import { connect } from "react-redux";
import { RootState } from "../../store/index";
import _ from "lodash";
import { DatasourceDefinitionParameterDisplayModel, DatasourceDisplayModel } from "../../shared/api-client";
import { renderValue } from "./shared";
import Style from 'style-it';

interface DatasourcesProps {
    datasources: Map<string, DatasourceDisplayModel>;
    datasourceValues: Map<string, any[]>;
    parameterValues: Map<string, any>;
}

function renderParameterValueSection(dsp: DatasourceDefinitionParameterDisplayModel, value: any) {
    return (<>
        <td>
            {dsp.name}{dsp.isOptional ? "*" : ""}
        </td>
        <td>
            {renderValue(value)}
        </td>
    </>);
}

function Datasources(props: DatasourcesProps) {
    const { datasources, datasourceValues, parameterValues } = props;

    return (
        <div className="table-container">
            <table className="table bordered key-value">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Function Path</th>
                        <th>Current Value</th>
                        <th>Parameter Name</th>
                        <th>Parameter Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.from(datasources.entries()).map(([dsId, ds]) => {
                            let normalizedParameters = ds.parameters.length > 0 ? _.orderBy(ds.parameters, ["isOptional", "name"], [false, true]) : [null];

                            return normalizedParameters.map((dsp, dspId) => <tr key={`dsp-${dspId}`}>
                                {dspId === 0 && <td className="text-field" rowSpan={normalizedParameters.length}>{ds.name}</td>}
                                {dspId === 0 && <td className="text-field" rowSpan={normalizedParameters.length}>{ds.moduleName ? `${ds.moduleName}.${ds.functionName}` : ""}</td>}
                                {
                                    dspId === 0 &&
                                    <td rowSpan={normalizedParameters.length}>
                                        {
                                            // The CodeMirror element's max height can only be set via a CSS rule targeting a nested element, so we use a styled component for this...
                                            Style.it(`.CodeMirror .CodeMirror-scroll { max-height: ${Math.max(250, normalizedParameters.length * 50)}px !important; }`, renderValue(datasourceValues.get(dsId)))
                                        }
                                    </td>
                                }
                                {
                                    dsp === null ?
                                        <td colSpan={2}>No parameters.</td> :
                                        renderParameterValueSection(dsp, parameterValues.get(dsp.name))
                                }
                            </tr>);
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        datasources: state.storyline.datasources,
        datasourceValues: state.storyline.datasourceValues,
        parameterValues: state.storyline.parameterValues
    }),
    null)(Datasources);