import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/foldgutter.css';
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { UnControlled as CodeMirror } from 'react-codemirror2';

export function renderValue(value: unknown) {
    switch (typeof value) {
        default:
            return <div className="code-container"><CodeMirror
                className="fill"
                value={JSON.stringify(value, null, 2)}
                options={{
                    mode: { name: "javascript", json: true },
                    theme: 'material',
                    foldGutter: true,
                    gutters: ["CodeMirror-foldgutter"]
                }}
            /></div>
    }
}