import React from "react";
import "./App.scss";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";
import history from "../shared/history";
import { connect, useDispatch } from "react-redux";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { loadApp } from "../store/app/actions";
import { incrementOutstandingRequestCount, decrementOutstandingRequestCount, showError, showDetailedError } from "../store/notifications/actions";
import { RootState } from "../store";
import { menuItems as defaultAdminMenuItems, default as Admin } from "../admin";
import Viewer from "../viewer";
import Public from "../public";
import LoginCallback from "../auth/LoginCallback";
import { fetchIntercept } from "../shared/monkey-patches";
import type { FetchInterceptor } from "../shared/services/fetchInterceptService";
import { useAuth0 } from "../auth/AuthContext";
import { SnackbarProvider } from 'notistack';
import Notifier from "../shared/components/Notifier";
import RequireAuthentication from "../auth/RequireAuthentication";
import Sidenav from "../shared/components/Sidenav";
import UserPreferences from "../shared/pages/UserPreferences";
import NotFound from "../shared/pages/NotFound";
import { AppThemeProvider } from "../shared/providers/AppThemeProvider";
import { openWsConnection } from "../store/vscode/actions";
import { ThunkDispatch } from "redux-thunk";
import { MenuItemDisplayModel, MenuLocation } from "../shared/api-client";
import RequiresRole from "../shared/components/RequiresRole";
import { ROLES } from "../auth/types";
import { useSettings } from "../shared/providers/SettingsProvider";

LicenseInfo.setLicenseKey(
    "c0999568c3def75091e9f9d50d778258T1JERVI6MjczNTksRVhQSVJZPTE2NTg0NzIyOTgwMDAsS0VZVkVSU0lPTj0x"
);

interface AppProps {
    menuItems: MenuItemDisplayModel[],
    loadApp: typeof loadApp,
    incrementOutstandingRequestCount: typeof incrementOutstandingRequestCount,
    decrementOutstandingRequestCount: typeof decrementOutstandingRequestCount
}

function App(props: AppProps) {
    const { menuItems, loadApp, incrementOutstandingRequestCount, decrementOutstandingRequestCount } = props;
    const { isAuthenticated, getTokenSilently, roles } = useAuth0();
    const [isInitialized, setIsInitialized] = React.useState(false);
    const dispatch = useDispatch() as ThunkDispatch<any, unknown, any>;
    const settings = useSettings();

    React.useEffect(() => {
        if (!isAuthenticated) return;

        // Register an HTTP interceptor that adds the latest JWT token to the request headers and tracks outstanding AJAX requests...
        const unregister = fetchIntercept.register({
            request: async (url, config) => {
                if (url?.startsWith?.(window.location.origin) || url?.startsWith?.("/")) {
                    incrementOutstandingRequestCount();

                    if (isAuthenticated && config != null) {
                        const token = await getTokenSilently();
                        config.headers["Authorization"] = `Bearer ${token}`;
                    }
                }

                return [url, config];
            },
            requestError: function (error) {
                decrementOutstandingRequestCount();

                // Called when an error occured during another 'request' interceptor call
                return Promise.reject(error);
            },
            response: function (response) {
                if (response?.url?.startsWith?.(window.location.origin) || response?.url?.startsWith?.("/")) {
                    decrementOutstandingRequestCount();

                    // Log and display error notifications...
                    if (response.status >= 400) {
                        console.group("Failed to fetch data.");
                        console.warn(response);
                        console.groupEnd();

                        if (settings.errorNotificationLevel === "verbose") {
                            response.text().then((body) => {
                                dispatch(showDetailedError("Failed to fetch data.", body));
                            });
                        }
                        else if (settings.errorNotificationLevel === "basic") {
                            dispatch(showError("Failed to fetch data."));
                        }
                    }
                }

                // Modify the reponse object
                return response;
            },
            responseError: function (error) {
                decrementOutstandingRequestCount();

                // Handles fetch error...
                return Promise.reject(error);
            }
        } as FetchInterceptor);

        loadApp(); // Load the storylines and canvases for the current tenant once-off...

        // Immediately try to open the VS Code WebSocket connection if the user is a developer...
        if (roles?.includes?.("Developer")) {
            dispatch(openWsConnection());
        }

        setIsInitialized(true);

        return unregister;
    }, [isAuthenticated]);

    const { storylineMenuItems, adminMenuItems } = React.useMemo(() => {
        return {
            storylineMenuItems: menuItems.filter(mi => mi.menuLocationId == MenuLocation.Storyline),
            adminMenuItems: [
                ...defaultAdminMenuItems,
                ...menuItems
                    .filter(mi => mi.menuLocationId == MenuLocation.Admin)
                    .map(mi => ({ ...mi, url: (mi.url.startsWith("/") && !mi.url.startsWith("/admin")) ? `/admin${mi.url}` : mi.url }))
            ]
        };
    }, [menuItems]);

    return (
        <AppThemeProvider>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                maxSnack={5}
            >
                <Notifier />
                <HistoryRouter history={history}>
                    <Routes>
                        <Route path="not-found" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <div className="app-layout not-found-page">
                                    <Sidenav menuItems={storylineMenuItems} />
                                    <div className="content">
                                        <NotFound />
                                    </div>
                                </div>
                            </RequireAuthentication>
                        } />
                        <Route path="login-callback" element={<LoginCallback />} />
                        <Route path="preferences" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <div className="app-layout preferences-page">
                                    <Sidenav menuItems={storylineMenuItems} />
                                    <div className="content">
                                        <UserPreferences />
                                    </div>
                                </div>
                            </RequireAuthentication>
                        } />
                        <Route path="public/*" element={<Public />} />
                        <Route path="admin/*" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <RequiresRole roleName={ROLES.ADMINISTRATOR}>
                                    <Admin menuItems={adminMenuItems} />
                                </RequiresRole>
                            </RequireAuthentication>
                        } />
                        <Route path="/*" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <Viewer menuItems={storylineMenuItems} />
                            </RequireAuthentication>
                        } />
                    </Routes>
                </HistoryRouter>
            </SnackbarProvider>
        </AppThemeProvider>
    );
}

export default connect(
    (state: RootState) => ({
        menuItems: state.app.menuItems
    }),
    { incrementOutstandingRequestCount, decrementOutstandingRequestCount, loadApp: loadApp as any })(App);