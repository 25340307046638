import { Card, CardHeader, CardContent, Button } from "../shared/components";
import { useAuth0 } from "./AuthContext";

export default function LoginCallback() {
    const { logout, error } = useAuth0();

    return error ?
        <Card className="screen-centered" style={{ maxWidth: "600px" }}>
            <CardHeader title="Login failed" />
            <CardContent className="text-align-center">
                {error}
                <br />
                <br />
                <br />
                <Button variant="contained" color="primary" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>
            </CardContent>
        </Card>
        :
        <Card className="screen-centered">
            <CardHeader title="Login succeeded" />
            <CardContent className="text-align-center" style={{ padding: "40px" }}>
                Redirecting - please wait...
            </CardContent>
        </Card>
}