import React from "react";
import { Auth0Context } from "../AuthContext";
import { AuthenticationResult, AuthError, BrowserCacheLocation, Configuration, EventType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { LogLevel } from "@azure/msal-browser";

export type B2CAuthProviderProps = {
    children: any;
    tenantId: string;
    instance: string;
    clientId: string;
    domain: string;
    redirectUri: string;
    apiVersion: string;
    onRedirectCallback: (state: object) => void;
}

export const B2CAuthProvider = (props: B2CAuthProviderProps) => {
    const { children, instance, clientId, domain, redirectUri, apiVersion, onRedirectCallback } = props;

    const b2cPolicies = {
        names: {
            signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
            forgotPassword: 'BSC_1A_PASSWORDRESET',
            editProfile: 'B2C_1A_PROFILEEDIT',
        },
        authorities: {
            signUpSignIn: {
                authority: `${instance}${domain}/B2C_1A_SIGNUP_SIGNIN`,
            },
            forgotPassword: {
                authority: `${instance}${domain}/B2C_1A_PASSWORDRESET`,
            },
            editProfile: {
                authority: `${instance}${domain}/B2C_1A_PROFILEEDIT`,
            },
        },
        authorityDomain: instance.replace("https://", "").replace("/", ""),
    };

    const loginRequest: RedirectRequest = {
        scopes: ['openid', 'offline_access', clientId],
        extraQueryParameters: {
            "domain": window.location.hostname,
            "api-version": apiVersion
        },
        // Default redirect to current location - this will be overridden by private route redirects, if needed...
        state: JSON.stringify({ targetUrl: window.location.href })
    };

    const msalConfig: Configuration = {
        auth: {
            clientId: clientId, // This is the ONLY mandatory field that you need to supply.
            authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
            knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
            redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };

    const [client] = React.useState(new PublicClientApplication(msalConfig));
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        client.handleRedirectPromise()
            .then(response => {
                setLoading(false);
            })
            .catch((err: AuthError) => {
                setLoading(false);
                const sanitizedErrorMessage = err.errorMessage?.split?.("\n")?.[0]?.replace?.("AADB2C: ", "");
                setError(sanitizedErrorMessage);
                onRedirectCallback && onRedirectCallback({ targetUrl: "/login-callback" });
            });
    }, [client]);

    React.useEffect(() => {
        const callbackId = client.addEventCallback((event) => {
            if (
              (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                (event.payload as AuthenticationResult).account
            ) {
                const authResult = event.payload as AuthenticationResult;
                const account = authResult.account;
                client.setActiveAccount(account);
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && (event.error as AuthError)?.errorMessage?.includes?.('AADB2C90118')) {
                    const resetPasswordRequest = {
                        ...loginRequest,
                        authority: b2cPolicies.authorities.forgotPassword.authority
                    };
                    client.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                client.removeEventCallback(callbackId);
            }
        };
    }, [client]);

    const activeUser = client.getActiveAccount();

    return (
        <Auth0Context.Provider
            value={{
                clientId,
                onRedirectCallback,
                client: client,
                isAuthenticated: activeUser !== null,
                user: { name: activeUser?.name },
                roles: activeUser?.idTokenClaims["roles"],
                allowedTags: activeUser?.idTokenClaims["allowed_tags"],
                deniedTags: activeUser?.idTokenClaims["denied_tags"],
                userMetadata: Object.fromEntries(
                    [
                        // Non-standard claim names, as returned originally by the "getUserMetadata" function call...
                        ["EmailAddress", activeUser?.idTokenClaims["email"] ?? ""],
                        ["UserId", activeUser?.idTokenClaims["user_id"] ?? ""],
                        // Standardized claims...
                        ["domain", activeUser?.idTokenClaims["domain"] ?? ""],
                        ["tenant_name", activeUser?.idTokenClaims["tenant_name"] ?? ""],
                        ["roles", activeUser?.idTokenClaims["roles"] ?? []],
                        ["user_id", activeUser?.idTokenClaims["user_id"] ?? ""],
                        ["name", activeUser?.idTokenClaims["name"] ?? ""],
                        ["email", activeUser?.idTokenClaims["email"] ?? ""],
                        // User metadata...
                        ...((activeUser?.idTokenClaims["user_metadata"] ?? []) as string[]).map(s => s.split("::")),
                    ]
                ),
                loading: client === null,
                popupOpen: false,
                error: error,
                loginWithPopup: (...a) => client.loginPopup(...a),
                handleRedirectPromise: (...p) => client.handleRedirectPromise(...p),
                getIdTokenClaims: (p) => client.getActiveAccount()?.idTokenClaims,
                loginWithRedirect: (p) => client.loginRedirect({...loginRequest, ...p }),
                getTokenSilently: async () => {
                    try {
                        const token = await client.acquireTokenSilent({ ...loginRequest });
                        if (!token.accessToken) {
                            return client.loginRedirect({ ...loginRequest });
                        }
                        return token.accessToken;
                    }
                    catch (err) {
                        return client.loginRedirect({ ...loginRequest });
                    }
                },
                getTokenWithPopup: (req) => client.acquireTokenPopup(req),
                logout: (...p) => client.logoutRedirect(...p)
            }}
        >
            {!loading && children}
        </Auth0Context.Provider>
    );
};