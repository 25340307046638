import React from 'react';
import "./TextToSpeechPlayer.scss";
import { useSettings } from '../../../shared/providers/SettingsProvider';
import { getUserPreferences } from "../../../shared/services/userPreferencesService";
import { sanitizeTextForNarration } from "../../../shared/utilities";
import { Fab, Tooltip } from "../../../shared/components";
import { Unmute, Pause, Clock } from "../../../shared/components/icons";
import { DocumentedComponent } from '../../../shared/components/DocumentedComponent';

function TextToSpeechPlayer(props: { text: string | string[], [key: string]: any }) {
    const { text } = props;
    const settings = useSettings();
    const [audio, setAudio] = React.useState<HTMLAudioElement>(null);
    const [state, setState] = React.useState("paused");

    if (!text || !settings.googleApiKey) return null;

    const playOrPauseClicked = () => {
        if (!audio) {
            setState("loading");

            const sanitizedText = sanitizeTextForNarration(text);

            fetch(`https://texttospeech.googleapis.com/v1/text:synthesize?key=${settings.googleApiKey}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        input: {
                            text: sanitizedText
                        },
                        voice: getUserPreferences().voice,
                        audioConfig: {
                            audioEncoding: "LINEAR16",
                            pitch: 0,
                            speakingRate: 1
                        }
                    })
                }).then(result => {
                    result.json().then((responseBody) => {
                        let newAudio = new Audio();
                        newAudio.onpause = () => setState("paused");
                        newAudio.onplay = () => setState("playing");

                        newAudio.src = `data:audio/mpeg;base64,${responseBody?.audioContent}`;
                        newAudio.play();
                        setAudio(newAudio);
                    })
                });
        }
        else if (!audio.paused) {
            audio.pause();
        }
        else {
            audio.play();
        }
    }

    return (
        <Tooltip arrow title={state === "paused" ? "Narrate" : "Pause"}>
            <Fab className="text-to-speech-control" size="small" color="primary" onClick={playOrPauseClicked} {...props}>
                {
                    state === "paused" ? <Unmute size="small" color="white" /> :
                        state === "loading" ? <Clock size="small" color="white" /> :
                            state === "playing" ? <Pause size="small" color="white" /> :
                                null
                }
            </Fab>
        </Tooltip>
    );
}

(TextToSpeechPlayer as DocumentedComponent).metadata = {
    description: "The TextToSpeechPlayer component allows the user to control the playback of the narration for arbitrary text.  It displays a single icon button that can be used to start the narration.  Once started, the narration can be paused and resumed again using the same button.  It relies on the [Google Text-to-Speech](https://cloud.google.com/text-to-speech/) service for narration.",
    isSelfClosing: true,
    attributes: [
        { name: "text", type: "object", description: "The text to be narrated.  Can be a string or a string array.  Can be a constant or a field from the current data frame.  The text is lightly sanitized before being passed to the narration API.  This includes removing HTML markup, replacing common currency symbols with their English counterparts and replacing suffixed numbers (`230 m`) with their reader-friendly counterparts (`230 million`)." },
    ]
};

export default TextToSpeechPlayer;