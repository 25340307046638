import "./Sparkline.scss";
import _ from "lodash";
import { useResizeDetector } from 'react-resize-detector';

type SparklineSection = {
    values: number[],
    color: string,
    variant: "dashed" | "filled" | "line"
}

type SparklineProps = {
    sections: SparklineSection[];
};

export function Sparkline(props: SparklineProps) {
    const { sections } = props;
    const { width, height, ref } = useResizeDetector();

    if (_.isEmpty(sections)) { return null; }

    const allValues = sections.flatMap(s => s.values);
    const xScale = width / (allValues.length - sections.length);
    const yScale = height;
    const maxValue = _.max(allValues) || 1;

    const sectionsWithOffsets = sections.map((section, sectionIndex) => {
        const predecessors = sections.slice(0, sectionIndex).flatMap(s => s.values);
        return [
            {
                ...section,
                values: section.values,
            },
            predecessors.length ? predecessors.length - sectionIndex : 0
        ] as [SparklineSection, number];
    });

    const paths = sectionsWithOffsets.map(([section, offset], sectionIndex) => {
        const { values, color, variant: _variant } = section;
        const variant = _variant ?? "filled";
        if (!values?.length) return null;

        const normalizedValues = values.map(v => v / maxValue);
        const coords = normalizedValues.map((y, x) => ({
            x: (offset + x) * xScale,
            y: (1 - y) * yScale
        }));

        if (coords?.[0]?.x === undefined || isNaN(coords?.[0]?.x) || coords?.[0]?.y === undefined || isNaN(coords?.[0]?.y)) {
            return null;
        }

        const lines = coords.map(({ x, y }) => `L ${x} ${y}`);
        return variant === "filled" ?
            <path key={`path-${sectionIndex}`} d={`M ${coords[0].x} ${height} ${lines.join(" ")} L ${coords.at(-1).x} ${height} Z`} fill={color} stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> :
            <path key={`path-${sectionIndex}`} d={`M ${coords[0].x} ${coords[0].y} ${lines.join(" ")}`} fill="none" stroke={color} strokeWidth="3" strokeDasharray={variant === "dashed" ? "5,5" : null} strokeLinecap="round" strokeLinejoin="round" />;
    });

    return (
        <div ref={ref} className="sparkline-container">
            {
                width !== undefined && !isNaN(width) && height !== undefined && !isNaN(height) ?
                    <div className="sparkline">
                        <svg viewBox={`0 0 ${width} ${height}`}>
                            {paths}
                        </svg>
                    </div> :
                    null
            }
        </div>
    );
}