import "./KpiMosaic.scss";
import type { Cell, Dimension } from "./types";
import React from "react";
import _ from "lodash";
import fastdom from "fastdom";
import { KpiMosaicHeaderCell } from "./KpiMosaicHeaderCell";
import { KpiMosaicCell } from "./KpiMosaicCell";
import { getIdentifier, LayoutCalculator } from "./layoutCalculator";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";

type KpiMosaicProps = {
    columns: { [name: string]: Dimension },
    rows: { [name: string]: Dimension },
    cells: Cell[],
    visibleColumnsParameterName?: string,
    visibleRowsParameterName?: string,
    getCellText?: (cellData: Cell) => string,
    getCellSparkline?: (cellData: Cell) => { filled?: number[], dashed?: number[] },
    getCellColour?: (cellData: Cell) => number,
    getMouseOverItems?: (cellData: Cell) => { icon: React.Component, tooltip: React.Component }[],
    getBusinessMapButtonVisibility?: (cellData: Cell) => boolean,
    getDashboardButtonVisibility?: (cellData: Cell) => boolean,
    onBusinessMapClick?: (cellData: Cell) => void,
    onDashboardClick?: (cellData: Cell) => void,
}

function _KpiMosaic(props: KpiMosaicProps) {
    const { columns, rows, cells, visibleColumnsParameterName, visibleRowsParameterName, getCellText, getCellColour, getCellSparkline, getMouseOverItems, getBusinessMapButtonVisibility, getDashboardButtonVisibility, onBusinessMapClick, onDashboardClick } = props;
    const cellRendererProps = { getCellText, getCellColour, getCellSparkline, getMouseOverItems, getBusinessMapButtonVisibility, getDashboardButtonVisibility, onBusinessMapClick, onDashboardClick };
    const { allColumns, allRows } = React.useMemo(() => {
        const allColumns: string[][] = _.uniqWith(cells.filter(c => !_.isEmpty(c["datasets"])).map(c => c.column_coordinate), _.isEqual);
        const allRows: string[][] = _.uniqWith(cells.filter(c => !_.isEmpty(c["datasets"])).map(c => c.row_coordinate), _.isEqual);

        return {
            allColumns,
            allRows
        };
    }, [cells]);

    const [lastAllColumns, setLastAllColumns] = React.useState([]);
    const [lastAllRows, setLastAllRows] = React.useState([]);
    const containerRef = React.useRef(null);

    const visibleRowsParameterValue = useSelector((s: RootState) => s.storyline.parameterValues.get(visibleRowsParameterName));
    const visibleColumnsParameterValue = useSelector((s: RootState) => s.storyline.parameterValues.get(visibleColumnsParameterName));

    const [visibleRows, _setVisibleRows] = React.useState(visibleRowsParameterValue ?? allRows);
    const [visibleColumns, _setVisibleColumns] = React.useState(visibleColumnsParameterValue ?? allColumns);

    React.useEffect(() => {
        visibleRowsParameterValue && !_.isEqual(visibleRowsParameterValue, visibleRows) && _setVisibleRows(visibleRowsParameterValue);
    }, [visibleRowsParameterValue]);

    React.useEffect(() => {
        visibleColumnsParameterValue && !_.isEqual(visibleColumnsParameterValue, visibleColumns) && _setVisibleColumns(visibleColumnsParameterValue);
    }, [visibleColumnsParameterValue]);

    const dispatch = useThunkDispatch();

    const setVisibleRows = React.useCallback((newValue) => {
        if (visibleRowsParameterName) {
            dispatch(updateParameterValue(visibleRowsParameterName, newValue));
        }
        else {
            _setVisibleRows(newValue);
        }
    }, [dispatch]);

    const setVisibleColumns = React.useCallback((newValue) => {
        if (visibleColumnsParameterName) {
            dispatch(updateParameterValue(visibleColumnsParameterName, newValue));
        }
        else {
            _setVisibleColumns(newValue);
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (!_.isEqual(lastAllRows, allRows)) {
            // Visible rows are incompatible with the new rows, disregard the current visible rows value and repopulate with the full set...
            if (visibleRows.some(r => !allRows.some(ar => _.isEqual(ar, r)))) {
                setVisibleRows(allRows);
            }
            
            setLastAllRows(allRows);
        }
    }, [allRows, visibleRows]);

    React.useEffect(() => {
        if (!_.isEqual(lastAllColumns, allColumns)) {
            // Visible columns are incompatible with the new columns, disregard the current visible columns value and repopulate with the full set...
            if (visibleColumns.some(c => !allColumns.some(ac => _.isEqual(ac, c)))) {
                setVisibleColumns(allColumns);
            }

            setLastAllColumns(allColumns);
        }
    }, [allColumns, visibleColumns]);

    // Imperfect attempt to implement sticky headers...
    React.useEffect(() => {
        if (!containerRef) return;

        // Reset header cell positions in order to allow for growing/shrinking of rows/columns...
        fastdom.mutate(() => {
            containerRef.current.querySelectorAll(".col-header").forEach(ch => {
                ch.style.position = "unset";
                ch.style.top = null;
            });

            containerRef.current.querySelectorAll(".row-header").forEach(rh => {
                rh.style.position = "unset";
                rh.style.left = null;
            });
        });

        // Allow layout to stabilize and then calculate the offsets...
        fastdom.mutate(() => {
            containerRef.current.querySelectorAll(".col-header").forEach(ch => {
                ch.style.top = Math.max(0, Math.floor(ch.offsetTop)) + "px";
                ch.style.position = null;
            });

            containerRef.current.querySelectorAll(".row-header").forEach(ch => {
                ch.style.left = Math.max(0, Math.floor(ch.offsetLeft)) + "px";
                ch.style.position = null;
            });
        });
    }, [containerRef, visibleColumns, visibleRows]);

    const layoutCalculator = new LayoutCalculator(allRows, visibleRows, allColumns, visibleColumns);

    const collapseExpandColumn = (isExpanded, col) => {
        const newVisibleColumns = isExpanded ? layoutCalculator.expandColumn(col) : layoutCalculator.collapseColumn(col);
        setVisibleColumns(newVisibleColumns);
    }

    const collapseExpandRow = (isExpanded, col) => {
        const newVisibleRows = isExpanded ? layoutCalculator.expandRow(col) : layoutCalculator.collapseRow(col);
        setVisibleRows(newVisibleRows);
    }

    return (
        <div key="kpi-mosaic-container" className="qbc-kpi-mosaic-container">
            <div key="kpi-mosaic" className="qbc-kpi-mosaic" ref={containerRef} style={layoutCalculator.gridLayout}>
                {/* These 2 blocks prevent bleed-through of cell contents when scrolling... */}
                <div key="col-spacer" className="col-spacer" style={{ gridArea: `1 / 1 / ${layoutCalculator.maxColDepth + 1} / -1`, width: "100%" }}></div>
                <div key="row-spacer" className="row-spacer" style={{ gridArea: `1 / 1 / -1 / ${layoutCalculator.maxRowDepth + 1}`, height: "100%" }}></div>

                {/* These elements are used to drive the spacing sizes via CSS and to add some styling */}
                <div key="corner-spacer" className="corner-spacer" style={{ gridArea: "row-header-spacer" }}></div>
                {
                    layoutCalculator.colGroups.map((_c, cIndex) => <div key={`col-header-spacer-${cIndex}`} className="col-spacer" style={{ gridArea: `col-header-spacer-${cIndex}` }}></div>)
                }
                {
                    layoutCalculator.rowGroups.map((_r, rIndex) => <div key={`row-header-spacer-${rIndex}`} className="row-spacer" style={{ gridArea: `row-header-spacer-${rIndex}` }}></div>)
                }

                {
                    layoutCalculator.columnHeaderCells
                        .map(col => {
                            const gridAreaName = `col-${getIdentifier(col.coords)}`;

                            return (
                                <KpiMosaicHeaderCell
                                    key={gridAreaName}
                                    className="col-header"
                                    coordinates={col.coords}
                                    gridArea={gridAreaName}
                                    headerData={columns[col.coords.at(-1)]}
                                    collapsible={col.showCollapseExpand}
                                    isExpanded={col.isExpanded}
                                    onCollapseExpand={collapseExpandColumn}
                                />);
                        })
                }

                {
                    layoutCalculator.rowHeaderCells
                        .map(row => {
                            const gridAreaName = `row-${getIdentifier(row.coords)}`;

                            return (
                                <KpiMosaicHeaderCell
                                    key={gridAreaName}
                                    className="row-header"
                                    coordinates={row.coords}
                                    gridArea={gridAreaName}
                                    headerData={rows[row.coords.at(-1)]}
                                    collapsible={row.showCollapseExpand}
                                    isExpanded={row.isExpanded}
                                    onCollapseExpand={collapseExpandRow}
                                />);
                        })
                }

                {
                    _.uniqWith(layoutCalculator.leafRows.flatMap(row => layoutCalculator.getHierarchyForLeafRow(row)), _.isEqual)
                        .map(row =>
                            layoutCalculator.leafCols.map(col => {
                                const gridAreaName = `cell-${getIdentifier(row)}-${getIdentifier(col)}`;

                                return (
                                    <KpiMosaicCell
                                        mosaicContainerRef={containerRef}
                                        key={gridAreaName}
                                        gridArea={gridAreaName}
                                        cellData={cells.find(c => _.isEqual(c.row_coordinate, row) && _.isEqual(c.column_coordinate, col))}
                                        {...cellRendererProps}
                                    />);
                            })
                        )
                }
            </div>
        </div>
    );
}

function KpiMosaic(props: KpiMosaicProps) {
    const { columns, rows, cells } = props;

    // We need rows, columns and cells in order to render anything, so short-circuit if any of these are null/empty...
    if (!(columns && rows && cells)) {
        return null;
    }

    return <_KpiMosaic {...props} />;
}

(KpiMosaic as DocumentedComponent).metadata = {
    description: "The KpiMosaic component displays an interactive grid of KPI metrics.",
    isSelfClosing: true,
    attributes: [
        {
            name: `columns`, type: "object", description: `The column definitions for the KPI mosaic.  Contains a dictionary, where the keys are the column identifiers(as referenced by the cells) and the values are the column definitions, of type \`Dimension\`.  See below for the structure of the \`Dimension\` type:

### \`Dimension\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`display\` | \`ElementDisplay\` | Determines how the row/column is displayed.  See below for the structure of the \`ElementDisplay\` type. |
| \`collapsible\` | \`boolean\` | Determines whether the row/column is collapsible.  Set this to \`true\` if the visibility of this element's children should be controllable by the user. |

### \`ElementDisplay\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`text\` | \`string\` | The text to render inside the header cell. |
| \`graphics\` | \`Graphic[]\` | The graphics to render inside the header cell.  See below for the structure of the \`Graphic\` type. |

### \`Graphic\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`type\` | \`"img" \| "Qerent-Bespoke" \| "MaterialUI" \| "FontAwesome"\` | The type of graphic to render. |
| \`content\` | \`string\` | The content of the graphic.  For type = \`"img"\`, this is the source for the image.  For other types, this is the identifier of the icon. |
` },
        {
            name: `rows`, type: "object", description: `The row definitions for the KPI mosaic.  Contains a dictionary, where the keys are the row identifiers(as referenced by the cells) and the values are the row definitions, of type \`Dimension\`.  See below for the structure of the \`Dimension\` type:

### \`Dimension\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`display\` | \`ElementDisplay\` | Determines how the row/column is displayed.  See below for the structure of the \`ElementDisplay\` type. |
| \`collapsible\` | \`boolean\` | Determines whether the row/column is collapsible.  Set this to \`true\` if the visibility of this element's children should be controllable by the user. |

### \`ElementDisplay\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`text\` | \`string\` | The text to render inside the header cell. |
| \`graphics\` | \`Graphic[]\` | The graphics to render inside the header cell.  See below for the structure of the \`Graphic\` type. |

### \`Graphic\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`type\` | \`"img" \| "Qerent-Bespoke" \| "MaterialUI" \| "FontAwesome"\` | The type of graphic to render. |
| \`content\` | \`string\` | The content of the graphic.  For type = \`"img"\`, this is the source for the image.  For other types, this is the identifier of the icon. |
` },
        {
            name: `cells`, type: "object", description: `The cells to render inside the KPI mosaic.  See below for the structure of the \`Cell\` type:

### \`Cell\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`column_coordinate\` | \`string[]\` | The column coordinates for this cell.  The values within this path should match the column identifiers. |
| \`row_coordinate\` | \`string[]\` | The row coordinates for this cell.  The values within this path should match the row identifiers. |
| \`datasets\` | \`Object\` | The data used for rendering the contents of this cell.  Referenced by the render/callback functions declared for the mosaic. |
` },
        { name: `visibleColumnsParameterName`, type: "string", description: `The (optional) parameter to bind the visible columns to.  External changes made to this parameter will update the component accordingly.` },
        { name: `visibleRowsParameterName`, type: "string", description: `The (optional) parameter to bind the visible rows to.  External changes made to this parameter will update the component accordingly.` },
        { name: `getCellText`, type: "function", template: "getCellText={(cellData) => {$1}}", description: `The callback function used to retrieve the text content for the cell.` },
        { name: `getCellSparkline`, type: "function", template: "getCellSparkline={(cellData) => {$1}}", description: `The callback function used to retrieve the sparkline data for the cell.  The "type" of the \`Sparkline\` is determined by the field which contains the data.  If both fields contain data, \`filled\` will take precedence.` },
        { name: `getCellColour`, type: "function", template: "getCellColour={(cellData) => {$1}}", description: `The callback function used to retrieve the cell colour.  Values should be in the range[-1, 1], where \`-1\` is bright red and \`1\` is dark green.` },
        { name: `getMouseOverItems`, type: "function", template: "getMouseOverItems={(cellData) => {$1}}", description: `The callback function used to retrieve the mouse - over items.  These items will be rendered as buttons in the cell footer, with \`icon\` used for the button contents and \`tooltip\` used for the tooltip contents.  The tooltip is displayed when the user hovers over the button or when the button is pinned.` },
        { name: "getBusinessMapButtonVisibility", type: "function", template: "getBusinessMapButtonVisibility={(cellData) => {$1}}", description: `The optional callback function used to determine whether to render the "Business Map" button inside a cell.  Any _truthy_ return value will result in the button being shown.  Optional - defaults to the button being shown in all cells.` },
        { name: "getDashboardButtonVisibility", type: "function", template: "getDashboardButtonVisibility={(cellData) => {$1}}", description: `The optional callback function used to determine whether to render the "Dashboard" button inside a cell.  Any _truthy_ return value will result in the button being shown.  Optional - defaults to the button being shown in all cells.` },
        { name: `onBusinessMapClick`, type: "function", template: "onBusinessMapClick={(cellData) => {$1}}", description: `The optional callback function to invoke when clicking on the "Navigate to Business Map" button in the cell.  Will most likely use the \`navigateToPage\` function to redirect the user to another storyline, while copying the current \`NavFilter\` values across.` },
        { name: `onDashboardClick`, type: "function", template: "onDashboardClick={(cellData) => {$1}}", description: `The optional callback function to invoke when clicking on the "Navigate to Dashboard" button in the cell.  Will most likely use the \`navigateToPage\` function to redirect the user to another storyline, while copying the current \`NavFilter\` values across.` },
    ]
};

export { KpiMosaic };
