import { useLocation } from "react-router-dom";
import { convertStringToValue } from "../utilities";
import * as _ from "lodash";

// A custom hook that builds on useLocation to parse
// the query string for you.
export default function useQueryParams() {
    const urlSearchParams = new URLSearchParams(useLocation().search);

    // Arrays are passed through as multiple entries with the same key.  We need to group these values and reconstruct the arrays, where applicable...
    const entriesMap = _
        .chain(Array.from(urlSearchParams.entries()))
        .reduce((map, [key, value]) => {
            let newValue = null;

            const existingValue = map.get(key);
            if (existingValue) {
                newValue = _.isArray(existingValue) ?
                    [...existingValue, convertStringToValue(value)] :
                    [existingValue, convertStringToValue(value)];
            } else {
                newValue = convertStringToValue(value);
            }

            map.set(key, newValue);
            return map;
        }, new Map<string, any>())
        .value();

    return entriesMap;
}