import "./Home.scss";
import logo from "../../assets/logo.png";
import { NavBar } from "../../shared/components";

function Home(props: any) {
    return (
        <>
            <NavBar title="Welcome to Qerent" breadcrumbStack={[{ title: "Home" }]}  />

            <div className="home-content">
                <img id="home-logo" src={logo} alt="The Qerent logo" className="logo" />
                <br />
                <br />
                <h2 id="home-header-1">Welcome to Qerent!</h2>
                <br />
                <h3 id="home-header-2">Select a Storyline/Canvas from the left menu to continue.</h3>
            </div>
        </>
    );
}

export default Home;