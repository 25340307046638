import { Sparkline } from "../Sparkline";
import type { Cell } from "./types";
import * as colors from "../../../shared/colors";
import _ from "lodash";
import { Fab, Popper, Tooltip } from "../../../shared/components";
import React from "react";
import clsx from "clsx";
import { Icons } from "../Icons";

// Colorjs.io package is imported as a global module in index.html
const { Color } = window as any;

type CellColorInfo = {
    color: string,
    opacity: number,
    additionalClasses?: string[]
};

function getCellInfoForValue(cellColorValue: number | null | undefined): CellColorInfo {
    const normalizedCellColorValue = cellColorValue > 1 ?
        1 : cellColorValue < -1 ?
            -1 : cellColorValue ?? 0;

    const result = {
        color: null,
        opacity: Math.min(1, Math.max(0.3, Math.abs(normalizedCellColorValue))),
        additionalClasses: Math.abs(normalizedCellColorValue) > 0.95 ? ["highlight-cell"] : []
    };

    const colorRanges: [number, string][] = [
        [-1, colors.red_1],
        [-0.33, colors.orange_1],
        [0.33, colors.yellow_1],
        [1, colors.green_2]
    ];

    const lowerBound = _.orderBy(colorRanges, ["0"], ["desc"]).find(([value, _]) => value <= normalizedCellColorValue);
    const upperBound = colorRanges.find(([value, _]) => value >= normalizedCellColorValue);

    const baseColor = new Color(lowerBound[1]);
    const range = baseColor.range(upperBound[1], {
        space: "srgb-linear"
    });

    const rangeWidth = (upperBound[0] - lowerBound[0]) || 1;
    const normalizedValue = (normalizedCellColorValue - lowerBound[0]) / rangeWidth;

    const cellColor = range(normalizedValue);
    result.color = cellColor.display();

    return result;
}

type MouseOverItem = {
    icon: React.Component,
    tooltip: React.Component
}

type KpiMosaicCellProps = {
    gridArea: string,
    cellData: Cell,
    getCellText?: (cellData: Cell) => string,
    getCellSparkline?: (cellData: Cell) => { filled?: number[], dashed?: number[] },
    getCellColour?: (cellData: Cell) => number,
    getMouseOverItems?: (cellData: Cell) => MouseOverItem[],
    getBusinessMapButtonVisibility?: (cellData: Cell) => boolean,
    getDashboardButtonVisibility?: (cellData: Cell) => boolean,
    onBusinessMapClick?: (cellData: Cell) => void,
    onDashboardClick?: (cellData: Cell) => void,
    mosaicContainerRef: React.MutableRefObject<HTMLElement>
};

export function KpiMosaicCell(props: KpiMosaicCellProps) {
    const { gridArea, cellData, getCellText, getCellSparkline, getCellColour, getMouseOverItems, getBusinessMapButtonVisibility, getDashboardButtonVisibility, onBusinessMapClick, onDashboardClick, mosaicContainerRef } = props;

    const sparklineData = getCellSparkline?.(cellData);
    const { filled, dashed } = sparklineData ?? {};

    const cellText = getCellText?.(cellData);

    const cellColorValue = getCellColour?.(cellData);
    const cellColorInfo = getCellInfoForValue(cellColorValue);

    const mouseOverItems = getMouseOverItems?.(cellData) ?? [];

    const showBusinessMapButton = getBusinessMapButtonVisibility ? getBusinessMapButtonVisibility(cellData) : true;
    const showDashboardButton = getDashboardButtonVisibility ? getDashboardButtonVisibility(cellData) : true;

    return (
        <div key={gridArea} id={gridArea} className={clsx("cell", cellColorInfo.additionalClasses)} style={{ gridArea, "--cell-color": cellColorInfo.color, "--cell-opacity": cellColorInfo.opacity } as React.CSSProperties}>
            {
                sparklineData &&
                <Sparkline
                    key="sparkline"
                    sections={[
                        {
                            variant: "filled",
                            values: filled ?? [],
                            color: cellColorInfo.color
                        }, {
                            variant: "dashed",
                            values: dashed ?? [],
                            color: cellColorInfo.color
                        }
                    ]}
                />
            }
            <div key="cell-content" className={clsx("cell-content")} style={{ backgroundColor: !sparklineData && cellColorInfo.color }}>
                <div key="cell-text" className="cell-text">{cellText}</div>
                <div key="footer" className="footer">
                    {
                        mouseOverItems.map((moi, moiIndex) =>
                            <FooterItem key={moiIndex} {...moi} mosaicContainerRef={mosaicContainerRef} />
                        )
                    }
                </div>
                <div key="header" className="header">
                    {
                        showBusinessMapButton && onBusinessMapClick &&
                        <Tooltip key="navigate-to-business-map" title="Navigate to Business Map" arrow disableInteractive>
                            <a onClick={() => onBusinessMapClick(cellData)} target="_blank" className="business-map">
                                <Fab size="small" className="mosaic-cell-button">
                                    <Icons.Graph color="black" size="small" />
                                </Fab>
                            </a>
                        </Tooltip>
                    }

                    {
                        showDashboardButton && onDashboardClick &&
                        <Tooltip key="navigate-to-dashboard" title="Navigate to Dashboard" arrow disableInteractive>
                            <a onClick={() => onDashboardClick(cellData)} target="_blank" className="dashboard">
                                <Fab size="small" className="mosaic-cell-button">
                                    <Icons.TreeChart color="black" size="small" />
                                </Fab>
                            </a>
                        </Tooltip>
                    }
                    <div key="spacer" className="spacer"></div>
                </div>
            </div>
        </div>
    );
}

type FooterItemProps = MouseOverItem & {
    mosaicContainerRef: React.MutableRefObject<HTMLElement>
}

function FooterItem(props: FooterItemProps) {
    const { tooltip, icon, mosaicContainerRef } = props;

    const buttonRef = React.useRef(null);
    const activeHover = React.useRef(false);
    const [pinned, setPinned] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMouseEnter = () => {
        activeHover.current = true;

        setTimeout(() => {
            if (activeHover.current) {
                setAnchorEl(buttonRef.current);
            }
        }, 200);
    };

    const handleMouseLeave = () => {
        activeHover.current = false;

        setTimeout(() => {
            if (!pinned && !activeHover.current) {
                setAnchorEl(null);
            }
        }, 200);
    };

    return (
        <>
            <Popper
                key="popper"
                className="footer-item-popper"
                open={!!anchorEl}
                container={mosaicContainerRef.current}
                anchorEl={anchorEl}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                arrow
            >
                {tooltip}
            </Popper>

            <Fab
                key="fab"
                size="small"
                ref={buttonRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={clsx("mosaic-cell-button", pinned && "pinned", anchorEl && "open")}
                onClick={_e => setPinned(!pinned)}
            >
                {icon}
            </Fab>
        </>
    );
}