import React from "react";
import "./Markdown.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

function Markdown(props: any) {
    const source = props?.source || props?.children;

    return (
        <div className="markdown">
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                {...props}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                style={style}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}
                children={source}
            />
        </div>
    );
}

(Markdown as DocumentedComponent).metadata = {
    description: "The Markdown component renders the provided markdown as HTML.  See the [component's repository](https://github.com/rexxars/react-markdown) for the full documentation.",
    isSelfClosing: false,
    attributes: []
};

export default Markdown;