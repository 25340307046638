import * as React from "react";
import clsx from "clsx";
import { default as Markdown } from "../Markdown";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface ContentRendererProps {
    className?: string;
    input?: string;
    bindings?: Object;
}

const TextRenderer = (text, _className = null, _bindings = null) => text;
const HtmlRenderer = (text, className = null, _bindings = null) => <div className={clsx(className)} dangerouslySetInnerHTML={{ __html: text }} />;
const JsxRenderer = (text, _className = null, bindings = null) => {
    const { TemplateRenderer } = require("../TemplateRenderer");
    return <TemplateRenderer template={text} data={bindings} />;
};
const MarkdownRenderer = (text, _className, _bindings) => <Markdown source={text} />;

const prefixMap = {
    "html:": HtmlRenderer,
    "jsx:": JsxRenderer,
    "markdown:": MarkdownRenderer,
    "": TextRenderer
};

function _ContentRenderer(props: ContentRendererProps) {
    const { className, input = "", bindings } = props;

    if (!input) {
        return null;
    }

    // Something other than a string was provided as input - serialize the input and display it as text...
    if (!input.startsWith) {
        return JSON.stringify(input);
    }

    for (let prefix of Object.keys(prefixMap)) {
        if (input?.startsWith(prefix)) {
            return prefixMap[prefix](input.substring(prefix.length), className, bindings);
        }
    }
}

const ContentRenderer = React.memo(_ContentRenderer);

(ContentRenderer as DocumentedComponent).metadata = {
    description: `The \`ContentRenderer\` component can be used to render any string as JSX, HTML, Markdown or plain text.  Useful in situations where dyamic content is returned by a datasource.

    The first few characters of the string will determine which renderer to use:
    
    * \`jsx:\` - Parses the content as JSX, utilizing all the same components that are directly available to templates.
    * \`html:\` - Treats the content as raw HTML and inserts it as-is into a containing \`div\`.  Even though \`jsx\` is a superset of \`html\`, this option should be used, if possible, as rendering performance will be much better.
    * \`markdown:\` - Parses the content as Markdown by wrapping it in a \`Markdown\` component.
    * Others - All other text is simply treated as a raw string, escaped and placed directly into the current position.`,
    isSelfClosing: true,
    attributes: [
        { name: `input`, type: `string`, description: "The text to render.  See above for the supported prefixes and their corresponding renderers." },
        { name: `bindings`, type: `object`, description: "Any additional data that is required by the template, but is not present in the standard bindings.  Only applies to `JSX` templates.  Primarily used to pass item-level data to the renderer while inside an iterator.  Optional - all Frame Data, Parameter Values and Metadata is already available to the `ContentRenderer` by default.  In case of a field name conflict, these values will take precedence over the default data." },
    ]
};

export { ContentRenderer };