import "./GeoMapContainer.scss";
import React, { useEffect, useState, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { GeoMap, GeoMapViewComponent } from "./map";
import { GeoClient } from "./services/geoClient";
import {
  GeoMarkerModel,
  GeoPolygonModel,
  PolygonFeature,
  PolygonFeatureCollection,
} from "./services/types";
import { Dialog } from "./shared/dialogs/dialog";
import { DataState } from "./services/enums";
import { AxiosConfig } from "./services/config/axios";
import { Autocomplete, Button, TextField, Tooltip } from "../../../shared/components";
import { Loader } from "./shared/loader/loader";
import { useLoaderStore } from "./shared/loader/store";
import { convertPathToPolygon, convertPolygonToPath } from "./services/utils";
import { useAuth0 } from "../../../auth/AuthContext";
import axios from 'axios';
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

export interface GeoMapWrapperContainerProps {
  baseUrl: string;
  zoomToBounds: number[][];
  useMetadata?: boolean;
}

declare type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];
const libraries: Libraries = ["drawing"];

const GeoMapContainerWrapper: React.FunctionComponent<GeoMapWrapperContainerProps> = ({
  baseUrl,
  zoomToBounds,
  useMetadata = true
 }) => {
  const { getTokenSilently } = useAuth0();
  const [mapApiKey, setMapApiKey] = useState("");

  useEffect(() => {
    getTokenSilently().then((token) => {
      AxiosConfig.init(baseUrl, token);
      axios.get('/api/getmapapikey')
      .then(response => setMapApiKey(response.data.mapApiKey))
      .catch(err => console.log(err));
    });
  }, [getTokenSilently]);

  return mapApiKey && <GeoMapContainer mapApiKey={mapApiKey} baseUrl={baseUrl} zoomToBounds={zoomToBounds} useMetadata={useMetadata}/>
}

interface GeoMapContainerProps extends GeoMapWrapperContainerProps {
  mapApiKey: string;
}

const GeoMapContainer: React.FunctionComponent<GeoMapContainerProps> = (
  props
) => {

  const { getTokenSilently } = useAuth0();
  const {zoomToBounds: _zoomToBounds, mapApiKey, useMetadata} = props;
  const [zoomToBounds, setZoomToBounds] = useState(_zoomToBounds);
  const mapRef = useRef<GeoMapViewComponent>(null);
  const [markers, setMarkers] = useState<GeoMarkerModel[]>([]);
  const [polygons, setPolygons] = useState<GeoPolygonModel[]>([]);
  const [maxMarkers, setMaxMarkers] = useState<number>(1000);
  const showLoader = useLoaderStore((state) => state.show);
  const hideLoader = useLoaderStore((state) => state.hide);
  const [geoIdValue, setGeoIdValue] = useState<number>(null);
  const [poiIdOptions, setPoiIdOptions] = useState([{value: "", label: ""}]);
  const [nextId, setNextId] = useState<number>(null);
  const [shouldReloadAutocomplete, setShouldReloadAutocomplete] = useState(true);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: mapApiKey,
    libraries: libraries,
  });

  function reloadAutocompleteOptions() {
    getTokenSilently().then((token) => {
      AxiosConfig.init(props.baseUrl, token);

      axios.get('/api/getpoioptions')
        .then(response => setPoiIdOptions(response.data.poiOptions))
        .catch(err => console.log(err));

      if (!useMetadata) {
        axios.get('/api/getnextid')
            .then(response => setNextId(response.data.nextId))
            .catch(err => console.log(err));
      }
    });
  }
  
  useEffect(() => {
    reloadAutocompleteOptions()
    setShouldReloadAutocomplete(false);
  }, [shouldReloadAutocomplete]);

  useEffect(() => {
    setZoomToBounds(_zoomToBounds);
  }, [_zoomToBounds]);

  function fetchPolygons(bounds: number[][]) {
    showLoader();

    const geoClient = new GeoClient();
    geoClient
      .getGeoFeatures(
        bounds
      )
      .then((response) => {
        const features = response.data.features;
        const newPolygons = features.map((feature) => {
          const path = convertPolygonToPath(feature.geometry.coordinates);

          const geoPolygon: GeoPolygonModel = {
            id: useMetadata? feature.id: Number(feature.id),
            name: feature.properties?.["name"],
            path,
            dataState: DataState.unchanged,
          };

          if (useMetadata) {
            geoPolygon.site = feature.properties?.["site"];
            geoPolygon.poiType = feature.properties?.["poiType"];
            geoPolygon.tat = feature.properties?.["tat"];
            geoPolygon.minTat = feature.properties?.["minTat"];
            geoPolygon.lane = feature.properties?.["lane"];
          }
          return geoPolygon;
        });
        setPolygons(newPolygons);
      })
      .finally(() => {
        hideLoader();
      });
  }

  function fetchPolygonByID(geo_id: any) {
    showLoader();

    const geoClient = new GeoClient();
    geoClient
      .getGeoById(
        geo_id
      )
      .then((response) => {
        const features = response.data.features;
        const newPolygons = features.map((feature) => {
          const path = convertPolygonToPath(feature.geometry.coordinates);       
          setZoomToBounds(feature.geometry.coordinates[0]);

          const geoPolygon: GeoPolygonModel = {
            id: useMetadata? feature.id: Number(feature.id),
            name: feature.properties?.["name"],
            path,
            dataState: DataState.unchanged,
          };

          if (useMetadata) {
            geoPolygon.site = feature.properties?.["site"];
            geoPolygon.poiType = feature.properties?.["poiType"];
            geoPolygon.tat = feature.properties?.["tat"];
            geoPolygon.minTat = feature.properties?.["minTat"];
            geoPolygon.lane = feature.properties?.["lane"];
          }
          return geoPolygon;
        });
        setPolygons(newPolygons);
      })
      .finally(() => {
        hideLoader();
      });
  }

  function fetchGps(bounds: number[][]) {
    showLoader();

    const geoClient = new GeoClient();
    geoClient
      .getGpsData(
        bounds,
        maxMarkers || 1000
      )
      .then((response) => {
        const newMarkers = response.data.map((data, id) => {
          return {
            id: id,
            // todo: Refactor this number formatting into a utility function...
            name: `${data.reg_num}\n\nSpeed: ${data.speed_kmph?.toLocaleString(
              undefined,
              { maximumFractionDigits: 0 }
            )} km/h\nTime to Previous Reading: ${data.time_to_prev_reading_mins?.toLocaleString(
              undefined,
              { minimumFractionDigits: 1, maximumFractionDigits: 1 }
            )} Minutes`,
            lat: data.latitude,
            lng: data.longitude,
            color: data.speed_kmph === 0 ? "red" : "green",
            size: 4,
          } as GeoMarkerModel;
        });
        setMarkers(newMarkers);
      })
      .finally(() => {
        hideLoader();
      });
  }

  function handlePolygonAdded(polygon: GeoPolygonModel) {
    setPolygons((prevPolygons) => {
      return [...prevPolygons, polygon];
    });
    if (!useMetadata && polygon.id === nextId) {
      setNextId(nextId + 1)
    }
  }

  function handlePolygonUpdated(polygon: GeoPolygonModel) {
    setPolygons((prevPolygons) => {
      return prevPolygons.map((item) => {
        if (item.id !== polygon.id) return item;

        // Set the data state to modified
        const dataState =
          item.dataState === DataState.unchanged
            ? DataState.modified
            : item.dataState;

        return { ...item, ...polygon, dataState };
      });
    });
  }

  function handlePolygonDeleted(id: any) {
    mapRef.current?.saveSettings();

    const geoClient = new GeoClient();
    geoClient.deleteGeo(
      id
    );
    setPolygons((prevPolygons) => {
      return prevPolygons.filter((item) => item.id !== id);
    });
  }

  function handleLoadPolygons() {
    if (!mapRef.current) return;
    mapRef.current?.saveSettings();

    const bounds = mapRef.current.getBounds();
    if (!bounds) return;

    fetchPolygons(bounds);
  }

  function handleLoadGeoById() {
    const geo_id = geoIdValue;
    fetchPolygonByID(geo_id)
  }

  function handleLoadGps() {
    if (!mapRef.current) return;
    mapRef.current?.saveSettings();

    const bounds = mapRef.current.getBounds();
    if (!bounds) return;

    fetchGps(bounds);
  }

  function handleSave() {
    mapRef.current?.saveSettings();

    const model = getChanges(useMetadata);
    if (model.features.length === 0) return;

    showLoader();
    const geoClient = new GeoClient();
    geoClient
      .upsertGeo(
        model
      )
      .then((_) => {
        acceptChanges();
        setShouldReloadAutocomplete(true);
        hideLoader();
      });
  }

  function getChanges(useMetadata: boolean): PolygonFeatureCollection {
    const featureChanges = polygons
      .filter(
        (x) =>
          x.dataState === DataState.added || x.dataState === DataState.modified
      )
      .map((x) => {
        const coords = convertPathToPolygon(x.path);

        let item: PolygonFeature;
        if (useMetadata) {
          item = {
            type: "Feature",
            id: x.id,
            geometry: {
              type: "Polygon",
              coordinates: coords,
            },
            properties: {
              name: x.name,
              site: x.site,
              poiType: x.poiType,
              tat: x.tat,
              minTat: x.minTat,
              lane: x.lane,
            },
          };
        } else {
          item = {
            type: "Feature",
            id: x.id,
            geometry: {
              type: "Polygon",
              coordinates: coords,
            },
            properties: {
              name: x.name,
            },
          };
        }

        return item;
      });

    const features: PolygonFeatureCollection = {
      type: "FeatureCollection",
      features: featureChanges,
    };

    return features;
  }

  function acceptChanges() {
    setPolygons((prevPolygons) => {
      return prevPolygons
        .filter((item) => item.dataState !== DataState.deleted)
        .map((item) => ({ ...item, dataState: DataState.unchanged }));
    });
  }

  function handleMaxMarkersChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMaxMarkers(event.target.value as unknown as number);
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) return <></>;

  return (
    <React.Fragment>
      <GeoMap
        ref={mapRef}
        markers={markers}
        polygons={polygons.filter((x) => x.dataState !== DataState.deleted)}
        zoomToBounds={zoomToBounds}
        useMetadata={useMetadata}
        nextId={nextId}
        onPolygonAdded={handlePolygonAdded}
        onPolygonUpdated={handlePolygonUpdated}
        onPolygonDeleted={handlePolygonDeleted}
      />

      <div className="d-flex justify-content-end spaced-top">
        <Tooltip title="Select a specific POI to load in the map view" >
          <Autocomplete
            id="geo-id"
            className="spaced-right"
            options={poiIdOptions}
            getOptionLabel={(option) =>  option["label"]}
            style={{ width: 220 }}
            onChange={(event, option) => {
              setGeoIdValue(option? option["value"] : null);
            }}
            placeholder="Select a location"
          />
        </Tooltip>
        <Tooltip title="Load only the selected location" >
          <Button
            className="spaced-right"
            variant="contained"
            color="secondary"
            onClick={handleLoadGeoById}
            disabled={geoIdValue === null}
          >
            Load Specific Location
          </Button>
        </Tooltip>
        <Tooltip title="Sets the maximum number of returned GPS points" >
          <TextField
            className="textfield spaced-right"
            label="Max Marker Count"
            variant="outlined"
            value={maxMarkers}
            onChange={handleMaxMarkersChange}
            style={{ width: 100 }}
          />
        </Tooltip>
        <Tooltip title="Loads GPS points. Stationary points are shown in red and can assist in defining accurate boundarys for each Location." >
          <Button
            className="spaced-right"
            variant="contained"
            color="secondary"
            onClick={handleLoadGps}
          >
            Load GPS
          </Button>
        </Tooltip>
        <Tooltip title="Loads all Locations in the current view frame" >
          <Button
            className="spaced-right"
            variant="contained"
            color="secondary"
            onClick={handleLoadPolygons}
          >
            Load Locations
          </Button>
        </Tooltip>
        <Tooltip title="Saves all updates made to locations" >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave}
          >
            Save Updates
          </Button>
        </Tooltip>
      </div>

      <Dialog />
      <Loader />
    </React.Fragment>
  );
};

(GeoMapContainerWrapper as DocumentedComponent).metadata = {
  description: "Provides CRUD operations for geofence polygons and markers.",
  isSelfClosing: true,
  attributes: [
    { name: "baseUrl", type: "string", description: "Base URL for API calls" },
    { name: "zoomToBounds", type: "object", description: "The bounds to zoom to, a 2-dimensional array of coordinates.  E.g., `[[-12.5, 23], [-12.75, 23.5]]`" },
    { name: "useMetadata", type: "boolean", description: "If set to true then v1 Asset utilisation code base is used. Allows setting of metadata Web app. False uses v2 Asset utilisation and metadata is set elsewhere." },
  ]
}

export default GeoMapContainerWrapper;
