/* eslint-disable no-shadow-restricted-names */
import './icons.scss';

import { ReactComponent as ALERT_ICON } from '../../assets/icons/alert.svg';
import { ReactComponent as ARCHIVE_ICON } from '../../assets/icons/archive.svg';
import { ReactComponent as AREA_CHART_ICON } from '../../assets/icons/area-chart.svg';
import { ReactComponent as ARROW_BOTH_ICON } from '../../assets/icons/arrow-both.svg';
import { ReactComponent as ARROW_DOWN_1_ICON } from '../../assets/icons/arrow-down-1.svg';
import { ReactComponent as ARROW_DOWN_RIGHT_ICON } from '../../assets/icons/arrow-down-right.svg';
import { ReactComponent as ARROW_DOWN_ICON } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ARROW_LEFT_ICON } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ARROW_RIGHT_ICON } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ARROW_UP_LEFT_ICON } from '../../assets/icons/arrow-up-left.svg';
import { ReactComponent as ARROW_UP_RIGHT_ICON } from '../../assets/icons/arrow-up-right.svg';
import { ReactComponent as ARROW_UP_ICON } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as AVATAR_ICON } from '../../assets/icons/avatar.svg';
import { ReactComponent as BACK_ICON } from '../../assets/icons/back.svg';
import { ReactComponent as BAR_CHART_ICON } from '../../assets/icons/bar-chart.svg';
import { ReactComponent as BEAKER_ICON } from '../../assets/icons/beaker.svg';
import { ReactComponent as BELL_SLASH_ICON } from '../../assets/icons/bell-slash.svg';
import { ReactComponent as BELL_ICON } from '../../assets/icons/bell.svg';
import { ReactComponent as BLOCKED_ICON } from '../../assets/icons/blocked.svg';
import { ReactComponent as BOLD_ICON } from '../../assets/icons/bold.svg';
import { ReactComponent as BOOK_ICON } from '../../assets/icons/book.svg';
import { ReactComponent as BOOKMARK_SLASH_ICON } from '../../assets/icons/bookmark-slash.svg';
import { ReactComponent as BOOKMARK_ICON } from '../../assets/icons/bookmark.svg';
import { ReactComponent as BOX_ICON } from '../../assets/icons/box.svg';
import { ReactComponent as BRIEFCASE_ICON } from '../../assets/icons/briefcase.svg';
import { ReactComponent as BROWSER_ICON } from '../../assets/icons/browser.svg';
import { ReactComponent as BUBBLE_CHART_ICON } from '../../assets/icons/bubble-chart.svg';
import { ReactComponent as BURGER_MENU_ICON } from '../../assets/icons/burger-menu.svg';
import { ReactComponent as CALENDAR_ICON } from '../../assets/icons/calendar.svg';
import { ReactComponent as CAMERA_ICON } from '../../assets/icons/camera.svg';
import { ReactComponent as CANDLESTICK_CHART_ICON } from '../../assets/icons/candlestick-chart.svg';
import { ReactComponent as CHAPTER_ICON } from '../../assets/icons/chapter.svg';
import { ReactComponent as CHECK_ICON } from '../../assets/icons/check.svg';
import { ReactComponent as CHECKBOX_MINUS_ICON } from '../../assets/icons/checkbox-minus.svg';
import { ReactComponent as CHECKLIST_ICON } from '../../assets/icons/checklist.svg';
import { ReactComponent as CHEVRON_DOWN_ICON } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as CHEVRON_LEFT_ICON } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as CHEVRON_RIGHT_ICON } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as CHEVRON_UP_ICON } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as CIRCLE_CHECK_ICON } from '../../assets/icons/circle-check.svg';
import { ReactComponent as CIRCLE_PLUS_ICON } from '../../assets/icons/circle-plus.svg';
import { ReactComponent as CIRCLE_SLASH_ICON } from '../../assets/icons/circle-slash.svg';
import { ReactComponent as CIRCLE_ICON } from '../../assets/icons/circle.svg';
import { ReactComponent as CLIPPY_ICON } from '../../assets/icons/clippy.svg';
import { ReactComponent as CLOCK_ICON } from '../../assets/icons/clock.svg';
import { ReactComponent as COLLAPSE_ICON } from '../../assets/icons/collapse.svg';
import { ReactComponent as COLUMN_CHART_ICON } from '../../assets/icons/column-chart.svg';
import { ReactComponent as COMBO_CHART_ICON } from '../../assets/icons/combo-chart.svg';
import { ReactComponent as COMMENT_DISCUSSION_ICON } from '../../assets/icons/comment-discussion.svg';
import { ReactComponent as COMMENT_ICON } from '../../assets/icons/comment.svg';
import { ReactComponent as CREDIT_CARD_ICON } from '../../assets/icons/credit-card.svg';
import { ReactComponent as CROSS_REFERENCE_ICON } from '../../assets/icons/cross-reference.svg';
import { ReactComponent as DASH_ICON } from '../../assets/icons/dash.svg';
import { ReactComponent as DASHBOARD_ICON } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DESKTOP_DEVICE_ICON } from '../../assets/icons/desktop-device.svg';
import { ReactComponent as DESKTOP_DOWNLOAD_ICON } from '../../assets/icons/desktop-download.svg';
import { ReactComponent as DEVICE_CAMERA_VIDEO_ICON } from '../../assets/icons/device-camera-video.svg';
import { ReactComponent as DEVICE_MOBILE_ICON } from '../../assets/icons/device-mobile.svg';
import { ReactComponent as DIAMOND_ICON } from '../../assets/icons/diamond.svg';
import { ReactComponent as DOT_ICON } from '../../assets/icons/dot.svg';
import { ReactComponent as DOUBLE_TAB_ICON } from '../../assets/icons/double-tab.svg';
import { ReactComponent as DOWNLOAD_ICON } from '../../assets/icons/download.svg';
import { ReactComponent as DRAG_VERTICAL_ICON } from '../../assets/icons/drag-vertical.svg';
import { ReactComponent as DUPLICATE_ICON } from '../../assets/icons/duplicate.svg';
import { ReactComponent as EXPAND_ICON } from '../../assets/icons/expand.svg';
import { ReactComponent as EXPORT_ICON } from '../../assets/icons/export.svg';
import { ReactComponent as EYE_ICON } from '../../assets/icons/eye.svg';
import { ReactComponent as FILE_MEDIA_ICON } from '../../assets/icons/file-media.svg';
import { ReactComponent as FILTER_ICON } from '../../assets/icons/filter.svg';
import { ReactComponent as FIRE_ICON } from '../../assets/icons/fire.svg';
import { ReactComponent as FLIP_ICON } from '../../assets/icons/flip.svg';
import { ReactComponent as FOLD_DOWN_ICON } from '../../assets/icons/fold-down.svg';
import { ReactComponent as FOLD_UP_ICON } from '../../assets/icons/fold-up.svg';
import { ReactComponent as FOLD_ICON } from '../../assets/icons/fold.svg';
import { ReactComponent as GEO_CHART_ICON } from '../../assets/icons/geo-chart.svg';
import { ReactComponent as GIFT_ICON } from '../../assets/icons/gift.svg';
import { ReactComponent as GLOBE_ICON } from '../../assets/icons/globe.svg';
import { ReactComponent as GRABBER_ICON } from '../../assets/icons/grabber.svg';
import { ReactComponent as GRAPH_ICON } from '../../assets/icons/graph.svg';
import { ReactComponent as GRID_ICON } from '../../assets/icons/grid.svg';
import { ReactComponent as GROUP_ICON } from '../../assets/icons/group.svg';
import { ReactComponent as HASH_24_ICON } from '../../assets/icons/hash-24.svg';
import { ReactComponent as HEADING_ICON } from '../../assets/icons/heading.svg';
import { ReactComponent as HEADSET_ICON } from '../../assets/icons/headset.svg';
import { ReactComponent as HEART_ICON } from '../../assets/icons/heart.svg';
import { ReactComponent as HISTORY_ICON } from '../../assets/icons/history.svg';
import { ReactComponent as HOME_ICON } from '../../assets/icons/home.svg';
import { ReactComponent as HORIZONTAL_RULE_ICON } from '../../assets/icons/horizontal-rule.svg';
import { ReactComponent as HOURGLASS_ICON } from '../../assets/icons/hourglass.svg';
import { ReactComponent as INBOX_ICON } from '../../assets/icons/inbox.svg';
import { ReactComponent as INFINITY_ICON } from '../../assets/icons/infinity.svg';
import { ReactComponent as INFO_ICON } from '../../assets/icons/info.svg';
import { ReactComponent as INSER_ABOVE_ICON } from '../../assets/icons/inser-above.svg';
import { ReactComponent as INSER_BELOW_ICON } from '../../assets/icons/inser-below.svg';
import { ReactComponent as INVISIBLE_ICON } from '../../assets/icons/invisible.svg';
import { ReactComponent as ITALIC_ICON } from '../../assets/icons/italic.svg';
import { ReactComponent as KEBAB_HORIZONTAL_ICON } from '../../assets/icons/kebab-horizontal.svg';
import { ReactComponent as KEY_ICON } from '../../assets/icons/key.svg';
import { ReactComponent as KEYBOARD_SHORTCUT_ICON } from '../../assets/icons/keyboard-shortcut.svg';
import { ReactComponent as LABELS_ICON } from '../../assets/icons/labels.svg';
import { ReactComponent as LAW_ICON } from '../../assets/icons/law.svg';
import { ReactComponent as LIGHTBULB_ICON } from '../../assets/icons/lightbulb.svg';
import { ReactComponent as LINK_ICON } from '../../assets/icons/link.svg';
import { ReactComponent as LIST_ORDERED_ICON } from '../../assets/icons/list-ordered.svg';
import { ReactComponent as LIST_UNORDERED_ICON } from '../../assets/icons/list-unordered.svg';
import { ReactComponent as LOADING_ICON } from '../../assets/icons/loading.svg';
import { ReactComponent as LOCATION_ICON } from '../../assets/icons/location.svg';
import { ReactComponent as LOCK_ICON } from '../../assets/icons/lock.svg';
import { ReactComponent as MAIL_ICON } from '../../assets/icons/mail.svg';
import { ReactComponent as MEGAPHONE_ICON } from '../../assets/icons/megaphone.svg';
import { ReactComponent as MENTION_ICON } from '../../assets/icons/mention.svg';
import { ReactComponent as METER_ICON } from '../../assets/icons/meter.svg';
import { ReactComponent as MILESTONE_ICON } from '../../assets/icons/milestone.svg';
import { ReactComponent as MINUS_ICON } from '../../assets/icons/minus.svg';
import { ReactComponent as MIRROR_ICON } from '../../assets/icons/mirror.svg';
import { ReactComponent as MOON_ICON } from '../../assets/icons/moon.svg';
import { ReactComponent as MORTAR_BOARD_ICON } from '../../assets/icons/mortar-board.svg';
import { ReactComponent as MOUSE_ICON } from '../../assets/icons/mouse.svg';
import { ReactComponent as MOVE_ICON } from '../../assets/icons/move.svg';
import { ReactComponent as MULTI_SELECT_ICON } from '../../assets/icons/multi-select.svg';
import { ReactComponent as MUTE_ICON } from '../../assets/icons/mute.svg';
import { ReactComponent as NORTH_STAR_ICON } from '../../assets/icons/north-star.svg';
import { ReactComponent as NOTE_ICON } from '../../assets/icons/note.svg';
import { ReactComponent as NUMBER_ICON } from '../../assets/icons/number.svg';
import { ReactComponent as ORGANISATIONAL_CHART_ICON } from '../../assets/icons/organisational-chart.svg';
import { ReactComponent as PAGE_ICON } from '../../assets/icons/page.svg';
import { ReactComponent as PAINTBRUSH_ICON } from '../../assets/icons/paintbrush.svg';
import { ReactComponent as PAPER_AIRPLANE_ICON } from '../../assets/icons/paper-airplane.svg';
import { ReactComponent as PAUSE_ICON } from '../../assets/icons/pause.svg';
import { ReactComponent as PENCIL_ICON } from '../../assets/icons/pencil.svg';
import { ReactComponent as PIE_CHART_ICON } from '../../assets/icons/pie-chart.svg';
import { ReactComponent as PIN_ICON } from '../../assets/icons/pin.svg';
import { ReactComponent as PLAY_ICON } from '../../assets/icons/play.svg';
import { ReactComponent as PLOT_AREA_ICON } from '../../assets/icons/plot-area.svg';
import { ReactComponent as PLUG_ICON } from '../../assets/icons/plug.svg';
import { ReactComponent as PLUS_ICON } from '../../assets/icons/plus.svg';
import { ReactComponent as PPT_ICON } from '../../assets/icons/ppt.svg';
import { ReactComponent as PROJECT_ICON } from '../../assets/icons/project.svg';
import { ReactComponent as PULSE_ICON } from '../../assets/icons/pulse.svg';
import { ReactComponent as QUESTION_ICON } from '../../assets/icons/question.svg';
import { ReactComponent as QUOTE_ICON } from '../../assets/icons/quote.svg';
import { ReactComponent as RADAR_CHART_ICON } from '../../assets/icons/radar-chart.svg';
import { ReactComponent as REPLY_ICON } from '../../assets/icons/reply.svg';
import { ReactComponent as REPORT_ICON } from '../../assets/icons/report.svg';
import { ReactComponent as ROCKET_ICON } from '../../assets/icons/rocket.svg';
import { ReactComponent as RUBY_ICON } from '../../assets/icons/ruby.svg';
import { ReactComponent as SAVE_ICON } from '../../assets/icons/save.svg';
import { ReactComponent as SCATTER_CHART_ICON } from '../../assets/icons/scatter-chart.svg';
import { ReactComponent as SCREEN_FULL_1_ICON } from '../../assets/icons/screen-full-1.svg';
import { ReactComponent as SCREEN_FULL_ICON } from '../../assets/icons/screen-full.svg';
import { ReactComponent as SCREEN_NORMAL_ICON } from '../../assets/icons/screen-normal.svg';
import { ReactComponent as SEARCH_ICON } from '../../assets/icons/search.svg';
import { ReactComponent as SERIES_ICON } from '../../assets/icons/series.svg';
import { ReactComponent as SETTINGS_1_ICON } from '../../assets/icons/settings-1.svg';
import { ReactComponent as SETTINGS_ICON } from '../../assets/icons/settings.svg';
import { ReactComponent as SHARE_ANDROID_ICON } from '../../assets/icons/share-android.svg';
import { ReactComponent as SHARE_ICON } from '../../assets/icons/share.svg';
import { ReactComponent as SIGN_IN_ICON } from '../../assets/icons/sign-in.svg';
import { ReactComponent as SIGN_OUT_ICON } from '../../assets/icons/sign-out.svg';
import { ReactComponent as SQUARE_ICON } from '../../assets/icons/square.svg';
import { ReactComponent as STACKED_BAR_CHART_ICON } from '../../assets/icons/stacked-bar-chart.svg';
import { ReactComponent as STACKED_COLUMN_CHART_ICON } from '../../assets/icons/stacked-column-chart.svg';
import { ReactComponent as STAR_ICON } from '../../assets/icons/star.svg';
import { ReactComponent as STOP_ICON } from '../../assets/icons/stop.svg';
import { ReactComponent as STOPWATCH_ICON } from '../../assets/icons/stopwatch.svg';
import { ReactComponent as STRIKETHROUGH_ICON } from '../../assets/icons/strikethrough.svg';
import { ReactComponent as SUN_ICON } from '../../assets/icons/sun.svg';
import { ReactComponent as SYNC_ICON } from '../../assets/icons/sync.svg';
import { ReactComponent as T_ICON } from '../../assets/icons/t.svg';
import { ReactComponent as TAB_1_ICON } from '../../assets/icons/tab-1.svg';
import { ReactComponent as TAB_ICON } from '../../assets/icons/tab.svg';
import { ReactComponent as TABLE_ICON } from '../../assets/icons/table.svg';
import { ReactComponent as TAG_ICON } from '../../assets/icons/tag.svg';
import { ReactComponent as TASKLIST_ICON } from '../../assets/icons/tasklist.svg';
import { ReactComponent as TELESCOPE_ICON } from '../../assets/icons/telescope.svg';
import { ReactComponent as TEST_ICON } from '../../assets/icons/test.svg';
import { ReactComponent as TOOLS_ICON } from '../../assets/icons/tools.svg';
import { ReactComponent as TRASH_ICON } from '../../assets/icons/trash.svg';
import { ReactComponent as TREE_CHART_ICON } from '../../assets/icons/tree-chart.svg';
import { ReactComponent as TREND_ICON } from '../../assets/icons/trend.svg';
import { ReactComponent as TRIANGLE_DOWN_ICON } from '../../assets/icons/triangle-down.svg';
import { ReactComponent as TRIANGLE_LEFT_ICON } from '../../assets/icons/triangle-left.svg';
import { ReactComponent as TRIANGLE_RIGHT_ICON } from '../../assets/icons/triangle-right.svg';
import { ReactComponent as TRIANGLE_UP_ICON } from '../../assets/icons/triangle-up.svg';
import { ReactComponent as TYPOGRAPHY_ICON } from '../../assets/icons/typography.svg';
import { ReactComponent as UNFOLD_ICON } from '../../assets/icons/unfold.svg';
import { ReactComponent as UNLOCK_ICON } from '../../assets/icons/unlock.svg';
import { ReactComponent as UNMUTE_ICON } from '../../assets/icons/unmute.svg';
import { ReactComponent as UNVERIFED_ICON } from '../../assets/icons/unverifed.svg';
import { ReactComponent as UPLOAD_ICON } from '../../assets/icons/upload.svg';
import { ReactComponent as VDT_MENU_GRAPH_ICON } from '../../assets/icons/VDT-menu-graph.svg';
import { ReactComponent as VDT_ICON } from '../../assets/icons/VDT.svg';
import { ReactComponent as VERIFIED_ICON } from '../../assets/icons/verified.svg';
import { ReactComponent as VIDEO_ICON } from '../../assets/icons/video.svg';
import { ReactComponent as VIEW_ICON } from '../../assets/icons/view.svg';
import { ReactComponent as WATERFALL_CHART_ICON } from '../../assets/icons/waterfall-chart.svg';
import { ReactComponent as WIZARD_PIPELINE_ICON } from '../../assets/icons/wizard-pipeline.svg';
import { ReactComponent as X_AXIS_ICON } from '../../assets/icons/x-axis.svg';
import { ReactComponent as X_CIRCLE_ICON } from '../../assets/icons/x-circle.svg';
import { ReactComponent as X_ICON } from '../../assets/icons/x.svg';
import { ReactComponent as Y_AXIS_ICON } from '../../assets/icons/y-axis.svg';
import { ReactComponent as ZAP_ICON } from '../../assets/icons/zap.svg';
import { ReactComponent as ZOOM_IN_ICON } from '../../assets/icons/zoom-in.svg';
import { ReactComponent as ZOOM_OUT_ICON } from '../../assets/icons/zoom-out.svg';

interface IconProps {
    size?: "small" | "medium" | "large";
    color?: "white" | "black" | string;
}

export const Alert = (props: IconProps) => <ALERT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Archive = (props: IconProps) => <ARCHIVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const AreaChart = (props: IconProps) => <AREA_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowBoth = (props: IconProps) => <ARROW_BOTH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowDown1 = (props: IconProps) => <ARROW_DOWN_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowDownRight = (props: IconProps) => <ARROW_DOWN_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowDown = (props: IconProps) => <ARROW_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowLeft = (props: IconProps) => <ARROW_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowRight = (props: IconProps) => <ARROW_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowUpLeft = (props: IconProps) => <ARROW_UP_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowUpRight = (props: IconProps) => <ARROW_UP_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ArrowUp = (props: IconProps) => <ARROW_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Avatar = (props: IconProps) => <AVATAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Back = (props: IconProps) => <BACK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BarChart = (props: IconProps) => <BAR_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Beaker = (props: IconProps) => <BEAKER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BellSlash = (props: IconProps) => <BELL_SLASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Bell = (props: IconProps) => <BELL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Blocked = (props: IconProps) => <BLOCKED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Bold = (props: IconProps) => <BOLD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Book = (props: IconProps) => <BOOK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BookmarkSlash = (props: IconProps) => <BOOKMARK_SLASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Bookmark = (props: IconProps) => <BOOKMARK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Box = (props: IconProps) => <BOX_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Briefcase = (props: IconProps) => <BRIEFCASE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Browser = (props: IconProps) => <BROWSER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BubbleChart = (props: IconProps) => <BUBBLE_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const BurgerMenu = (props: IconProps) => <BURGER_MENU_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Calendar = (props: IconProps) => <CALENDAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Camera = (props: IconProps) => <CAMERA_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CandlestickChart = (props: IconProps) => <CANDLESTICK_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Chapter = (props: IconProps) => <CHAPTER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Check = (props: IconProps) => <CHECK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CheckboxMinus = (props: IconProps) => <CHECKBOX_MINUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Checklist = (props: IconProps) => <CHECKLIST_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronDown = (props: IconProps) => <CHEVRON_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronLeft = (props: IconProps) => <CHEVRON_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronRight = (props: IconProps) => <CHEVRON_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ChevronUp = (props: IconProps) => <CHEVRON_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CircleCheck = (props: IconProps) => <CIRCLE_CHECK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CirclePlus = (props: IconProps) => <CIRCLE_PLUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CircleSlash = (props: IconProps) => <CIRCLE_SLASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Circle = (props: IconProps) => <CIRCLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Clippy = (props: IconProps) => <CLIPPY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Clock = (props: IconProps) => <CLOCK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Collapse = (props: IconProps) => <COLLAPSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ColumnChart = (props: IconProps) => <COLUMN_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ComboChart = (props: IconProps) => <COMBO_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CommentDiscussion = (props: IconProps) => <COMMENT_DISCUSSION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Comment = (props: IconProps) => <COMMENT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CreditCard = (props: IconProps) => <CREDIT_CARD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const CrossReference = (props: IconProps) => <CROSS_REFERENCE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Dash = (props: IconProps) => <DASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Dashboard = (props: IconProps) => <DASHBOARD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DesktopDevice = (props: IconProps) => <DESKTOP_DEVICE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DesktopDownload = (props: IconProps) => <DESKTOP_DOWNLOAD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DeviceCameraVideo = (props: IconProps) => <DEVICE_CAMERA_VIDEO_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DeviceMobile = (props: IconProps) => <DEVICE_MOBILE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Diamond = (props: IconProps) => <DIAMOND_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Dot = (props: IconProps) => <DOT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DoubleTab = (props: IconProps) => <DOUBLE_TAB_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Download = (props: IconProps) => <DOWNLOAD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const DragVertical = (props: IconProps) => <DRAG_VERTICAL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Duplicate = (props: IconProps) => <DUPLICATE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Expand = (props: IconProps) => <EXPAND_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Export = (props: IconProps) => <EXPORT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Eye = (props: IconProps) => <EYE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const FileMedia = (props: IconProps) => <FILE_MEDIA_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Filter = (props: IconProps) => <FILTER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Fire = (props: IconProps) => <FIRE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Flip = (props: IconProps) => <FLIP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const FoldDown = (props: IconProps) => <FOLD_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const FoldUp = (props: IconProps) => <FOLD_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Fold = (props: IconProps) => <FOLD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const GeoChart = (props: IconProps) => <GEO_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Gift = (props: IconProps) => <GIFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Globe = (props: IconProps) => <GLOBE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Grabber = (props: IconProps) => <GRABBER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Graph = (props: IconProps) => <GRAPH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Grid = (props: IconProps) => <GRID_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Group = (props: IconProps) => <GROUP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Hash24 = (props: IconProps) => <HASH_24_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Heading = (props: IconProps) => <HEADING_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Headset = (props: IconProps) => <HEADSET_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Heart = (props: IconProps) => <HEART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const History = (props: IconProps) => <HISTORY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Home = (props: IconProps) => <HOME_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const HorizontalRule = (props: IconProps) => <HORIZONTAL_RULE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Hourglass = (props: IconProps) => <HOURGLASS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Inbox = (props: IconProps) => <INBOX_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Infinity = (props: IconProps) => <INFINITY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Info = (props: IconProps) => <INFO_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const InserAbove = (props: IconProps) => <INSER_ABOVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const InserBelow = (props: IconProps) => <INSER_BELOW_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Invisible = (props: IconProps) => <INVISIBLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Italic = (props: IconProps) => <ITALIC_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const KebabHorizontal = (props: IconProps) => <KEBAB_HORIZONTAL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Key = (props: IconProps) => <KEY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const KeyboardShortcut = (props: IconProps) => <KEYBOARD_SHORTCUT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Labels = (props: IconProps) => <LABELS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Law = (props: IconProps) => <LAW_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Lightbulb = (props: IconProps) => <LIGHTBULB_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Link = (props: IconProps) => <LINK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ListOrdered = (props: IconProps) => <LIST_ORDERED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ListUnordered = (props: IconProps) => <LIST_UNORDERED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Loading = (props: IconProps) => <LOADING_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Location = (props: IconProps) => <LOCATION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Lock = (props: IconProps) => <LOCK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mail = (props: IconProps) => <MAIL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Megaphone = (props: IconProps) => <MEGAPHONE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mention = (props: IconProps) => <MENTION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Meter = (props: IconProps) => <METER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Milestone = (props: IconProps) => <MILESTONE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Minus = (props: IconProps) => <MINUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mirror = (props: IconProps) => <MIRROR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Moon = (props: IconProps) => <MOON_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const MortarBoard = (props: IconProps) => <MORTAR_BOARD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mouse = (props: IconProps) => <MOUSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Move = (props: IconProps) => <MOVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const MultiSelect = (props: IconProps) => <MULTI_SELECT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Mute = (props: IconProps) => <MUTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const NorthStar = (props: IconProps) => <NORTH_STAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Note = (props: IconProps) => <NOTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Number = (props: IconProps) => <NUMBER_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const OrganisationalChart = (props: IconProps) => <ORGANISATIONAL_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Page = (props: IconProps) => <PAGE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Paintbrush = (props: IconProps) => <PAINTBRUSH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const PaperAirplane = (props: IconProps) => <PAPER_AIRPLANE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pause = (props: IconProps) => <PAUSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pencil = (props: IconProps) => <PENCIL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const PieChart = (props: IconProps) => <PIE_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pin = (props: IconProps) => <PIN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Play = (props: IconProps) => <PLAY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const PlotArea = (props: IconProps) => <PLOT_AREA_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Plug = (props: IconProps) => <PLUG_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Plus = (props: IconProps) => <PLUS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Ppt = (props: IconProps) => <PPT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Project = (props: IconProps) => <PROJECT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Pulse = (props: IconProps) => <PULSE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Question = (props: IconProps) => <QUESTION_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Quote = (props: IconProps) => <QUOTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const RadarChart = (props: IconProps) => <RADAR_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Reply = (props: IconProps) => <REPLY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Report = (props: IconProps) => <REPORT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Rocket = (props: IconProps) => <ROCKET_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Ruby = (props: IconProps) => <RUBY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Save = (props: IconProps) => <SAVE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScatterChart = (props: IconProps) => <SCATTER_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScreenFull1 = (props: IconProps) => <SCREEN_FULL_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScreenFull = (props: IconProps) => <SCREEN_FULL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ScreenNormal = (props: IconProps) => <SCREEN_NORMAL_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Search = (props: IconProps) => <SEARCH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Series = (props: IconProps) => <SERIES_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Settings1 = (props: IconProps) => <SETTINGS_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Settings = (props: IconProps) => <SETTINGS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ShareAndroid = (props: IconProps) => <SHARE_ANDROID_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Share = (props: IconProps) => <SHARE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const SignIn = (props: IconProps) => <SIGN_IN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const SignOut = (props: IconProps) => <SIGN_OUT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Square = (props: IconProps) => <SQUARE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const StackedBarChart = (props: IconProps) => <STACKED_BAR_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const StackedColumnChart = (props: IconProps) => <STACKED_COLUMN_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Star = (props: IconProps) => <STAR_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Stop = (props: IconProps) => <STOP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Stopwatch = (props: IconProps) => <STOPWATCH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Strikethrough = (props: IconProps) => <STRIKETHROUGH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Sun = (props: IconProps) => <SUN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Sync = (props: IconProps) => <SYNC_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const T = (props: IconProps) => <T_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tab1 = (props: IconProps) => <TAB_1_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tab = (props: IconProps) => <TAB_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Table = (props: IconProps) => <TABLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tag = (props: IconProps) => <TAG_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tasklist = (props: IconProps) => <TASKLIST_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Telescope = (props: IconProps) => <TELESCOPE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Test = (props: IconProps) => <TEST_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Tools = (props: IconProps) => <TOOLS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Trash = (props: IconProps) => <TRASH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TreeChart = (props: IconProps) => <TREE_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Trend = (props: IconProps) => <TREND_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleDown = (props: IconProps) => <TRIANGLE_DOWN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleLeft = (props: IconProps) => <TRIANGLE_LEFT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleRight = (props: IconProps) => <TRIANGLE_RIGHT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const TriangleUp = (props: IconProps) => <TRIANGLE_UP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Typography = (props: IconProps) => <TYPOGRAPHY_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unfold = (props: IconProps) => <UNFOLD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unlock = (props: IconProps) => <UNLOCK_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unmute = (props: IconProps) => <UNMUTE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Unverifed = (props: IconProps) => <UNVERIFED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Upload = (props: IconProps) => <UPLOAD_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const VDTMenuGraph = (props: IconProps) => <VDT_MENU_GRAPH_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const VDT = (props: IconProps) => <VDT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Verified = (props: IconProps) => <VERIFIED_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Video = (props: IconProps) => <VIDEO_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const View = (props: IconProps) => <VIEW_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const WaterfallChart = (props: IconProps) => <WATERFALL_CHART_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const WizardPipeline = (props: IconProps) => <WIZARD_PIPELINE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const XAxis = (props: IconProps) => <X_AXIS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const XCircle = (props: IconProps) => <X_CIRCLE_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const X = (props: IconProps) => <X_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const YAxis = (props: IconProps) => <Y_AXIS_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const Zap = (props: IconProps) => <ZAP_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ZoomIn = (props: IconProps) => <ZOOM_IN_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
export const ZoomOut = (props: IconProps) => <ZOOM_OUT_ICON className={`icon ${props?.size || "medium"} ${props?.color}`} />;
