import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";
import { RootState, useThunkDispatch } from "../../../store";
import { updateCurrentCanvasState } from "../../../store/storyline/actions";

type CanvasContextProps = {
    onEnter: () => void;
    onExit: () => void;
    onParameterValuesChanged: () => void;
    onParameterValueChanged: (parameterName: string, newValue: any, oldValue: any) => void;
    onFrameDataChanged: () => void;
    onFieldValueChanged: (fieldName: string, newValue: any, oldValue: any) => void;
    onDatasourceRefreshStart: (datasourceName: string) => void;
    onDatasourceRefreshEnd: (datasourceName: string) => void;
    enableFunctionCaching: boolean;
};

const _CanvasContext: React.FunctionComponent<CanvasContextProps> = (props) => {
    const { onEnter, onExit, onParameterValuesChanged, onParameterValueChanged, onFrameDataChanged, onFieldValueChanged, onDatasourceRefreshStart, onDatasourceRefreshEnd, children, enableFunctionCaching, ...fields } = props;
    const parameterValues = useSelector((s: RootState) => s.storyline.parameterValues);
    const currentFrame = useSelector((s: RootState) => s.storyline.currentFrame.frame);
    const datasourcesInFlight = useSelector((s: RootState) => s.storyline.datasourcesInFlight);
    const dispatch = useThunkDispatch();
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [lastParameterValues, setLastParameterValues] = React.useState(parameterValues);
    const [lastFrameData, setLastFrameData] = React.useState(currentFrame);
    const [cachedFunctions] = React.useState({});
    const [lastDatasourcesInFlight, setLastDatasourcesInFlight] = React.useState([]);

    React.useEffect(() => {
        onEnter && onEnter();
        dispatch(updateCurrentCanvasState(fields));
        setIsInitialized(true);

        if (enableFunctionCaching) {
            for (let [key, value] of Object.entries(fields)) {
                if (_.isFunction(value)) {
                    cachedFunctions[key] = value;
                }
            }
        }

        return onExit;
    }, []);

    React.useLayoutEffect(() => {
        if (enableFunctionCaching) {
            for (let [key, value] of Object.entries(fields)) {
                if (_.isFunction(value)) {
                    if (cachedFunctions[key]) {
                        fields[key] = cachedFunctions[key];
                    }
                    else {
                        cachedFunctions[key] = value;
                    }
                }
            }
        }

        dispatch(updateCurrentCanvasState(fields));
    }, [parameterValues, currentFrame]);

    React.useEffect(() => {
        onParameterValuesChanged && onParameterValuesChanged();
    }, [parameterValues, onParameterValuesChanged]);

    React.useEffect(() => {
        if (!parameterValues || !onParameterValueChanged) return;

        for (let key of parameterValues.keys()) {
            const newValue = parameterValues.get(key);

            if (!lastParameterValues.has(key)) {
                onParameterValueChanged?.(key, newValue, null);
            } else {
                const oldValue = lastParameterValues.get(key);

                if (!_.isEqual(oldValue, newValue)) {
                    onParameterValueChanged?.(key, newValue, oldValue);
                    // Rapid parameter value changes cause a race condition where this effect gets invoked multiple times before the "lastParameterValues"
                    // state can actually be updated.  This optimization mutates the old value immediately, ensuring that we only call the `onParameterValueChanged` 
                    // once per parameter...
                    lastParameterValues.set(key, newValue);
                }
            }
        }

        setLastParameterValues(new Map(parameterValues));
    }, [parameterValues, onParameterValueChanged]);

    React.useEffect(() => {
        if (!currentFrame) return;
        if (!onFrameDataChanged && !onFieldValueChanged) return;

        if (!_.isEqual(lastFrameData, currentFrame)) {
            onFrameDataChanged && onFrameDataChanged();

            for (let [key, newValue] of Object.entries(currentFrame)) {
                if (!Object.keys(lastFrameData).find(k => k === key)) {
                    onFieldValueChanged?.(key, newValue, null);
                } else {
                    const oldValue = lastFrameData?.[key];

                    if (!_.isEqual(oldValue, newValue)) {
                        onFieldValueChanged?.(key, newValue, oldValue);
                    }
                }
            }
        }

        setLastFrameData(_.clone(currentFrame));
    }, [currentFrame, onFrameDataChanged, onFieldValueChanged]);

    React.useEffect(() => {
        const datasourcesInFlightArray = Array.from(datasourcesInFlight.values());

        const requestsAdded = _.difference(Array.from(datasourcesInFlight.values()), lastDatasourcesInFlight);
        const requestsRemoved = _.difference(lastDatasourcesInFlight, datasourcesInFlightArray);

        requestsAdded.forEach(r => onDatasourceRefreshStart?.(r));
        requestsRemoved.forEach(r => onDatasourceRefreshEnd?.(r));

        setLastDatasourcesInFlight(datasourcesInFlightArray);
    }, [datasourcesInFlight]);

    if (!isInitialized) return <></>;

    return <>{children}</>;
};

const CanvasContext: React.FunctionComponent<CanvasContextProps> = (props) => {
    const { children } = props;
    const staticPlot = useStaticPlot();

    return staticPlot ?
        <>{children}</> :
        <_CanvasContext {...props} />;
};

(CanvasContext as DocumentedComponent).metadata = {
    description: `The \`CanvasContext\` component combines the functionality of the \`CanvasEventHandler\` and \`CanvasState\` components into a single component.  
This component is intended to be used as the root element of all templates, with the actual content included as its children.

The benefit of using \`CanvasContext\` (over \`CanvasEventHandler\` + \`CanvasState\`) is related to timing issues and race conditions.
For example, the standalone \`onEnter\` event of the \`CanvasEventHandler\` executes on the first render of the template - any parameter value changes made therein are only reflected when the template is re-rendered (triggered by the parameter value changes).  
This results in flickering and/or errors related to these values not being available during the first render loop.  

In contrast, the \`CanvasContext\` component delays the rendering of its children until the \`onEnter\` callback has completed and the _state_ fields have been populated according to the input props.  
This prevents flickering on initial load and any errors related to the initial values being unavailable on first render.`,
    attributes: [
        { name: `onEnter`, type: "function", template: `onEnter={() => {\n\t$1\n}}`, description: `Callback that is executed when a user navigates to the canvas.` },
        { name: `onLeave`, type: "function", template: `onLeave={() => {\n\t$1\n}}`, description: `Callback that is executed when a user navigates away from the canvas.` },
        { name: `onParameterValueChanged`, type: "function", template: `onParameterValueChanged={(parameterName, newValue, oldValue) => {\n\t$1\n}}`, description: `Callback that is executed when a parameter value has changed.  The \`parameterName\` input parameter can be used to determine which parameter was changed, whereas the \`newValue\` parameter will contain the updated value and \`oldValue\` will contain the previous value.` },
        { name: `onParameterValuesChanged`, type: "function", template: `onParameterValuesChanged={() => {\n\t$1\n}}`, description: `Callback that is executed when the parameter values have been updated.  The function can use \`this.parameterValues\` to access the parameter values map.` },
        { name: `onFrameDataChanged`, type: "function", template: `onFrameDataChanged={() => {\n\t$1\n}}`, description: `Callback that is executed when the frame data for the canvas has changed.` },
        { name: `onFieldValueChanged`, type: "function", template: `onFieldValueChanged={(fieldName, newValue, oldValue) => {\n\t$1\n}}`, description: `Callback that is executed when a field in the frame data has changed.  The \`fieldName\` input parameter can be used to determine which field has changed, whereas the \`newValue\` parameter will contain the updated value and \`oldValue\` will contain the previous value.` },
        { name: `onDatasourceRefreshStart`, type: "function", template: `onDatasourceRefreshStart={(datasourceName) => {\n\t$1\n}}`, description: `Callback that is executed when a datasource is being refreshed.  The network request should be in-flight at this stage.  The \`datasourcesInFlight\` field (available to the template bindings) should be preferred above manual parameter value management via this callback and its response counterpart.` },
        { name: `onDatasourceRefreshEnd`, type: "function", template: `onDatasourceRefreshEnd={(datasourceName) => {\n\t$1\n}}`, description: `Callback that is executed when a datasource refresh response has been received.  Useful in cases where dependent datasources need to be refreshed after some persistence action has occurred.  A timestamp or version response from the datasource can also be used to track this, but this callback may be cleaner/simpler in some cases.` },
        { name: `enableFunctionCaching`, type: "boolean", description: "If `true`, functions declared within this `CanvasContext` will be cached.  This can offer significant performance benefits if these functions are used as input props for data-heavy components.  **NB: Only use this option if all the declared functions are pure with regards to canvas state (no dependencies on any canvas state).**  Optional, defaults to `false`." },
    ]
};

export { CanvasContext };