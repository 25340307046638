import React from "react";
import { Avatar, IconButton, MenuItem, ListItemIcon, Menu } from "@mui/material";
import { ExitToApp, Settings } from "@mui/icons-material";
import { useAuth0 } from "../../auth/AuthContext";
import { Link } from "react-router-dom";

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(_name: string) {
    const name = _name ?? "Anonymous User";

    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')?.[0]?.[0]}${name.split(' ')?.[1]?.[0]}`,
    };
}

export default function UserProfileBadge(props) {
    const { user, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton id="btn-user-profile" aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar className="small" alt={user?.name} src={user?.picture} {...stringAvatar(user?.name)} />
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled className="menu-header">{user?.name}</MenuItem>
                <hr />
                <Link to="/preferences">
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon><Settings /></ListItemIcon>
                        Preferences
                    </MenuItem>
                </Link>
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                    <ListItemIcon><ExitToApp /></ListItemIcon>
                    Log Out
                </MenuItem>
            </Menu>
        </>
    );
}