import React from "react";
import { Vdt as BaseVdt, VdtProps as BaseVdtProps } from "../../../shared/components";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { ValueDriverTreeNode } from "../../../shared/components/Vdt/VdtNode";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";
import { useStructuralMemo } from "../../../shared/hooks";

function* iterateTree(currentNode: ValueDriverTreeNode<any>): Generator<ValueDriverTreeNode<any>> {
    yield currentNode;

    for (const child of (currentNode?.nodes ?? [])) {
        yield* iterateTree(child);
    }
}

interface VdtProps extends BaseVdtProps {
    persistNodeStatusTo?: string;
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
}

function _Vdt(props: VdtProps) {
    const { root: _root, persistNodeStatusTo, parameterValues, updateParameterValue, staticPlot: propStaticPlot, ...rest } = props;

    // Use structural equality check for root data to prevent unnecessary redraws and rebinds...
    const root = useStructuralMemo(_root);
    const [flattenedNodes, setFlattenedNodes] = React.useState(Array.from(iterateTree(JSON.parse(JSON.stringify(root)))));
    React.useEffect(() => {
        setFlattenedNodes(Array.from(iterateTree(JSON.parse(JSON.stringify(root)))));
    }, [root]);
    React.useEffect(() => {
        if (persistNodeStatusTo && flattenedNodes && parameterValues.has(persistNodeStatusTo)) {
            const nodeStatuses = parameterValues.get(persistNodeStatusTo);
            Object.entries(nodeStatuses).forEach(([id, collapsed]: [string, boolean]) => {
                const node = flattenedNodes?.find(n => n.id == id);
                if (node) { node.collapsed = collapsed; }
            })
        }
    }, [parameterValues]);

    // If the user is passing in an explicit value for the `staticPlot` prop, use that, otherwise use the canvas-level value...
    const canvasStaticPlot = useStaticPlot();
    const staticPlot = propStaticPlot !== undefined && propStaticPlot !== null ? propStaticPlot : canvasStaticPlot;

    const handleCollapseExpand = (collapsed: boolean) => (node: ValueDriverTreeNode<any>) => {
        flattenedNodes.find(n => n.id === node.id).collapsed = collapsed;

        if (persistNodeStatusTo) {
            const newStatusMap = Object.fromEntries(flattenedNodes.map(n => [n.id, n.collapsed]));
            updateParameterValue(persistNodeStatusTo, newStatusMap);
        }
    };

    return (<BaseVdt
        root={root}
        staticPlot={staticPlot}
        {...rest}
        // Persist the collapsed/expanded status to the original VDT node in order to persist across slide/frame transitions...
        onCollapse={handleCollapseExpand(true)}
        onExpand={handleCollapseExpand(false)}
        nodeStatusMap={persistNodeStatusTo ? parameterValues?.get(persistNodeStatusTo) : undefined}
    />);
}

const Vdt = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(_Vdt);

(Vdt as DocumentedComponent).metadata = {
    description: "The VDT component renders a hierarchical list of nodes in a tree format.  This is a bespoke D3-based component.",
    isSelfClosing: true,
    attributes: [
        { 
            name: `root`, 
            type: `object`, 
            description: 
                `The root node of the tree.  Required.  See below for the structure of the \`VDTNode\` object.
                
### VDTNode Fields:

| Name | Type | Description |
|------|------|-------------|
| \`id\` | \`number \\| string\` | The unique id for this node.  Must be unique across all nodes in the current VDT. |
| \`title\` | \`string\` | The title of the node.  Displayed in the header when in the "full" view and across the node body when in the "simple" view.  Supports JSX, HTML, Markdown and plain text via the relevant prefix. |
| \`type\` | \`'fields' \\| 'chart' \\| 'custom'\` | The type of the node. \`fields\` nodes display a list of fields.  \`chart\` nodes display a Plotly chart inside the node body, with the \`data\` and \`layout\` fields populated in the \`model\`.  \`custom\` nodes allow the user to render a completely custom VDT body, driven by the \`template\` specified.  Optional - defaults to \`fields\`. |
| \`class\` | \`string\` | The CSS class to apply to this node.  Determines the default background color for the "simple" node body and the background color for the progress bar.  Predefined classes are: \`positive\` (green), \`negative\` (red), \`warning\` (orange) and \`deemphasized\` (gray). |
| \`fields\` | \`VDTField[]\` | The fields to display in the node body.  Each field is rendered as a row in the node body.  Only visible in the "full" view, when the node \`type\` is \`fields\` or empty.  See below for the structure of the \`VDTField\` object. |
| \`template\` | \`string\` | The custom template used to render the body of the node.  Only applicable if the node type is set to \`custom\`.  All the fields inside \`model\`, as well as the node fields (\`title\`, \`progressBarValue\` and \`progressBarText\`) and canvas values are available to the template.  In the case of a naming conflict, the \`model\` fields will override the canvas-level fields.|
| \`model\` | \`object\` | Data required for rendering non-standard nodes (those with a type other than \`fields\`) is passed in here.  In the case of \`chart\` nodes, this contains the \`data\` and \`layout\` objects required for rendering a Plotly chart. |
| \`backgroundColor\` | \`string\` | An optional color to use for the background of this node when in the "simple" (zoomed-out) view.  Overrides the background color specified by the node's CSS class. |
| \`foregroundColor\` | \`string\` | An optional color to use for the title of this node when in the "simple" (zoomed-out) view.  Overrides the foreground color specified by the node's CSS class. |
| \`progressBarValue\` | \`number\` | A value between 0 and 1 to indicate what percentage of the progress bar should be filled in. |
| \`progressBarText\` | \`string\` | The text to overlay on top of the progress bar.  Usually contains the human-friendly value of the impact for this node. |
| \`progressBarColor\` | \`string\` | The fill color to apply to the shaded portion of the progress bar.  Overrides the value from the CSS class applied to the node.  Optional - default value is already driven by the node's \`class\` property. |
| \`collapsed\` | \`boolean\` | Indicates whether the node should be collapsed (children are hidden) upon initial render.  Does not prevent the user from collapsing/expanding the node after the initial render.  Optional - defaults to \`false\`. |
| \`hideTitle\` | \`boolean\` | Hides node title in order to free up space.  Only applies to certain node types - \`chart\` only at the moment.  Optional - defaults to \`false\`. |
| \`hideProgressBar\` | \`boolean\` | Hides progress bar.  Optional - defaults to \`false\`. |
| \`nodes\` | \`VDTNode[]\` | The child nodes for this node.  These nodes will be drawn below the current node with connecting lines - indicating the parent/child relationship between them.  Empty/null if the current node is a leaf node. | 

### VDTField Fields:

| Name | Type | Description |
|------|------|-------------|
| \`name\` | \`string\` | The title of the field.  Left-aligned text value displayed in the field row.  Supports JSX, HTML, Markdown and plain text via the relevant prefix. |
| \`value\` | \`string\` | The value of the field.  Right-aligned text value displayed in the field row.  Supports JSX, HTML, Markdown and plain text via the relevant prefix. |
| \`class\` | \`string\` | The CSS class to apply to this field row.  Useful for color-coding or highlighting rows. |
| \`isEditable\` | \`boolean\` | If \`true\`, the field is rendered as a \`TextField\`, allowing the user to edit the value.  The \`onFieldValueEdited\` event handler is invoked once the user has finished updating the value (on blur). |` 
        },
        { name: `nodeWidth`, type: `number`, description: "The width of each VDT node in pixels.  Optional - defaults to `200`." },
        { name: `nodeHeight`, type: `number`, description: "The height of each VDT node in pixels.  Optional - defaults to `85`." },
        { name: `siblingSpacing`, type: `number`, description: "The number of horizontal pixels between nodes that share the same parent.  Optional - defaults to `40`." },
        { name: `parentSpacing`, type: `number`, description: "The number of vertical pixels between a node and its parent.  Optional - defaults to `40`." },
        { name: `neighbourSpacingFactor`, type: `number`, description: "The ratio (of the node width) of spacing to have between nodes on the same level that do not share the same parent.  Optional - defaults to `0.2`." },
        { name: `displayModeTransitionLevel`, type: `number`, description: "The zoom level at which to swap between the \"simple\" and \"full\" VDT display modes.  Lower numbers let the nodes stay in \"full\" mode for longer, higher numbers let the nodes stay in \"simple\" mode for longer.  Optional - defaults to `1` (1:1 ratio between content size and display size)." },
        { name: `zoomToFitOnNodeToggle`, type: `boolean`, description: "Indicate whether the VDT should automatically zoom to fit the new contents when collapsing/expanding nodes.  Optional - defaults to `true`." },
        { name: `showProgressBarInSimpleMode`, type: `boolean`, description: "Indicate whether the VDT should display a progress bar inside the nodes when in \"simple\" mode." },
        { name: `hideControls`, type: `boolean`, description: "Optional field that determines whether the VDT should show (`false`) or hide (`true`) the VDT controls on hover.  The default behavior is to show the controls.  Can be hidden in the case of very small VDTs, where the controls overlap with the content." },
        { name: `onFieldValueEdited`, type: `function`, template: `onFieldValueEdited={(input) => { const { node, field } = input; $1} }`, description: "The callback to invoke when a field value has been edited.  The `field` value inside the input object contains the edited field (with the updated value).  Optional - defaults to `null`." },
        { name: `persistNodeStatusTo`, type: `string`, description: "The (optional) name of the parameter to persist the `collapsed` status of all nodes to.  Can be used to persist the VDT status across data refreshes or to synchronize multiple VDTs.  The node id is used for matching nodes across VDTs." },
        { name: `onNodeClick`, type: `function`, template: `onNodeClick={(node) => { $1 } }`, description: "The callback function to invoke when the user clicks on a node." },
        { name: `onNodeTitleClick`, type: `function`, template: `onNodeTitleClick={(node) => { $1 } }`, description: "The callback function to invoke when the user clicks on a node title." },
        { name: `defaultCustomNodeTemplate`, type: `string`, description: "The (optional) default template to use for custom nodes.  Overridden by the `template` field of individual nodes, if present." },
    ]
};

export { Vdt };