import { AppState, AppActionTypes, LOAD_APP, SHOW_APP, TOGGLE_MENU, SET_BREADCRUMB_STACK, SET_MENU_IS_EXPANDED } from "./types";

const initialState: AppState = {
    loading: true,
    menuItems: [],
    menuIsExpanded: true,
    breadcrumbStack: [{title: "Home"}]
}

export function appReducer(
    state = initialState,
    action: AppActionTypes
): AppState {
    switch (action.type) {
        case LOAD_APP:
            return {
                ...state,
                loading: true
            }

        case SHOW_APP:
            return {
                ...state,
                loading: false,
                menuItems: action.menuItems
            }

        case TOGGLE_MENU:
            return {
                ...state,
                menuIsExpanded: !state.menuIsExpanded
            }

        case SET_BREADCRUMB_STACK:
            return {
                ...state,
                breadcrumbStack: action.breadcrumbStack
            }

        case SET_MENU_IS_EXPANDED:
            return {
                ...state,
                menuIsExpanded: action.value
            }
        
        default:
            return state;
    }
}