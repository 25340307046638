import React from "react";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RootState } from "../../../store";
import { connect } from "react-redux";

import { Dialog, DialogTitleWithCloseButton } from "../../../shared/components";
import { Breakpoint } from "@mui/material";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface ModalProps {
    title: string,
    visibilityParameter: string,
    maxWidth?: Breakpoint
    children: any,
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue
}

function _Modal(props: ModalProps) {
    const { title, visibilityParameter, parameterValues, updateParameterValue, maxWidth, children, ...rest } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        const parameterValue = parameterValues.get(visibilityParameter);
        setIsOpen(!!parameterValue);
    }, [parameterValues, visibilityParameter]);

    const closeDialog = () => {
        setIsOpen(false);
        updateParameterValue(visibilityParameter, false);
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={maxWidth || "md"}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title"
            disablePortal={true}
            {...rest}>
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                {title}
            </DialogTitleWithCloseButton>
            {children}
        </Dialog>
    );
}

const Modal = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(_Modal));

(Modal as DocumentedComponent).metadata = {
    description: "The Modal component serves as a pop-up window, which can contain any other components as nested children.  Visibility of the modal is determined by the value of the specified parameter.",
    isSelfClosing: false,
    attributes: [
        { name: `title`, type: `string`, description: "The text to display in the heading of the modal dialog." },
        { name: `visibilityParameter`, type: `string`, description: `The name of the parameter used to determine the visibility of the Modal.  Any _truthy_ value is interpreted as "visible" and any _falsey_ value is interpreted as "hidden".` },
        { name: `maxWidth`, type: "string", options: [`xs`, `sm`, `md`, `lg`, `xl`], description: "The maximum width of the modal.  Optional - defaults to `md`." },
    ]
};

export default Modal;