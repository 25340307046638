import "./PersistentTabPanel.scss";
import React from 'react';
import { RootState } from "../../../store";
import { useSelector } from 'react-redux'
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface TabPanelProps {
    tabsName: string,
    value: string,
    children: any,
    className: string
}

function PersistentTabPanel(props: TabPanelProps) {
    const { tabsName, value, children, className, ...other } = props;
    const parameterValues = useSelector((state: RootState) => state.storyline.parameterValues);

    const parameterName = `tabs-${tabsName}`;

    const [activeTab, setActiveTab] = React.useState(parameterValues.get(parameterName));

    React.useEffect(() => {
        if (parameterValues.has(parameterName)) {
            const newValue = parameterValues.get(parameterName);

            if (newValue !== activeTab) {
                setActiveTab(newValue);
            }
        }
    }, [parameterValues]);

    return (
        <div {...other} className={`${className} persistent-tab ${activeTab === value ? "" : "persistent-tab-hidden"}`}>
            {children}
        </div>
    );
}

(PersistentTabPanel as DocumentedComponent).metadata = {
    description: "The PersistentTabPanel behaves in exactly the same way as the TabPanel.  However, unlike the TabPanel, the PersistentTabPanel maintains its current state when tab switching occurs.  This is useful for tab panels that contain forms, as the user can switch between tabs without losing their form data.",
    isSelfClosing: false,
    attributes: [
        { name: `tabsName`, type: `string`, description: "The name of the `Tabs` component that this `TabPanel` is linked to.  This allows for multiple groups of `Tabs` per canvas." },
        { name: `value`, type: `string`, description: "The value of the `Tab` that is linked with this panel.  When a `Tab` is selected, the panel with the matching value is displayed." },
    ]
};

export default PersistentTabPanel;