import { RootState } from "..";
import {
    SET_CONNECTION_STATUS,
    SetConnectionStatusAction,
    ServerToClientMessage,
    ConnectionStatus,
    LoadTemplateClientToServerMessage,
    LoadComponentDocumentationClientToServerMessage,
    LoadCanvasDataClientToServerMessage,
    LoadCssClassesClientToServerMessage
} from "./types";
import {
    UPDATE_CURRENT_SLIDE_TEMPLATE,
    UpdateCurrentSlideTemplate
} from "../storyline/types";
import { components } from "../../viewer/Canvas/Canvas";
import { DocumentedComponent } from "../../shared/components/DocumentedComponent";
import { saveCurrentCanvasTemplate } from "../storyline/actions";
import * as _ from "lodash";

export type ConnectionSettings = {
    url: string
}

const defaultConfig: ConnectionSettings = {
    url: "ws://127.0.0.1:11223"
};

let connection: WebSocket;

function setupConnection(connection: WebSocket, dispatch: any) {
    connection.onmessage = ((event: MessageEvent<string>) => {
        const message = JSON.parse(event.data) as ServerToClientMessage;
        switch (message.type) {
            case "REQUEST_TEMPLATE":
                dispatch(sendCurrentTemplateToVsCode());
                return;

            case "SAVE_TEMPLATE":
                dispatch(saveCurrentCanvasTemplate(message.template, message.css));
                return;

            case "UPDATE_TEMPLATE":
                dispatch({
                    type: UPDATE_CURRENT_SLIDE_TEMPLATE,
                    newTemplate: message.template,
                    newCustomCss: message.css
                } as UpdateCurrentSlideTemplate);
                return;
        }
    });
}

function setConnectionStatus(status: ConnectionStatus) {
    return {
        type: SET_CONNECTION_STATUS,
        status
    } as SetConnectionStatusAction;
}

export function openWsConnection() {
    return async (dispatch) => {
        connection = new WebSocket(defaultConfig.url);
        setupConnection(connection, dispatch);

        connection.onopen = (e) => {
            dispatch(setConnectionStatus("OPEN"));
            sendCurrentComponentDocumentationToVsCode();
            dispatch(sendCanvasDataToVsCode());
        }
        connection.onerror = (e) => {
            console.error(e);
            dispatch(setConnectionStatus("ERROR"));
        };

        connection.onclose = (e) => {
            dispatch(setConnectionStatus("CLOSED"));
        }
    }
}

export function closeWsConnection() {
    return async (dispatch, _getState: () => RootState) => {
        dispatch(setConnectionStatus("CLOSED"));
    }
}

export function sendCurrentTemplateToVsCode() {
    return async (_dispatch, getState: () => RootState) => {
        const currentTemplate = getState()?.storyline?.currentFrame?.template;
        if (!currentTemplate) return;

        if (connection?.readyState === WebSocket.OPEN) {
            connection.send(JSON.stringify({
                type: "LOAD_TEMPLATE",
                template: currentTemplate.contents,
                css: currentTemplate.customCss
            } as LoadTemplateClientToServerMessage));
        }
    }
}

export function sendCurrentComponentDocumentationToVsCode() {
    const componentDocumentationEntries = Object.entries(components)
        .map(([name, component]: [string, DocumentedComponent]) => [name, component?.metadata ?? { attributes: [] }]);

    if (connection?.readyState === WebSocket.OPEN) {
        connection.send(JSON.stringify({
            type: "LOAD_COMPONENT_DOCUMENTATION",
            components: Object.fromEntries(componentDocumentationEntries)
        } as LoadComponentDocumentationClientToServerMessage));
    }
}

const FUNCTIONS_DOCUMENTATION = {
    formatNumber: {
        kind: "function",
        signature: `function formatNumber(value: number, decimalPlaces = 2, prefix = "", suffix = "", multiplicationFactor = 1)`,
        description: "Formats a number to the given specification.",
        template: `formatNumber($1, $2)`
    }, formatDate: {
        kind: "function",
        signature: `function formatDate(value: string | Date | moment.Moment, formatString = "YYYY-MM-DD HH:mm")`,
        description: "Formats a date to the given specification.",
        template: `formatDate($1, $2)`
    }, updateParameterValueAction: {
        kind: "function",
        signature: `function updateParameterValueAction(parameterName: string, newValue: any, isInitialLoad = false, preventDatasourceRefresh = false, sourceDatasourceId: string = null)`,
        description: "Updates the value of a storyline parameter.",
        template: `updateParameterValueAction("$1", $2)`
    }, applyParameterValueChangesAction: {
        kind: "function",
        signature: `function applyParameterValueChangesAction()`,
        description: "Triggers a manual refresh of all pending datasources which are 'dirty' due to parameter value changes.  Only applies to datasources which are explicitly excluded from automatic refreshes.",
        template: `applyParameterValueChangesAction()`
    }, refreshDatasourceByName: {
        kind: "function",
        signature: `function refreshDatasourceByName(name: string, mapResultToDatasourceParameters = false)`,
        description: "Explicitly refreshes a datasource by name, even if no parameter value changes have occurred since the last refresh.  If `mapResultToDatasourceParameters` is true, any field names in the result set which matches a parameter name, will be used to overwrite the previous parameter value.",
        template: `refreshDatasourceByName("$1", true)`
    }, setDatasourceData: {
        kind: "function",
        signature: `function setDatasourceData(datasourceName: string, data: Object[])`,
        description: "Replaces the canvas data for the specified datasource.  NOTE: If a datasource with the specified name does not exist, a new one is created and linked to all canvases in the storyline.  This allows for shadowing/overriding the standard frame data values with custom ones, in the same way that parameter values do.",
        template: `setDatasourceData("$1", [{}])`
    }, patchDatasourceData: {
        kind: "function",
        signature: `function patchDatasourceData(datasourceName: string, diff: Object)`,
        description: "Patches the canvas data for the specified datasource, using the provided diff object.  Values within the diff object will be used to modify the frame data across all frames within the data source.  NOTE: If a datasource with the specified name does not exist, a new one is created and linked to all canvases in the storyline.  This allows for shadowing/overriding the standard frame data values with custom ones, in the same way that parameter values do.",
        template: `patchDatasourceData("$1", {})`
    }, showError: {
        kind: "function",
        signature: `function showError(message: string)`,
        description: "Displays a toast notification with the given message, in the style of an error message.",
        template: `showError("$1")`
    }, showSuccess: {
        kind: "function",
        signature: `function showSuccess(message: string)`,
        description: "Displays a toast notification with the given message, in the style of a success message.",
        template: `showSuccess("$1")`
    }, showWarning: {
        kind: "function",
        signature: `function showWarning(message: string)`,
        description: "Displays a toast notification with the given message, in the style of a warning message.",
        template: `showWarning("$1")`
    }, showInfo: {
        kind: "function",
        signature: `function showInfo(message: string)`,
        description: "Displays a toast notification with the given message, in the style of an info message.",
        template: `showInfo("$1")`
    }, showDetailedError: {
        kind: "function",
        signature: `function showDetailedError(message: string, details = "")`,
        description: "Displays a toast notification with the given message, in the style of a error message.  The `details` text is displayed inside an expandable area inside the message body.",
        template: `showDetailedError("$1", "$2")`
    }, goToID: {
        kind: "function",
        signature: `function goToID(id: string)`,
        description: "Immediately navigates to the first frame of the canvas with the given name/id.  Only applies to canvases within the current storyline.",
        template: `goToID("$1")`
    }, goToXYZ: {
        kind: "function",
        signature: `function goToXYZ(xIndex: number, yIndex: number, frameIndex: number)`,
        description: "Immediately navigates to the frame with the given coordinates.  X = chapter index, Y = page index, Z = frame index.",
        template: `goToXYZ($1, $2, $3)`
    }, clsx: {
        kind: "function",
        signature: `function clsx(...classes: ClassArray | ClassDictionary | string | number | null | boolean | undefined): string`,
        description: "Helper function for building up a dynamic class list string.  Takes an arbitrary number of input arguments.  Each argument is evaluated in turn and any 'falsey' values are discarded.  Space-separates the surviving class name list, for use in `className` bindings. See the [library documentation](https://github.com/lukeed/clsx#usage) for usage examples.",
        template: `clsx($1)`
    }, navigateToPage: {
        kind: "function",
        signature: `function navigateToPage(targetUrl: string, additionalParameters: Object = {}, openNewTab: boolean = false, passNavFiltersValues: boolean = true): void`,
        description: "Navigates to the provided URL.  By default, all parameters contained within a `NavFilters` component is persisted to local storage and rehydrated on the other side of the navigation request.  Additional parameter values provided are passed through as query parameters.",
        template: `navigateToPage($1)`
    }
};

function canvasDataSerializer(key: string, value: unknown): any {
    if (typeof value === "function") {
        return value.toString()?.slice(0, 100);
    }

    return value;
}

export function sendCanvasDataToVsCode() {
    return async (_dispatch, getState: () => RootState) => {
        if (connection?.readyState === WebSocket.OPEN) {
            const canvasData = {
                ...window["canvasBindings"],
                functions: FUNCTIONS_DOCUMENTATION
            };

            if (!canvasData) return;

            connection.send(JSON.stringify({
                type: "LOAD_CANVAS_DATA",
                canvasData
            } as LoadCanvasDataClientToServerMessage, canvasDataSerializer));
        }
    }
}

function getAllCssClassNamesForCurrentDocument() {
    var classNameGrabber = /\.([A-Za-z0-9\-_]*)(?:,|:|$|\s)+/g;

    var styleSheets = Array.from(document.styleSheets);
    var styleSheetRules = styleSheets
        .flatMap(ss =>
            Array.from(ss.cssRules).map(ssr => ssr["selectorText"]))
        .filter(ssr => ssr);
    var classNames = styleSheetRules.flatMap(ssr =>
        Array.from(ssr.matchAll(classNameGrabber)).map(m => m[1])
    );

    return _.uniq(classNames.filter(cn => !cn.startsWith("_") && !cn.startsWith("css")));
}

export function sendCssClassesToVsCode() {
    if (connection?.readyState === WebSocket.OPEN) {
        const classNames = getAllCssClassNamesForCurrentDocument();

        if (!classNames) return;

        connection.send(JSON.stringify({
            type: "LOAD_CSS_CLASSES",
            classNames
        } as LoadCssClassesClientToServerMessage));
    }
}
