import * as React from "react";
import "./TreeView.scss";
import { TreeView as BaseTreeView } from "../../../shared/components";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

function getFullHierarchy(node: TreeViewNode): TreeViewNode[] {
    const allChildren = _.flatMap(node.children, getFullHierarchy);
    return [node, ...allChildren];
}

function containsSelectedNodes(selectedNodeValues: any[], currentNode: TreeViewNode): boolean {
    if (selectedNodeValues.find(nodeValue => _.isEqual(currentNode.value, nodeValue))) {
        return true;
    }
    if (!currentNode.children) {
        return false;
    }

    return !!currentNode.children.find(node => containsSelectedNodes(selectedNodeValues, node));
}

function addBackwardsCompatibility(node: TreeViewNode) {
    node.value = node.value ?? node["id"];
    node.label = node.label ?? node["title"];
    node.children?.forEach?.(addBackwardsCompatibility);
}

export interface TreeViewNode {
    value?: string;
    label: string;
    children?: TreeViewNode[]
    isExpanded?: boolean;
    isSelected?: boolean;
}

interface TreeViewProps {
    nodes: TreeViewNode[];
    multiSelect?: boolean;
    onNodeSelect: (selectedNodes: TreeViewNode[]) => void;
    name?: string;
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
    showSearch?: boolean;
}

function _TreeView(props: TreeViewProps) {
    const { nodes, onNodeSelect, multiSelect, name, parameterValues, updateParameterValue, showSearch, ...rest } = props;
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [highlighted, setHighlighted] = React.useState<string[]>([]);
    const [currentNodes, setCurrentNodes] = React.useState([]);

    React.useEffect(() => {
        nodes?.forEach?.(addBackwardsCompatibility);
        if (currentNodes?.length === nodes?.length && _.isMatch(currentNodes, nodes)) return;
        setCurrentNodes(nodes);

        const defaultExpandedNodeIds = Array.isArray(nodes) ? _.flatMap(nodes, getFullHierarchy).filter(n => n.isExpanded).map(n => n.value) : [];
        setExpanded(defaultExpandedNodeIds);

        const defaultSelectedNodeIds = Array.isArray(nodes) ? _.flatMap(nodes, getFullHierarchy).filter(n => n.isSelected).map(n => n.value) : [];
        setSelected(defaultSelectedNodeIds);

        const allNodes = _.flatMap(nodes, getFullHierarchy);
        const highlightedNodes = allNodes.filter(n => containsSelectedNodes(defaultSelectedNodeIds, n)).map(n => n.value);
        setHighlighted(highlightedNodes);
    }, [nodes]);

    React.useEffect(() => {
        if (name && parameterValues.has(name)) {
            const parameterValue = parameterValues.get(name);
            const newSelected: any[] = multiSelect ? parameterValue : [parameterValue];
            if (selected?.length !== newSelected?.length || !_.isMatch(selected, newSelected)) {
                setSelected(newSelected);

                // Expand all the ancestors of the selected nodes...
                const allNodes = _.flatMap(nodes, getFullHierarchy);
                const expandedNodes = allNodes.filter(n => containsSelectedNodes(newSelected, n)).map(n => n.value);
                setExpanded([...expanded, ...expandedNodes]);
                setHighlighted([...expandedNodes]);
            }
        }
    }, [parameterValues, nodes]);

    const handleSelect = React.useCallback((selectedNodes: TreeViewNode[]) => {
        if (onNodeSelect) {
            onNodeSelect(selectedNodes);
        }

        if (name) {
            updateParameterValue(name, multiSelect ? selectedNodes.map(n => n.value) : selectedNodes?.[0]?.value);
        }
    }, [onNodeSelect, name, multiSelect]);

    return (
        <BaseTreeView
            {...rest}
            defaultExpanded={multiSelect ? expanded : expanded?.[0]}
            defaultHighlighted={multiSelect ? highlighted : highlighted?.[0]}
            defaultSelected={multiSelect ? selected : selected?.[0]}
            nodes={currentNodes}
            multiSelect={multiSelect}
            onSelectionChanged={handleSelect}
            showSearch={showSearch}
        />
    );
}

const TreeView = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(_TreeView);

(TreeView as DocumentedComponent).metadata = {
    description: "The TreeView component renders hierarchical data in a collapsible tree structure.",
    isSelfClosing: true,
    attributes: [
        { name: `name`, type: `string`, description: "The name of the parameter to persist the selected Node Value(s) to.  If `multiselect` is enabled, the persisted value will be an array, otherwise it is a string value.  Optional - automated parameter read/write behaviour is disabled if the parameter name is not specified, but the `onNodeSelect` callback can still be used for this purpose." },
        { name: `nodes`, type: `object`, description: "The nodes to display in the tree.  See below for the structure of `TreeViewNode`." },
        { name: `multiSelect`, type: `boolean`, description: "If `true`, allows the user to select multiple items by holding down `Ctrl` or `Shift`.  Optional - defaults to `false`." },
        { name: `showSearch`, type: `boolean`, description: "If `true`, renders a text field that allows the user to search within the TreeView.  Optional - defaults to `false`." },
        { name: `onNodeSelect`, type: "function", template: `onNodeSelect={(selectedNodes) => {$1}}`, description: "The callback function to execute when a node is selected.  If `multiSelect` is `false`, the input parameter is a single-element array." },
    ]
};

export { TreeView };