import React from "react";
import "./CanvasTour.scss";
import RunOnceTour from "../../../tours/RunOnceTour";
import Joyride, { Props as JoyrideProps } from "react-joyride";
import * as colors from "../../../shared/colors";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

const defaultOptions = {
    continuous: true,
    showSkipButton: true,
    spotlightPadding: 0,
    disableScrolling: true,
    locale: {
        back: 'Back',
        close: 'Close',
        last: 'Close',
        next: 'Next',
        skip: 'Skip Tour'
    },
    floaterProps: {
        disableAnimation: true
    },
    showProgress: true,
    disableBeacon: true
}

const styles = {
    options: {
        zIndex: 1400,
        primaryColor: colors.blue_1
    }
}

export interface CanvasTourProps extends JoyrideProps {
    autoRun?: boolean;
    runOnce?: boolean;
}

function CanvasTour(props: any) {
    const { autoRun, runOnce } = props;
    const [run, setRun] = React.useState(false);

    // Add a delay to the tour's start in order to make sure the slide transition/render has completed...
    React.useEffect(() => {
        autoRun && setTimeout(() => setRun(true), 1500);
    }, [autoRun]);

    const TourComponent = runOnce ? RunOnceTour : Joyride;

    const steps = props?.steps?.map(s => ({
        ...s,
        content: <div dangerouslySetInnerHTML={{ __html: s?.content || "" }} />
    }));

    return (
        <TourComponent
            {...defaultOptions}
            styles={styles}
            {...props}
            run={autoRun ? run : props.run}
            steps={steps}
        />
    );
}

(CanvasTour as DocumentedComponent).metadata = {
    description: "The CanvasTour component can be used to display a guided tour for a canvas.  The tour consists of multiple steps, each highlighting a portion of the canvas and displaying a message explaining the highlighted section.  This component is a wrapper around the `React Joyride` library - consult [the documentation](https://docs.react-joyride.com/) for all the available options.",
    isSelfClosing: true,
    attributes: [
        { name: `runOnce`, type: `boolean`, description: "Determines whether the tour should run only once - or each time the user navigates to this frame.  If`true`, the`id` field is used to uniquely identify this tour.  Defaults to`false`." },
        { name: `id`, type: `string`, description: "The unique identifier for this tour.  Only used if `runOnce` is set to `true`.  If populated, the tour will only be shown the first time a user views the canvas on a device.  Note: this value must be unique across all canvases, since the `id` is persisted to local storage in order to keep track of viewed tours." },
        {
            name: `steps`, type: `object`, template: `steps={[\n{ title: "", content: "", target: "", disableBeacon: true }\n]}`, description: `The steps for this tour.  Each step will highlight a selected portion of the canvas and display a relevant message.  The user can navigate back and forth between the different steps.  See below for the structure of the \`Step\` object.\n\n

## Step Fields:

| Name | Type | Description |
|------|------|-------------|
| \`title\` | \`string\` | The title to display in the header of the step dialog. |
| \`content\` | \`string\` | The content to display in the body of the step dialog.  Supports plain text and HTML markup. |
| \`target\` | \`string\` | The CSS selector used to determine the element to highlight during this step.  Can use any of the standard CSS selectors - \`Type\`, \`Class\`, \`ID\`, \`Attribute\`, etc.  See [this link](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors) for the complete documentation on CSS Selectors. |
| \`disableBeacon\` | \`boolean\` | In Joyride, a beacon is used to start or continue a tour.  If this property is set to \`true\`, the pop-up for this step is automatically shown when a tour is started or the user closes the previous step.  If not set (or set to \`false\`), the pop-up has to be manually triggered by a user, by clicking on the beacon. |
| \`placement\` | \`string\` | Optional field that determines where the dialog should be shown in relation to the highlighted element.  Valid options are: \`"top"\`, \`"bottom"\`, \`"left"\` and \`"right"\`. |` },
        { name: `autoRun`, type: `boolean`, description: "Determines whether the tour should start automatically when the canvas is viewed for the first time.  If`false`, the developer would have to add a button or similar to the canvas that can trigger a manual run." },
    ]
};

export default CanvasTour;