import "./Map.scss";
import React from "react";
import Plot from "react-plotly.js";
import { useResizeDetector } from 'react-resize-detector';
import { connect } from "react-redux";
import { updateParameterValue } from "../../../store/storyline/actions";
import { RootState } from "../../../store";
import MapControls from "./MapControls";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";

const style = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

const defaultMapboxAccessToken = "pk.eyJ1IjoiZGJhcnJldHRic2MiLCJhIjoiY2tjeW1zMWE1MGJlcjMxb3VwdHp1ejR3diJ9.NFsoLer0ez7eDYU-SpAY3Q";

interface ZoomOverride {
    center: {
        lat: number;
        lon: number;
    };
    zoom?: number;
}

interface DataPointHighlightOptions {
    markerSize: number;
    markerColor: string;
    dataPointFilter: Function;
}

interface Props {
    input: any,
    maxSelectionCount?: number,
    parameterName?: string,
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue,
    onMaxSelected?: Function,
    onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void,
    staticPlot?: boolean,
    zoomOverride?: ZoomOverride,
    persistUserZoom?: boolean,
    dataPointHighlightOptions: DataPointHighlightOptions
}

function _Map(props: Props) {
    // Just attaching a resize listener to the parent of the chart causes the component to re-render when the size changes.  No need to explicitly call resize on the Plotly element...
    const { width: _width, height: _height, ref } = useResizeDetector();
    const { input, maxSelectionCount, parameterName, parameterValues, updateParameterValue, onMaxSelected, onClick, zoomOverride, persistUserZoom, dataPointHighlightOptions, staticPlot: propStaticPlot } = props;
    const canvasStaticPlot = useStaticPlot();
    // If the user is passing in an explicit value for the `staticPlot` prop, use that, otherwise use the canvas-level value...
    const staticPlot = propStaticPlot !== undefined && propStaticPlot !== null ? propStaticPlot : canvasStaticPlot;
    const { data, layout } = input || {};
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [lastZoomDetails, setLastZoomDetails] = React.useState(null);
    const [mapStyle, setMapStyle] = React.useState(layout?.mapbox?.style || "light");
    const [mapData, setMapData] = React.useState([{
        lat: [],
        lon: [],
        mode: "markers",
        type: "scattermapbox"
    }] as any[]);
    const [mapLayout, setMapLayout] = React.useState(null);

    const dataPointClicked = React.useCallback(
        (data) => {
            if (!maxSelectionCount || !parameterName) return;

            if (data?.points?.[0]?.customdata) {
                const item = {
                    "id": data.points[0].customdata.id,
                    "name": data.points[0].customdata.name,
                    "lat": data.points[0].lat,
                    "lon": data.points[0].lon
                };

                const selectedItemsMinusThisPoint = selectedItems.filter(i => i.id !== item.id);

                // Remove item if it already exists in the list, otherwise add it...
                const newItems = selectedItemsMinusThisPoint.length !== selectedItems.length ?
                    selectedItemsMinusThisPoint :
                    [...selectedItems, item];

                const newItemsWithMaxLengthConstraint = newItems.slice(0, maxSelectionCount);

                setSelectedItems(newItemsWithMaxLengthConstraint);
                updateParameterValue(parameterName, newItemsWithMaxLengthConstraint);

                if (onMaxSelected && newItems.length === maxSelectionCount) {
                    onMaxSelected();
                }
            }
        }, [selectedItems, updateParameterValue, parameterName]);

    React.useEffect(() => {
        const newParameterValue = parameterValues.get(parameterName);
        if (!newParameterValue) return;
        setSelectedItems(newParameterValue);
    }, [parameterValues, parameterName]);

    const onRelayout = (e) => {
        persistUserZoom && setLastZoomDetails(e);
    };

    React.useEffect(() => {
        if (layout) {
            setMapLayout({
                ...layout,
                autosize: true,
                mapbox: {
                    ...layout?.mapbox,
                    center: lastZoomDetails?.["mapbox.center"] || layout?.mapbox?.center,
                    zoom: lastZoomDetails?.["mapbox.zoom"] || layout?.mapbox?.zoom,
                    style: mapStyle || layout?.mapbox?.style
                }
            });
        }
        else {
            setMapLayout({
                autosize: true,
                mapbox: {
                    center: lastZoomDetails?.["mapbox.center"] || { lon: 23.84474398368866, lat: -27.644883351235023 },
                    zoom: lastZoomDetails?.["mapbox.zoom"] || 4,
                    style: mapStyle,
                    accesstoken: defaultMapboxAccessToken
                },
                margin: {
                    r: 0,
                    t: 0,
                    l: 0,
                    b: 0
                }
            })
        }
    }, [layout, mapStyle, lastZoomDetails]);

    React.useEffect(() => {
        zoomOverride && setLastZoomDetails({
            "mapbox.center": zoomOverride.center,
            "mapbox.zoom": zoomOverride.zoom
        })
    }, [zoomOverride]);

    React.useEffect(() => {
        if (!data) return;

        const mapDataWithHighlighting = dataPointHighlightOptions ? data.map(layer => {
            const originalMarkerOptions = layer.originalMarker || layer.marker;

            const normalizedLayerData = _.zipWith(layer.lat, layer.lon, layer.customdata, (lat: number, lon: number, customdata: any) => ({ "lat": lat, "lon": lon, "customdata": customdata }));
            const markerOptions = originalMarkerOptions ? {
                ...originalMarkerOptions,
                "size": normalizedLayerData.map(point => dataPointHighlightOptions.dataPointFilter(point) ? (dataPointHighlightOptions.markerSize || originalMarkerOptions.size) : originalMarkerOptions.size),
                "color": normalizedLayerData.map(point => dataPointHighlightOptions.dataPointFilter(point) ? (dataPointHighlightOptions.markerColor || originalMarkerOptions.color) : originalMarkerOptions.color)
            } : undefined;

            return {
                ...layer,
                marker: markerOptions,
                originalMarker: originalMarkerOptions
            };
        }) : data;

        setMapData([
            ...mapDataWithHighlighting,
            {
                lat: selectedItems.map(i => i.lat),
                lon: selectedItems.map(i => i.lon),
                customdata: selectedItems.map(i => ({ id: i.id, name: i.name })),
                marker: {
                    color: "rebeccapurple",
                    size: 20,
                    symbol: "circle"
                },
                mode: "markers",
                name: "Selected Items",
                type: "scattermapbox",
                hoverinfo: "skip"
            }
        ]);

    }, [data, selectedItems, dataPointHighlightOptions, parameterValues]);

    return (
        <div className="fill map" style={{ position: "relative" }} ref={ref}>
            <Plot
                data={mapData}
                layout={mapLayout}
                config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: staticPlot, displayModeBar: false, doubleClick: false }}
                style={style}
                onClick={onClick || dataPointClicked}
                onRelayout={onRelayout}
            />

            <MapControls mapStyle={mapStyle} setMapStyle={setMapStyle} />
        </div>
    );
}

const Map = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(_Map));

(Map as DocumentedComponent).metadata = {
    description:
        `The Map component is a thin wrapper around the \`Plot\` component from the [\`react-plotly.js\`](https://plotly.com/javascript/react/) library.  All additional props are passed directly to the underlying component - please consult the documentation for that component for all the available options.  

This is a specialized version of the standard \`Chart\` component - with a few enhancements specific to maps:
        
* Allows for toggling between the different values available for \`layout.mapbox.style\`
* Persists zoom/pan state between data rebinds
* Shows a default map when no data is bound
* Allows for selecting POIs/data points`,
    isSelfClosing: true,
    attributes: [
        { name: `input`, type: "object", description: "The figure to render.  Traces contained within must be of type `Scattermapbox` or `Scattergeo` in order for the map-specific enhancements to function correctly.  For other trace types use a standard `Chart` component.  See the [Plotly figure documentation](https://plotly.com/python/figure-structure/) for the structure of this object." },
        { name: `staticPlot`, type: `boolean`, description: "This optional property indicates whether the plot should be interactive or static.  Defaults to `true` when the plot is rendered on the Minimap/Tooltip and to `false` when rendered on the main canvas. Can be overridden to always be `true` if a static plot is desired." },
        { name: `maxSelectionCount`, type: `number`, description: "The maximum number of data points that can be selected.  Selected points are persisted against the storyline parameter specified via `parameterName`.  Defaults to `0`, which disables point selection." },
        { name: `parameterName`, type: `string`, description: "The name of the parameter to persist selected data points against.  If no value is specified, selection is disabled." },
        { name: `onMaxSelected`, type: "function", template: "onMaxSelected={() => {$1}}", description: "An optional parameterless function to invoke when the maximum number of points have been selected.  Useful for refreshing data sources or closing a modal once a sufficient number of points have been selected." },
        {
            name: `zoomOverride`,
            type: "object",
            description:
                `The center point and zoom level to force for this map.  Used for programmatic control of pan/zoom from other components or events.  See below for the structure of the \`ZoomOverride\` object.

### ZoomOverride Props:

| Name | Type | Description |
|------|------|-------------|
| \`center\` | \`object\` | The desired center point of the map.  Contains 2 fields - \`lat\` and \`lon\`. |
| \`zoom\` | \`number\` | The desired zoom level. See the [Mapbox documentation](https://docs.mapbox.com/help/glossary/zoom-level/#zoom-levels-and-geographical-distance) for the available zoom levels. |`
        },
        {
            name: `highlightedLayers`,
            type: "object",
            description:
                `The addition layers to create in order to highlight some data.  See below for the structure of the \`HighlightedLayer\` object.
        
### HighlightedLayer Props:

| Name | Type | Description |
|------|------|-------------|
| \`baseLayerName\` | \`string\` | The layer that should be used as the basis for the new layer.  Data from this layer is used to construct the new layer (lat + lon + customdata). |
| \`filterFunction\` | \`(point: {lat, lon, customdata}) => boolean\` | The function used to determine whether a data point is copied over to the new layer. |
| \`newLayerProperties\` | \`object\` | The metadata for the new map layer.  See the [Plotly documentation](https://plotly.com/python/reference/scattermapbox/#scattermapbox) for the available fields. |`
        },
        { name: `persistUserZoom`, type: `boolean`, description: "This property indicates whether to persist the last interactive zoom level/center point across data rebinds.  If `true`, the calculated midpoint/zoom level passed in via the map data is ignored once the user has started interacting with the map." },

    ]

};

export default Map;