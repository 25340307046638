import { connect } from "react-redux";
import { RootState } from "../../store/index";
import _ from "lodash";
import { renderValue } from "./shared";

interface ParameterValuesProps {
    parameterValues: Map<string, any>
}

function ParameterValues(props: ParameterValuesProps) {
    const { parameterValues } = props;

    return (
        <div className="table-container">
            <table className="table bordered no-header key-value">
                <tbody>
                    {
                        _.map(Array.from(parameterValues.entries()), entry => <ParameterValueRow key={entry[0]} entry={entry} />)
                    }
                </tbody>
            </table>
        </div>
    );
}

interface ParameterValueRowProps {
    entry: [string, any]
}

function ParameterValueRow(props: ParameterValueRowProps) {
    const { entry } = props;

    return (
        <tr>
            <td className="heading-cell">{entry[0]}</td>
            <td>{renderValue(entry[1])}</td>
        </tr>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    null)(ParameterValues);