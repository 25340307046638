import * as React from "react";
import { Tooltip as BaseTooltip, TooltipProps } from "@mui/material";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

const Tooltip = React.forwardRef((props: TooltipProps, ref) => {
    const { children, title, classes, PopperProps, ...rest } = props;

    // Workaround for the fact that JSX Parser wraps whitespace-enclosed elements in a fragment by default...
    const sanitizedChildren = _.isArray(children) ? children?.[1] : children;

    // Ensure that the popper renders inside the canvas div, in order to use the user-defined CSS...
    const getPopperContainer = React.useCallback(() => document.querySelector(".main-canvas .jsx-parser"), []);

    const cssClasses = {
        popper: !_.isString(title) && "MuiTooltip-popperDynamicContent"
    };

    return (
        <BaseTooltip
            ref={ref}
            title={title}
            classes={cssClasses}
            children={sanitizedChildren}
            PopperProps={{ ...PopperProps, container: getPopperContainer }}
            {...rest}
        />
    );
});

(Tooltip as DocumentedComponent).metadata = {
    description: `The Tooltip component allows for displaying temporary information when the user hovers over designated canvas elements.

See [the base component's documentation](https://mui.com/material-ui/react-tooltip/) for the full feature list.`,
    isSelfClosing: false,
    attributes: [
        { name: `title`, type: `object`, description: "The content to render inside the Tooltip.  Can be a `string` or any standard template elements." },
        { name: `arrow`, type: `boolean`, description: "If `true`, adds an arrow to the tooltip.  Optional - defaults to `false`." },
    ]
};

export { Tooltip };