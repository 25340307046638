import * as React from "react";
import "./FileUpload.scss";
import { Button } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

interface FileUploadProps {
    onFilesSelected?: (files: File[]) => void;
    buttonContent?: any;
}

function FileUpload(props: FileUploadProps) {
    const { onFilesSelected, buttonContent, ...rest } = props;
    const inputRef = React.useRef<HTMLInputElement>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files.length < 1) return;

        onFilesSelected?.([...files]);
    }

    return (
        <div className="file-upload-container">
            <input type="file" ref={inputRef} onChange={handleChange} {...rest} />
            <Button variant="outlined" onClick={() => { inputRef.current.click(); }}  {...rest}>
                { buttonContent ?? "Select File" }
            </Button>
        </div>
    );
}

(FileUpload as DocumentedComponent).metadata = {
    description: "The `FileUpload` control allows the user to select files for upload.  Selected file(s) must be processed via the `onFilesSelected` event handler, in order to manipulate and persist the contents to parameter value(s).",
    isSelfClosing: true,
    attributes: [
        { name: `multiple`, type: `boolean`, description: "If `true`, multiple files can be selected." },
        { name: `accept`, type: `string`, description: "A comma-separated list of file types that the control will accept.  Comma-separated list of File Types which can be uploaded by the user.  See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept) for more details on this attribute." },
        { name: `onFilesSelected`, type: `function`, description: "The callback to be invoked when the user has selected the files to upload.  Passes in a list of [File](https://developer.mozilla.org/en-US/docs/Web/API/File) objects to be processed.  Reading and processing the file contents must be done here and any required parameter values persisted as required." },
        { name: `buttonContent`, type: `string`, description: "The content to render inside the button.  Optional - defaults to `\"Select File\"`" }
    ]
};

export { FileUpload }