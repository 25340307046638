import "./CrudPanel.scss";
import * as React from "react";
import { updateParameterValue } from "../../../store/storyline/actions";
import { default as Autocomplete, Option } from "../Autocomplete";
import { Button } from "../Button";
import { Pencil } from "../../../shared/components/icons";
import { connect } from "react-redux";
import { CreateDialog } from "./dialogs/CreateDialog";
import { CloneDialog } from "./dialogs/CloneDialog";
import { RenameDialog } from "./dialogs/RenameDialog";
import { ClearDialog } from "./dialogs/ClearDialog";
import { RemoveDialog } from "./dialogs/RemoveDialog";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { CommitDialog } from "./dialogs/CommitDialog";
import { UnlinkDialog } from "./dialogs/UnlinkDialog";
import clsx from "clsx";

type CrudOperation = "create" | "clone" | "rename" | "clear" | "remove";

interface CrudPanelProps {
    entityName: string;
    options: Option[];
    selectedItemParameter: string;
    onCrudOperation?: (operation: string, args?: Object) => void;
    onSelectionChanged?: (newValue: Option) => void;
    availableOperations?: CrudOperation[];
    enableLinkedAndWipEntities?: boolean;
    linkedEntities?: Option[];
    wipEntities?: Option[];
    className?: string;
}

function _CrudPanel(props: CrudPanelProps) {
    const { entityName, options, selectedItemParameter, onCrudOperation, onSelectionChanged, enableLinkedAndWipEntities, linkedEntities, wipEntities, className, ...rest } = props;
    const availableOperations = props.availableOperations ??
        (
            enableLinkedAndWipEntities ?
                ["create", "clone", "rename", "clear", "remove", "commit", "unlink"] :
                ["create", "clone", "rename", "clear", "remove"]
        );
    const [selectedValue, setSelectedValue] = React.useState(null);

    const [isExpanded, setIsExpanded] = React.useState(false);
    const toggleIsExpanded = React.useCallback(() => {
        setIsExpanded(value => !value);
    }, [setIsExpanded]);

    const [currentOperation, setCurrentOperation] = React.useState("");
    const acceptModalCallback = React.useCallback((args: Object) => {
        onCrudOperation && onCrudOperation(currentOperation, args);
        setCurrentOperation("");
    }, [currentOperation, setCurrentOperation, onCrudOperation]);
    const closeModalCallback = React.useCallback(() => {
        setCurrentOperation("");
    }, [setCurrentOperation]);

    const handleSelection = (_event: React.ChangeEvent<HTMLDivElement>, option: Option | Option[]) => {
        setSelectedValue((option as Option));
        onSelectionChanged && onSelectionChanged(option as Option);
    }

    return (
        <div className={clsx("crud-panel", className)}>
            <Autocomplete {...rest} name={selectedItemParameter} options={options} onChange={handleSelection} />
            <Button variant="outlined" color="primary" size="small" onClick={toggleIsExpanded}>
                <Pencil size="small" />
            </Button>
            {
                isExpanded &&
                <div className="crud-buttons">
                    {availableOperations.find(o => o === "create") && <Button variant="outlined" color="success" size="small" onClick={() => setCurrentOperation("create")}>New</Button>}
                    {availableOperations.find(o => o === "clone") && <Button variant="outlined" color="info" size="small" onClick={() => setCurrentOperation("clone")}>Clone</Button>}
                    {availableOperations.find(o => o === "rename") && <Button variant="outlined" color="secondary" size="small" onClick={() => setCurrentOperation("rename")}>Rename</Button>}
                    {availableOperations.find(o => o === "clear") && <Button variant="outlined" color="warning" size="small" onClick={() => setCurrentOperation("clear")}>Clear</Button>}
                    {availableOperations.find(o => o === "remove") && <Button variant="outlined" color="error" size="small" onClick={() => setCurrentOperation("remove")}>Remove</Button>}
                    {enableLinkedAndWipEntities && availableOperations.find(o => o === "commit") && <Button variant="outlined" color="success" size="small" onClick={() => setCurrentOperation("commit")}>Commit</Button>}
                    {enableLinkedAndWipEntities && availableOperations.find(o => o === "unlink") && <Button variant="outlined" color="warning" size="small" onClick={() => setCurrentOperation("unlink")}>Unlink</Button>}
                </div>
            }

            {currentOperation === "create" && <CreateDialog entityName={entityName} entities={options} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} enableLinkedAndWipEntities={enableLinkedAndWipEntities} />}
            {currentOperation === "clone" && <CloneDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "rename" && <RenameDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "clear" && <ClearDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "remove" && <RemoveDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "commit" && <CommitDialog entityName={entityName} entities={wipEntities ?? []} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "unlink" && <UnlinkDialog entityName={entityName} entities={linkedEntities ?? []} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}

        </div>
    );
}

const CrudPanel = connect(
    null,
    { updateParameterValue: updateParameterValue })(_CrudPanel);

(CrudPanel as DocumentedComponent).metadata = {
    description: "The `CrudPanel` component displays a dropdown list of items with basic CRUD functionality thereof.",
    isSelfClosing: true,
    attributes: [
        { name: `entityName`, type: `string`, description: "The type of entity that will be managed by the control.  Used for specialized text in labels and messages." },
        { name: `selectedItemParameter`, type: `string`, description: "The name of the variable that the selected value will be read from and persisted to." },
        { name: `options`, type: `object`, description: "The list of options that will be displayed in the Autocomplete control.  Each `option` contains a `value` and `label`." },
        { name: `onCrudOperation`, type: "function", template: `onCrudOperation={(operation, args) => {$1}}`, description: "The callback function to execute when an operation is requested.  Occurs after the user has completed all required information and clicked on the relevant dialog's \"Submit\" button.  See below for the available operations and the `args` object structure for each." },
        { name: `onSelectionChanged`, type: "function", template: `onSelectionChanged={(newValue) => {$1}}`, description: "The optional callback function to execute when the selected item has changed." },
        { name: `availableOperations`, type: `object`, template: `availableOperations={["create", "clone", "rename", "clear", "remove"]}`, description: "The list of operations that should be available to the user.  Optional - defaults to all." },
        { name: `enableLinkedAndWipEntities`, type: `boolean`, description: "Determines whether to add support for Linked- and Work-in-Progress entities.  This allows the user to select between these 2 additional types when creating an entity and allows for selecting a related (source) entity.  This also allows the use of the \"commit\" and \"unlink\" operations.  Optional - defaults to `false`." },
        { name: `linkedEntities`, type: `object`, description: "The list of linked entities that will be available in the \"Unlink\" dialog.  Only required and applicable if `enableLinkedAndWipEntities` is truthy." },
        { name: `wipEntities`, type: `object`, description: "The list of WIP entities that will be available in the \"Commit\" dialog.  Only required and applicable if `enableLinkedAndWipEntities` is truthy." },

    ]
};

export default CrudPanel;