import * as api from "../../shared/api-client"
import {
    BreadcrumbItem,
    LOAD_APP,
    SET_BREADCRUMB_STACK,
    SET_MENU_IS_EXPANDED,
    SHOW_APP,
    TOGGLE_MENU
} from "./types";
import { initializeDatasourceFetchPipeline } from "../storyline/actions";
import { RootState } from "..";


export function loadApp() {
    return async (dispatch, getState: () => RootState) => {
        dispatch({
            type: LOAD_APP
        });

        initializeDatasourceFetchPipeline()(dispatch, getState);

        const menuItems = await new api.MenuItemsClient().getAll();

        dispatch({
            type: SHOW_APP,
            menuItems
        });
    }
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU
    }
}

export function setMenuIsExpanded(value: boolean) {
    return {
        type: SET_MENU_IS_EXPANDED,
        value: value
    }
}

export function setBreadcrumbStack(breadcrumbStack: BreadcrumbItem[]) {
    return {
        type: SET_BREADCRUMB_STACK,
        breadcrumbStack: breadcrumbStack
    }
}