import React from "react";
import "./Sidenav.scss";
import { TreeView, TreeItem } from "@mui/lab";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import { RootState } from "../../store";
import { setBreadcrumbStack, toggleMenu } from "../../store/app/actions";
import useLocalStorage from "../hooks/useLocalStorage";
import { Icon, OutlinedInput, InputAdornment, CircularProgress, IconButton } from "./";
import { Search, TriangleDown, TriangleRight, ChevronLeft, ChevronRight } from "./icons";
import { BehaviorSubject } from "rxjs";
import { tap, debounceTime } from 'rxjs/operators';
import { ReactComponent as FooterImage } from "../../assets/sidenav-footer.svg";
import { useSettings } from "../providers/SettingsProvider";
import { default as RequiresRole } from "./RequiresRole";
import { ROLES } from "../../auth/types";
import clsx from "clsx";

export interface MenuItem {
    id?: string;
    title?: string | undefined;
    icon?: string | undefined;
    description?: string | undefined;
    url?: string | undefined;
    children?: MenuItem[] | undefined;
}

function filterMenuItems(menuItem: MenuItem, searchTerm: string) {
    // Search term is blank or this node matches the search criteria...
    if (!searchTerm || menuItem.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return menuItem;
    }

    const survivors = _.chain(menuItem.children).map(a => filterMenuItems(a, searchTerm)).filter(a => !!a).value();

    if (survivors?.length > 0) {
        return {
            ...menuItem,
            children: survivors
        };
    } else {
        return null;
    }
}

function getFullHierarchy(item: MenuItem): MenuItem[] {
    const allChildren = _.flatMap(item.children, getFullHierarchy)
    return [item, ...allChildren];
}

function highlightSearchTerm(text: string, searchTerm: string) {
    const startIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (startIndex === -1) return <span>{text}</span>;

    const endIndex = startIndex + searchTerm.length;
    return <span>
        {text.slice(0, startIndex)}
        <mark>{text.slice(startIndex, endIndex)}</mark>
        {text.slice(endIndex, text.length)}
    </span>;
}

function getPathToItem(searchTarget: MenuItem, currentItem: MenuItem, currentPath: MenuItem[] = []): MenuItem[] {
    if (currentItem === searchTarget) {
        return currentPath;
    }
    if (!currentItem.children) {
        return null;
    }

    return _.chain(currentItem.children).map(c => getPathToItem(searchTarget, c, [...currentPath, currentItem])).find(a => !!a).value();
}

// It gets complicated when you try to trim the branches midway, so instead we just cap the number of leaf nodes in the tree when rendering the search results.
function takeNLeafNodesFromTree(tree: MenuItem[], n: number) {
    let remainder = n;

    const getPrunedMenuItem = (item: MenuItem) => {
        if (!item.children?.length) {
            if (remainder > 0) {
                remainder--;
                return item;
            }
            else {
                return null;
            }
        }
        else {
            let trimmedChildren = item.children?.map(getPrunedMenuItem).filter(a => !!a);
            if (!trimmedChildren.length) {
                return null;
            }
            else {
                return { ...item, children: trimmedChildren };
            }
        }
    }

    return tree.map(getPrunedMenuItem).filter(a => !!a);
}

const searchTermSubject$ = new BehaviorSubject<string>("");

const CollapseExpandSideNavButton = (props: { toggleDrawer: (() => void), open: boolean }) => {
    const { toggleDrawer, open } = props;

    return (
        <IconButton color="primary" onClick={toggleDrawer}>
            {
                open ?
                    <ChevronLeft size="medium" color="primary" /> :
                    <ChevronRight size="medium" color="primary" />
            }
        </IconButton >
    );
}

interface SidenavProps {
    toggleMenu: typeof toggleMenu;
    isLoading: boolean;
    menuItems: MenuItem[];
    open: boolean;
    setBreadcrumbStack: typeof setBreadcrumbStack;
    activeItemContent?: JSX.Element;
    className?: string;
}

function Sidenav(props: SidenavProps) {
    const { toggleMenu, isLoading, menuItems, open, setBreadcrumbStack, activeItemContent, className } = props;

    const [expandedNodes, setExpandedNodes] = useLocalStorage("side-nav-expanded-nodes", []);
    const searchField = React.useRef(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermText, setSearchTermText] = React.useState("");
    const [selectedNavItem, setSelectedNavItem] = React.useState<MenuItem>(null);
    const [filteredMenuItems, setFilteredMenuItems] = React.useState([]);
    const [expandedSearchNodes, setExpandedSearchNodes] = React.useState([]);
    const [highlightedNodes, setHighlightedNodes] = React.useState([]);
    const location = useLocation();
    const settings = useSettings();
    const [width, setWidth] = React.useState(Number(localStorage.getItem("sidenav-width") ?? "400"));

    React.useEffect(() => {
        const allItems = _.flatMapDeep(menuItems, getFullHierarchy);
        const currentLocation = location.pathname;
        const activeItem = _.find(allItems, mi => mi?.url === currentLocation || mi?.url?.startsWith(currentLocation + "?")) as MenuItem;
        if (activeItem && activeItem !== selectedNavItem) {
            setSelectedNavItem(activeItem);
            setExpandedNodes(en => _.uniq([...en, activeItem.id]));
            const parentsToHighlight = _.chain(menuItems).map(a => getPathToItem(activeItem, a)).find(a => !!a).value();
            const breadcrumbStack = [...parentsToHighlight, activeItem]
            setHighlightedNodes(breadcrumbStack);
            setBreadcrumbStack(breadcrumbStack);
        }
    }, [menuItems, location]);

    const onNodeToggle = (event: any, nodeIds: string[]) => {
        if (!searchTerm) {
            setExpandedNodes(nodeIds);
        }
    }

    const renderMenuItemSubTree = (menuItem: MenuItem, depth = 1) => {
        if (menuItem.children?.length) {
            return (
                <TreeItem key={menuItem.id} nodeId={menuItem.id} className={`menu-group-level-${depth} parent ${highlightedNodes?.find(n => n?.id === menuItem?.id) ? "active" : ""}`} label={
                    <div className="menu-item-label">
                        {menuItem.icon && <Icon>{menuItem.icon}</Icon>}
                        {highlightSearchTerm(menuItem.title, searchTerm)}
                    </div>
                }>
                    {menuItem.children && menuItem.children.map(item => renderMenuItemSubTree(item, depth + 1))}
                </TreeItem>
            );
        }
        else {
            return (
                <TreeItem key={menuItem.id} nodeId={menuItem.id} className={`menu-group-level-${depth} ${highlightedNodes?.find(n => n?.id === menuItem?.id) ? "active leaf" : "leaf"}`} label={
                    <Link to={menuItem.url || "/not-found"}>
                        <div className="menu-item-label">
                            {menuItem.icon && <Icon>{menuItem.icon}</Icon>}
                            {highlightSearchTerm(menuItem.title, searchTerm)}
                        </div>
                    </Link>
                }>
                    {menuItem.id === selectedNavItem?.id && activeItemContent}
                </TreeItem>
            );
        }
    }

    React.useEffect(() => {
        const searchTermSubscription = searchTermSubject$
            .pipe(
                tap(setSearchTermText), // Immediately update the text in the input control...
                debounceTime(500)       // And debounce the update of the search term...
            ).subscribe(setSearchTerm);

        return () => searchTermSubscription.unsubscribe();
    }, []);

    React.useEffect(() => {
        if (searchTerm) {
            let filteredResults = _.chain(menuItems).map(a => filterMenuItems(a, searchTerm)).filter(a => !!a).value();
            filteredResults = takeNLeafNodesFromTree(filteredResults, 200); // Cap the search results at 200 leaf nodes, for performance reasons...
            setFilteredMenuItems(filteredResults.map(item => renderMenuItemSubTree(item)));
            setExpandedSearchNodes(_.chain(filteredResults).flatMapDeep(getFullHierarchy).filter(mi => mi?.children?.length > 0).map(mi => mi.id).value());
        }
        else {
            setFilteredMenuItems(menuItems.map(item => renderMenuItemSubTree(item)));
            setExpandedSearchNodes([]);
        }
    }, [menuItems, selectedNavItem, highlightedNodes, searchTerm]);

    const handleResizerMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        const startX = e.pageX;
        const startWidth = width;

        const mouseMoveHandler = (e: MouseEvent) => {
            const newWidth = startWidth + (e.pageX - startX);
            setWidth(newWidth);
            localStorage.setItem("sidenav-width", newWidth.toString());
        };

        const mouseUpHandler = () => {
            window.removeEventListener("mousemove", mouseMoveHandler);
            window.removeEventListener("mouseup", mouseUpHandler);
        };

        window.addEventListener("mousemove", mouseMoveHandler);
        window.addEventListener("mouseup", mouseUpHandler);
    };

    return (
        <div
            className={clsx("sidenav", open ? "open" : "closed", className)}
            style={{ width: width }}
        >
            <div className="collapse-expand-button">
                <CollapseExpandSideNavButton toggleDrawer={toggleMenu} open={open} />
            </div>
            <div className="drawer">
                <div className="drawer-header">
                    <div className="top-row">
                        <div className="logo-and-tenant-name">
                            <div className="logo-container">
                                <Link to="/">
                                    {
                                        settings.logoUrl ?
                                            <img id="logo" src={settings.logoUrl} alt="Company Logo" className="logo tenant-logo" /> :
                                            <img id="logo" src="/logo.png" alt="The Qerent Logo" className="logo" />
                                    }
                                </Link>
                            </div>
                            <div className="tenant-name">
                                {settings.tenantName}
                            </div>
                        </div>
                    </div>
                    <div className="nav-tree">
                        <RequiresRole roleName={ROLES.ADMINISTRATOR}>
                            <Link to={"/admin"}>
                                <div className="menu-item-label">
                                    <Icon>settings</Icon>
                                    Administration
                                </div>
                            </Link>
                        </RequiresRole>
                    </div>
                    <div className="search-row">
                        <OutlinedInput
                            size="small"
                            placeholder="Search"
                            className="search-menu-items-input"
                            inputRef={searchField}
                            fullWidth
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search size="small" />
                                </InputAdornment>
                            }
                            value={searchTermText}
                            onChange={(event) => searchTermSubject$.next(event.target.value)}
                        />
                    </div>
                    <hr />

                </div>
                <div className="menu-items">
                    {
                        isLoading ?
                            <div><CircularProgress className="loading-spinner-inline" /></div> :
                            filteredMenuItems?.length > 0 ?
                                <TreeView
                                    className="nav-tree"
                                    // By updating the key whenever the search term changes, the TreeView doesn't need to worry about collapsing/expanding nodes (with the subsequent layout thrashing)...
                                    key={`menu-tree-${searchTerm}`}
                                    onNodeToggle={onNodeToggle}
                                    expanded={!searchTerm ? expandedNodes : expandedSearchNodes}
                                    disableSelection={true}
                                    defaultCollapseIcon={!searchTerm ? <TriangleDown size="small" /> : null}
                                    defaultExpandIcon={!searchTerm ? <TriangleRight size="small" /> : null}
                                >
                                    {filteredMenuItems}
                                </TreeView> :
                                <div className="no-items">No items found.</div>
                    }
                </div>
                <div className="drawer-footer">
                    <FooterImage />

                </div>
            </div>

            <div
                className="resize-handle"
                onMouseDown={handleResizerMouseDown}
            />
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        isLoading: state.app.loading,
        open: state.app.menuIsExpanded
    }),
    { setBreadcrumbStack, toggleMenu })(Sidenav);