import * as _ from "lodash";
import moment from "moment";
import React from 'react';
import JsxParser from "@recursive-robot/react-jsx-parser";
import { connect } from "react-redux";
import Style from 'style-it';
import * as BaseComponents from "../../shared/components";
import browserHistory from "../../shared/history";
import { getColumnDateTimeFormatter, getColumnNumberFormatter, formatDate, formatNumber, getHashCode } from "../../shared/utilities";
import { RootState } from "../../store";
import { showDetailedError, showError, showSuccess, showWarning, showInfo } from "../../store/notifications/actions";
import { applyParameterValueChanges, refreshDatasourceByName, updateParameterValue, goToID, goToXYZ, setDatasourceData, patchDatasourceData } from "../../store/storyline/actions";
import { Template } from "../../store/storyline/types";
import * as StorylineComponents from "../components";
import ScavengerFeedbackColumn from "../components/_bespoke/imperial/ScavengerFeedbackColumn";
import * as TableCellRenderers from "../components/Table/CellRenderers";
import "./Canvas.scss";
import CanvasContentErrorBoundary from "./CanvasContentErrorBoundary";
import { CanvasBindingsContext } from "../../shared/providers/CanvasBindingsProvider";
import { StaticPlotContext } from "../../shared/providers/StaticPlotProvider";
import { useSettings } from "../../shared/providers/SettingsProvider";
import * as FileSaver from "file-saver";
import clsx from "clsx";
import * as xlsx from "xlsx";
import { NavFiltersParameterName } from "../components/NavFilters";
import { useAuth0 } from "../../auth/AuthContext";
import { ROLES } from "../../auth/types";


export const components = { CanvasContentErrorBoundary, ...BaseComponents, ...StorylineComponents } as Record<string, any>;
export const blacklistedAttrs = [];
export const blacklistedTags = [];
const renderError = ({ error }) => <h2 className="home-content error-message">{`An error occurred while parsing template: ${error}`}</h2>;

// Backwards-compatibility shim for underscore-based array indexing of frame data...
function getUnderscoreBasedIndexingProxy(frameData: any) {
    return new Proxy(frameData, {
        get: function (target, field) {
            if (typeof field === "string") {
                // If exact path matches, return the value as-is...
                const value = target?.[field] ?? window?.[field];
                if (value !== undefined) {
                    return value;
                }
                // Else, try to parse an underscore-based index from the path...
                else if (field.indexOf("_") >= 0) {
                    const path = field.substr(0, field.lastIndexOf("_"));
                    const index = field.substr(field.lastIndexOf("_") + 1);

                    return target?.[path]?.[index];
                }
            }

            return target?.[field] ?? window?.[field];
        }
    });
}

interface Props {
    template: Template;
    frameData: any;
    parameterValues: Map<string, any>;
    datasourcesInFlight: Set<string>;
    canvasState: object;
    staticPlot: boolean;
    [key: string]: any;
}

function _Canvas(props: Props) {
    const { template, frameData, canvasState, navigateTo, updateParameterValueAction, applyParameterValueChangesAction, parameterValues, datasourcesInFlight, staticPlot } = props;

    const settings = useSettings();
    const { roles, userMetadata } = useAuth0();
    const isDeveloper = roles?.includes?.(ROLES.DEVELOPER);

    const onError = React.useCallback((e) => {
        console.group("An error occurred during canvas rendering.");
        console.log(e);
        console.groupEnd();
    }, []);

    // TODO: JSX parser now supports lambdas inside bindings, so these higher-order wrappers can be removed at some point...
    // JSX Parser doesn't support lambdas inside bindings, so we need a higher-order function to close over the slide id and expose a suitable parameterless function that can be bound to...
    const navigateToHandler = React.useCallback((slideId) => {
        return () => navigateTo(slideId);
    }, [navigateTo]);

    const updateParameterValueHandler = React.useCallback((name: string) => {
        return (value) => updateParameterValueAction(name, value);
    }, [updateParameterValueAction]);

    const updateParameterValue = React.useCallback((name: string, value: any) => {
        return () => updateParameterValueAction(name, value);
    }, [updateParameterValueAction]);

    const chain = React.useCallback((actions: Function[]) => {
        return () => _.forEach(actions, f => f());
    }, []);

    const applyParameterValueChanges = React.useCallback(applyParameterValueChangesAction, [applyParameterValueChangesAction]);

    const populatedParameterValues = Object.fromEntries(Array.from(parameterValues.entries()).filter(([_key, value]) => value !== null && value !== undefined));

    const navigateToPage = (targetUrl: string, additionalParameters: Object = {}, openNewTab: boolean = false, passNavFiltersValues: boolean = true) => {

        if (passNavFiltersValues) {
            const navFilterParameterNames = parameterValues?.get(NavFiltersParameterName) ?? [];
            const navFilterParameterValues = Object.fromEntries(Object.entries(populatedParameterValues ?? {}).filter(([key, _value]) => navFilterParameterNames.some(x => x === key))) ?? {};

            if (!_.isEmpty(navFilterParameterValues)) {
                const storedValue = JSON.stringify(navFilterParameterValues);
                const navFiltersId = getHashCode(storedValue);
                sessionStorage.setItem(`nav-filters-${navFiltersId}`, storedValue);
                additionalParameters["nav-filters-id"] = navFiltersId;
            }
        }

        const url = new URL(targetUrl, targetUrl.startsWith("/") ? document.location.origin : undefined);

        Object.entries(additionalParameters).forEach(([key, value]) => {
            if (value === null || value === undefined) return;

            if (_.isDate(value) || moment.isMoment(value)) {
                url.searchParams.append(key, value.toISOString());
            } else if (_.isObject(value)) {
                url.searchParams.append(key, JSON.stringify(value));
            } else {
                url.searchParams.append(key, value?.toString ? value.toString() : JSON.stringify(value));
            }
        });

        if (openNewTab) {
            window.open(url, "_blank");
        } else {
            browserHistory.push({
                pathname: url.pathname,
                search: url.search
            });
        }
    }

    const categorizedBindings = {
        canvasState,
        frameData,
        parameterValues: populatedParameterValues,
        functions: {
            navigateToHandler,
            updateParameterValueHandler,
            applyParameterValueChanges,
            getColumnNumberFormatter,
            getColumnDateTimeFormatter,
            updateParameterValue,
            chain,
            clsx,
            formatNumber,
            formatDate,
            updateParameterValueAction: props.updateParameterValueAction,
            applyParameterValueChangesAction: props.applyParameterValueChanges,
            refreshDatasourceByName: props.refreshDatasourceByName,
            modifyDatasourceData: props.modifyDatasourceData,
            showError: props.showError,
            showSuccess: props.showSuccess,
            showDetailedError: props.showDetailedError,
            showWarning: props.showWarning,
            showInfo: props.showInfo,
            goToID: props.goToID,
            goToXYZ: props.goToXYZ,
            navigateToPage
        },
        libraries: {
            moment,
            FileSaver,
            Object,
            xlsx,
            _,
            browserHistory
        },
        metadata: {
            userMetadata,
            settings,
            datasourcesInFlight
        }
    };
    window["canvasBindings"] = categorizedBindings;

    const _bindings = {
        ...categorizedBindings.canvasState,
        ...props,
        ...categorizedBindings.frameData,
        ...categorizedBindings.parameterValues,
        ...categorizedBindings.functions,
        ...categorizedBindings.libraries,
        ...categorizedBindings.metadata,
        ScavengerFeedbackColumn,
        ...TableCellRenderers
    }
    _bindings.__proto__ = getUnderscoreBasedIndexingProxy(frameData);

    const defineFunction = React.useCallback((...args) => {
        try {
            return new Function(...args).bind(_bindings); // eslint-disable-line no-new-func
        }
        catch (e) {
            props.showDetailedError("defineFunction: Function body parsing failed.", e.toString());
        }
    }, [_bindings]);

    // eslint-disable-next-line no-eval
    const _eval = React.useCallback((expr) => eval(expr), []);

    if (!frameData) return <BaseComponents.CircularProgress className="loading-spinner" />;

    const bindings = { ..._bindings, defineFunction, eval: _eval };
    bindings.__proto__ = getUnderscoreBasedIndexingProxy(frameData);

    const result = <div className="jsx-parser">
        <StaticPlotContext.Provider value={staticPlot}>
            <CanvasBindingsContext.Provider value={bindings}>
                <JsxParser
                    bindings={bindings}
                    components={components}
                    jsx={`<CanvasContentErrorBoundary>${template?.contents}</CanvasContentErrorBoundary>`}
                    showWarnings={true}
                    disableKeyGeneration={true}
                    blacklistedAttrs={blacklistedAttrs}
                    blacklistedTags={blacklistedTags}
                    renderError={renderError}
                    onError={isDeveloper ? onError : undefined} // Only log errors for developer users, to avoid polluting the console for end users
                    renderInWrapper={false}
                    key={template?.contents} // Force re-parse when template content is updated
                />
            </CanvasBindingsContext.Provider>
        </StaticPlotContext.Provider>
    </div>;

    return template?.customCss ?
        Style.it(template.customCss, result) :
        result;
}

const Canvas = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        canvasState: state.storyline.canvasState,
        datasourcesInFlight: state.storyline.datasourcesInFlight
    }),
    {
        updateParameterValueAction: updateParameterValue,
        applyParameterValueChangesAction: applyParameterValueChanges,
        refreshDatasourceByName,
        showError,
        showSuccess,
        showDetailedError,
        showWarning,
        showInfo,
        goToID,
        goToXYZ,
        setDatasourceData,
        patchDatasourceData
    })(_Canvas);

export default React.memo(Canvas);