import { Fab } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { Popper } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { Tooltip } from "../Tooltip";
import "./ActionButtonsOverlay.scss";

type ActionButtonFields = {
    data?: unknown,
    icon: React.Component,
    tooltip?: React.Component,
    onClick?: (data: unknown) => void,
    position?: "top-left" | "top-right" | "bottom-left" | "bottom-right"
}

type ActionButtonsOverlayProps = {
    items: ActionButtonFields[];
    className?: string;
    children: React.ReactNode;
};

function ActionButtonsOverlay(props: ActionButtonsOverlayProps) {
    const { items, className, children } = props;

    const groupedItems = _.groupBy(items, item => item?.position ?? "bottom-right");

    return <div className={clsx("wrapper-container-fill", "action-buttons-overlay-container", className)}>
        {
            Object.entries(groupedItems).map(([position, items]) =>
                <div className={clsx("action-buttons", position)} key={position}>
                    {
                        items.map(item =>
                            item.onClick ?
                                <LinkButton {...item} /> :
                                <PinnableButton {...item} />
                        )
                    }
                </div>
            )
        }

        {children}
    </div>
}

type ActionButtonProps = ActionButtonFields & {

};

function PinnableButton(props: ActionButtonProps) {
    const { tooltip, icon } = props;

    const buttonRef = React.useRef(null);
    const activeHover = React.useRef(false);
    const [pinned, setPinned] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // Ensure that the popper renders inside the canvas div, in order to use the user-defined CSS...
    const getPopperContainer = React.useCallback(() => document.querySelector(".main-canvas .jsx-parser"), []);

    const handleMouseEnter = () => {
        activeHover.current = true;

        setTimeout(() => {
            if (activeHover.current) {
                setAnchorEl(buttonRef.current);
            }
        }, 200);
    };

    const handleMouseLeave = () => {
        activeHover.current = false;

        setTimeout(() => {
            if (!pinned && !activeHover.current) {
                setAnchorEl(null);
            }
        }, 200);
    };

    const clickHandler = () => {
        setPinned(p => !p);
    };

    return (
        <>
            <Popper
                key="popper"
                open={!!anchorEl}
                container={getPopperContainer}
                anchorEl={anchorEl}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                arrow
            >
                {tooltip}
            </Popper>

            <Fab
                key="fab"
                size="small"
                ref={buttonRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={clsx(pinned && "pinned", anchorEl && "open")}
                onClick={clickHandler}
            >
                {icon}
            </Fab>
        </>
    );
}

function LinkButton(props: ActionButtonProps) {
    const { tooltip, icon, onClick, data } = props;

    return (
        <Tooltip title={tooltip} arrow>
            <Fab
                key="fab"
                size="small"
                onClick={_e => onClick(data)}
            >
                {icon}
            </Fab>
        </Tooltip>
    );
}

(ActionButtonsOverlay as DocumentedComponent).metadata = {
    description: "The `ActionButtonsOverlay` component serves as a container for content and its associated actions/data tooltips.  The floating action buttons are visible only when the user hovers over the container.  The tooltip for a specific button can be pinned by clicking on it.",
    attributes: [
        {
            name: "items", type: "object", description: 
            `The list of floating action buttons to be displayed.

### \`ActionButton\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`icon\` | \`React.Component\` | The icon to render inside the button. |
| \`tooltip\` | \`React.Component\` | The tooltip to be displayed when the user hovers over the button. |
| \`position\` | \`"top-left" \\| "top-right" \\| "bottom-left" \\| "bottom-right"\` | The position of the button.  Multiple buttons with the same position value are stacked horizontally.  Optional - defaults to \`"bottom-right"\`. |
| \`onClick\` | \`(data: any) => void \` | The (optional) handler to be called when the user clicks on the button.  If provided, this overrides the default behaviour of pinning/unpinning the tooltip contents. |
| \`data\` | \`any\` | The data to be passed to the \`onClick\` handler.  Optional - defaults to \`null\`. |` }
    ]
};

export { ActionButtonsOverlay };