import * as api from "../../shared/api-client";
import * as _ from "lodash";
import {
    LOAD_STORYLINE,
    SHOW_STORYLINE,
    SHOW_CANVAS_AS_STORYLINE,
    GO_TO_X_Y_Z,
    GO_TO_ID,
    PARAMETER_VALUE_UPDATED,
    DATASOURCE_UPDATED,
    UPDATE_CURRENT_SLIDE_TEMPLATE,
    UPDATE_CURRENT_FRAME_DATA,
    UPDATE_CANVAS_STATE,
    StorylineState,
    NavigationTarget,
    ADD_GLOBAL_DATASOURCE,
    UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
    UpdateInFlightRequestsForDatasource
} from "./types";
import {
    TraceSource
} from "../tracing/types";
import { RootState } from "../index";
import { showError, showDetailedError } from "../notifications/actions";
import { addTraceEvent } from "../tracing/actions";
import { animateSlideNavigation } from "../../shared/services/slideTransitionAnimationService";
import browserHistory from "../../shared/history";
import { BehaviorSubject, Observable, merge } from "rxjs";
import { map, filter, debounceTime, groupBy, mergeAll, scan, pairwise } from 'rxjs/operators';
import { shouldRecordTraces } from "../../shared/utilities";
import { sendCanvasDataToVsCode, sendCssClassesToVsCode } from "../vscode/actions";

interface ApiRequestEvent {
    datasource: api.DatasourceDisplayModel;
}

interface ApiResponseEvent {
    datasource: api.DatasourceDisplayModel;
    payload: unknown;
}

interface DatasourceStatusSummary {
    datasource: api.DatasourceDisplayModel;
    inFlightRequests: number;
    lastResponse: unknown;
}

interface DatasourceStatusDictionary {
    [key: string]: DatasourceStatusSummary;
}

let datasourcesPendingRefresh: api.DatasourceDisplayModel[] = [];
const datasourceRefreshRequests$ = new BehaviorSubject<string>(null);
const apiRequests$ = new BehaviorSubject<ApiRequestEvent>(null);
const apiResponses$ = new BehaviorSubject<ApiResponseEvent>(null);
let datasourceStatuses$: Observable<DatasourceStatusDictionary>;

const datasourceCascadeMap = new Map<string, Array<string>>();

export function initializeDatasourceFetchPipeline() {
    return async (dispatch, getState: () => RootState) => {
        // Debounce datasource refresh requests to eliminate unnecessary API calls...
        datasourceRefreshRequests$
            .pipe(
                filter(datasourceId => datasourceId !== undefined && datasourceId !== null),
                groupBy(datasourceId => datasourceId),
                map((group: any) => group.pipe(
                    debounceTime(100)
                )),
                mergeAll()
            ).subscribe((datasourceId: string) => {
                const { datasources } = getState().storyline;
                const datasource = datasources.get(datasourceId);

                refreshDatasource(datasource, true)(dispatch, getState);
            });

        datasourceStatuses$ =
            merge(
                apiRequests$.pipe(filter(r => !!r), map(r => ({ "type": "REQUEST", ...r }))),
                apiResponses$.pipe(filter(r => !!r), map(r => ({ "type": "RESPONSE", ...r })))
            )
                .pipe(
                    scan((acc, elem) => {
                        switch (elem.type) {
                            case "REQUEST":
                                return (() => {
                                    const request = elem as ApiRequestEvent;
                                    const currentStatus = acc[request.datasource.id] || { datasource: request.datasource, inFlightRequests: 0, lastResponse: null };

                                    dispatch({
                                        type: UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
                                        datasource: request.datasource,
                                        inFlightRequestCount: currentStatus.inFlightRequests + 1
                                    } as UpdateInFlightRequestsForDatasource);

                                    return {
                                        ...acc,
                                        [request.datasource.id]: {
                                            datasource: request.datasource,
                                            inFlightRequests: currentStatus.inFlightRequests + 1
                                        }
                                    } as DatasourceStatusDictionary;
                                })();

                            case "RESPONSE":
                                return (() => {
                                    const response = elem as ApiResponseEvent;
                                    const currentStatus = acc[response.datasource.id];

                                    dispatch({
                                        type: UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
                                        datasource: response.datasource,
                                        inFlightRequestCount: currentStatus.inFlightRequests - 1
                                    } as UpdateInFlightRequestsForDatasource);

                                    return {
                                        ...acc,
                                        [response.datasource.id]: {
                                            datasource: response.datasource,
                                            inFlightRequests: currentStatus.inFlightRequests - 1,
                                            lastResponse: response.payload
                                        }
                                    } as DatasourceStatusDictionary;
                                })();
                        }
                    }, {} as DatasourceStatusDictionary)
                );

        // Monitor data source event stream for final outstanding requests (per datasource) completing and
        // dispatch the DATASOURCE_UPDATED action if necessary...
        datasourceStatuses$
            .pipe(pairwise())
            .subscribe(([prevState, currentState]) => {
                for (let datasourceId of Object.keys(currentState)) {
                    const s0 = prevState[datasourceId];
                    const s1 = currentState[datasourceId];

                    // Only update the datasource value if the last in-flight request has just completed...
                    if (!s0 || s0.inFlightRequests !== 1 || s1.inFlightRequests !== 0) continue;

                    const hasCascadingInFlightCall = (datasourceId: string) => {
                        const cascadeSources = datasourceCascadeMap.get(datasourceId) || [];

                        if (cascadeSources && cascadeSources.length) {
                            return cascadeSources.find(hasCascadingInFlightCall);
                        }

                        return currentState[datasourceId].inFlightRequests > 0;
                    }

                    // If any of the cascading parent datasources have outstanding responses, don't bother updating the frame data with this (soon to be overwritten) result...
                    if (hasCascadingInFlightCall(datasourceId) && !_.isEmpty(getState().storyline.datasourceValues.get(datasourceId)?.[0])) continue;

                    shouldRecordTraces() && dispatch(addTraceEvent({
                        kind: "DatasourceRefreshed",
                        name: s0.datasource.name,
                        oldValue: getState().storyline.datasourceValues.get(datasourceId),
                        newValue: s1.lastResponse,
                        source: { kind: "ParameterValueChanged" } as TraceSource
                    }));

                    dispatch({
                        type: DATASOURCE_UPDATED,
                        datasourceId: datasourceId,
                        data: s1.lastResponse
                    });
                }
            });
    }
}

function fetchDatasourceData(datasource: api.DatasourceDisplayModel, parameterValues: Map<string, any>, datasources: api.DatasourceDisplayModel[]) {
    return async (dispatch, getState: () => RootState) => {
        // Only call the API if all the required parameters are populated...
        if (_.chain(datasource.parameters).filter(p => !p.isOptional).map(p => p.name).every(p => parameterValues.get(p) !== undefined && parameterValues.get(p) !== null).value()) {

            // Get the parameter values and construct the parameter object for the request body...
            let parameterObject = _.chain(datasource.parameters)
                .map(p => p.name)
                .map(p => ({ key: p, value: parameterValues.get(p) }))
                .filter(p => p.value !== null && p.value !== undefined)
                .reduce((acc, elem) => { acc[elem.key] = elem.value; return acc; }, {})
                .value();

            try {
                apiRequests$.next({ datasource });
                const result = await new api.CanvasDataClient().getData(datasource.id, datasource.useDatasourceResults, parameterObject);
                apiResponses$.next({ datasource, payload: result });

                if (!_.isArray(result)) {
                    showError("Data source does not contain an array of frames!")(dispatch);
                }

                // Do not update parameter values during the initial load.  Data sources loaded via this mechanism are discarded at that point...
                if (!getState()?.storyline?.loading) {
                    // If result contains values that should be mapped to parameters, set them accordingly...
                    const datasourceFields = _.chain(result).flatMap(frame => _.keys(frame)).uniq().value();
                    const allParameters = _.chain(datasources).flatMap(ds => ds.parameters).map(p => p.name).uniq().value();
                    const parametersToBePopulated = _.intersection(datasourceFields, allParameters);

                    for (const fieldName of parametersToBePopulated) {
                        const fieldValue = _.find(result, frame => frame?.[fieldName] !== undefined && frame?.[fieldName] !== null)?.[fieldName];
                        if (fieldValue !== null && fieldValue !== undefined) {
                            await updateParameterValue(fieldName, fieldValue, false, false, datasource.id)(dispatch, getState);
                        }
                    }
                }

                return result;
            }
            catch (ex) {
                apiResponses$.next({ datasource, payload: null });
            }
        }

        return [{}];
    }
}

export function loadStoryline(id: string, parameterValues: Map<string, any>) {
    return async (dispatch, getState: () => RootState) => {
        dispatch({
            type: LOAD_STORYLINE,
            id
        });

        datasourceCascadeMap.clear();

        const storyline = await new api.StorylinesClient().getByIdOrFriendlyUrl(id);
        if (!storyline) {
            const currentUrl = document.location.href;
            browserHistory.push(`/not-found?original-path=${currentUrl}`);
            return;
        }

        let datasources = _.chain(storyline.canvases)
            .flatMap(canvas => [...canvas.canvas.datasources, ..._.flatMap(canvas.children, child => child.canvas.datasources)])
            .uniqBy(ds => ds.id)
            .value();

        let datasourceValues = await Promise.all(datasources.map(async datasource => [datasource.id, await fetchDatasourceData(datasource, parameterValues, datasources)(dispatch, getState)] as any));

        // User has already navigated to a different storyline - discard the results and move on...
        if (getState().storyline.id !== id) {
            return;
        }

        dispatch({
            type: SHOW_STORYLINE,
            id,
            storyline,
            canvasData: new Map(datasourceValues),
            parameterValues
        });

        // Set the unbound parameter values that are found in the initial data sources, refreshing affected data sources as we go along...
        const allParameters = _.chain(datasources).flatMap(ds => ds.parameters).map(p => p.name).uniq().value();
        const unboundParameters = _.difference(allParameters, ...parameterValues.keys());
        for (const datasourceValue of datasourceValues.map(x => x[1])) {
            const firstFrame = datasourceValue?.[0];
            if (!firstFrame) continue;

            const parametersToPopulate = _.intersection(unboundParameters, _.keys(firstFrame));
            for (const key of parametersToPopulate) {
                updateParameterValue(key, firstFrame[key], true)(dispatch, getState);
            }
        }

        setTimeout(() => {
            dispatch(sendCanvasDataToVsCode());
            sendCssClassesToVsCode();
        }, 2000);
    }
}

export function loadCanvas(id: string, parameterValues: Map<string, any>) {
    return async (dispatch, getState: () => RootState) => {
        dispatch({
            type: LOAD_STORYLINE,
            id
        });

        datasourceCascadeMap.clear();

        const canvas = await new api.CanvasesClient().getByIdOrFriendlyUrl(id);
        if (!canvas) {
            const currentUrl = document.location.href;
            browserHistory.push(`/not-found?original-path=${currentUrl}`);
            return;
        }

        let datasources = canvas.datasources;
        let datasourceValues = await Promise.all(datasources.map(async datasource => [datasource.id, await fetchDatasourceData(datasource, parameterValues, datasources)(dispatch, getState)] as any));

        // User has already navigated to a different storyline - discard the results and move on...
        if (getState().storyline.id !== id) {
            return;
        }

        dispatch({
            type: SHOW_CANVAS_AS_STORYLINE,
            id,
            canvas,
            canvasData: new Map(datasourceValues),
            parameterValues
        });

        // Set the unbound parameter values that are found in the initial data sources, refreshing affected data sources as we go along...
        const allParameters = _.chain(datasources).flatMap(ds => ds.parameters).map(p => p.name).uniq().value();
        const unboundParameters = _.difference(allParameters, ...parameterValues.keys());
        for (const datasourceValue of datasourceValues.map(x => x[1])) {
            const firstFrame = datasourceValue?.[0];
            if (!firstFrame) continue;

            const parametersToPopulate = _.intersection(unboundParameters, _.keys(firstFrame));
            for (const key of parametersToPopulate) {
                updateParameterValue(key, firstFrame[key], true)(dispatch, getState);
            }
        }

        setTimeout(() => {
            dispatch(sendCanvasDataToVsCode());
            sendCssClassesToVsCode();
        }, 2000);
    }
}

export function updateParameterValue(parameterName: string, newValue: any, isInitialLoad = false, preventDatasourceRefresh = false, sourceDatasourceId: string = null) {
    return async (dispatch, getState: () => RootState) => {
        if (!parameterName) return;

        // Short-circuit action if the value hasn't actually changed...
        const oldValue = getState()?.storyline?.parameterValues?.get(parameterName);
        if (_.isEqual(oldValue, newValue)) {
            return;
        }

        shouldRecordTraces() && dispatch(addTraceEvent({
            kind: "ParameterValueChanged",
            name: parameterName,
            oldValue,
            newValue,
            source: { kind: isInitialLoad ? "InitialLoad" : sourceDatasourceId ? "DatasourceBinding" : "InputControlChange" } as TraceSource
        }));

        dispatch({
            type: PARAMETER_VALUE_UPDATED,
            parameterName,
            newValue
        });

        // Find the data sources that are affected by the parameter change...
        let affectedDatasources = _.filter(Array.from(getState().storyline.datasources.values()), (ds: api.DatasourceDisplayModel) => !!_.find(ds.parameters, p => p.name === parameterName));

        // Record the datasource that triggered this cascading update...
        if (sourceDatasourceId) {
            affectedDatasources.forEach(ds => {
                datasourceCascadeMap.set(ds.id, _.uniq(_.filter([...datasourceCascadeMap.get(ds.id) || [], sourceDatasourceId], a => !!a)));
            });
        }

        // Keep track of the data sources that now have outstanding updates pending, so that we can refresh them on the next manual update run...
        let dataSourcesToManuallyRefresh = _.filter(affectedDatasources, ds => !ds.autoRefreshOnParameterChange);
        datasourcesPendingRefresh = _.unionBy(datasourcesPendingRefresh, dataSourcesToManuallyRefresh, a => a.id);

        if (!preventDatasourceRefresh) {
            // Post the list of data sources to update to the RxJS subject, so that datasource updates can be grouped and debounced...
            let datasourcesToAutoRefresh = _.filter(affectedDatasources, ds => isInitialLoad || ds.autoRefreshOnParameterChange);
            for (let datasource of datasourcesToAutoRefresh) {
                datasourceRefreshRequests$.next(datasource.id);
            }
        }

        setTimeout(() => dispatch(sendCanvasDataToVsCode()), 500);
    };
}

export function refreshDatasourceByName(name: string, mapResultToDatasourceParameters = false) {
    return async (dispatch, getState: () => RootState) => {
        const datasource = _.find(Array.from(getState().storyline.datasources).map(([_, value]) => value), a => a.name === name);
        if (datasource) {
            refreshDatasource(datasource, mapResultToDatasourceParameters)(dispatch, getState);
        }
    };
}

export function refreshDatasource(datasource: api.DatasourceDisplayModel, mapResultToDatasourceParameters = false) {
    return async (dispatch, getState: () => RootState) => {
        if (!datasource) return;

        const parameterValues = getState().storyline.parameterValues;

        // Only call the API if all the required parameters are populated...
        if (_.chain(datasource.parameters).filter(p => !p.isOptional).map(p => p.name).every(p => parameterValues.get(p) !== undefined && parameterValues.get(p) !== null).value()) {
            const datasourcesToMapParametersTo = mapResultToDatasourceParameters ? Array.from(getState().storyline.datasources).map(([_, value]) => value) : [];

            await fetchDatasourceData(datasource, getState().storyline.parameterValues, datasourcesToMapParametersTo)(dispatch, getState);
        }

        setTimeout(() => dispatch(sendCanvasDataToVsCode()), 500);
    };
}

export function applyParameterValueChanges(mapResultToDatasourceParameters = false) {
    return async (dispatch, getState: () => RootState) => {
        // Make a copy of the datasourcesPendingRefresh array and clear out the original to prevent race conditions/duplicate API calls on subsequent function invocations...
        const snapshotDatasourcesPendingRefresh = [...datasourcesPendingRefresh];
        datasourcesPendingRefresh = [];

        await Promise.all(_.map(snapshotDatasourcesPendingRefresh, async ds => await refreshDatasource(ds, mapResultToDatasourceParameters)(dispatch, getState)));
    }
}

type CoordinateCalculators = {
    [key: string]: (state: StorylineState) => NavigationTarget
}

const coordinateCalculators: CoordinateCalculators = {
    "NEXT_ITEM": (state) => {
        if (state.frameIndex < state.slides[state.xIndex][state.yIndex].frames.length - 1) {
            return {
                xIndex: state.xIndex,
                yIndex: state.yIndex,
                frameIndex: state.frameIndex + 1,
                previousFrameIndex: state.frameIndex
            };
        }
        else if (state.yIndex < state.slides[state.xIndex].length - 1) {
            return {
                xIndex: state.xIndex,
                yIndex: state.yIndex + 1,
                frameIndex: 0
            }
        }
        else if (state.xIndex < state.slides.length - 1) {
            return {
                xIndex: state.xIndex + 1,
                yIndex: 0,
                frameIndex: 0
            }
        }
    },
    "PREVIOUS_ITEM": (state) => {
        if (state.frameIndex > 0) {
            const frameIndex = state.frameIndex - 1;

            return {
                xIndex: state.xIndex,
                yIndex: state.yIndex,
                frameIndex,
                previousFrameIndex: state.frameIndex
            };
        }
        else if (state.yIndex > 0) {
            const yIndex = state.yIndex - 1;
            const frameIndex = state.slides[state.xIndex][yIndex].frames.length - 1;

            return {
                xIndex: state.xIndex,
                yIndex,
                frameIndex
            }
        }
        else if (state.xIndex > 0) {
            const xIndex = state.xIndex - 1;
            const yIndex = state.slides[xIndex].length - 1;
            const frameIndex = state.slides[xIndex][yIndex].frames.length - 1;

            return {
                xIndex,
                yIndex,
                frameIndex
            }
        }
    },
    "NEXT_SECTION": (state) => {
        if (state.yIndex < state.slides[state.xIndex].length - 1) {
            return {
                xIndex: state.xIndex,
                yIndex: state.yIndex + 1,
                frameIndex: 0
            }
        }
        else if (state.xIndex < state.slides.length - 1) {
            return {
                xIndex: state.xIndex + 1,
                yIndex: 0,
                frameIndex: 0
            }
        }
    },
    "PREVIOUS_SECTION": (state) => {
        if (state.yIndex > 0) {
            return {
                xIndex: state.xIndex,
                yIndex: state.yIndex - 1,
                frameIndex: 0
            }
        }
        else if (state.xIndex > 0) {
            const xIndex = state.xIndex - 1;
            const yIndex = state.slides[xIndex].length - 1;

            return {
                xIndex,
                yIndex,
                frameIndex: 0
            }
        }
    },
    "NEXT_CHAPTER": (state) => {
        if (state.xIndex < state.slides.length - 1) {
            return {
                xIndex: state.xIndex + 1,
                yIndex: 0,
                frameIndex: 0
            }
        }
    },
    "PREVIOUS_CHAPTER": (state) => {
        if (state.xIndex > 0) {
            return {
                xIndex: state.xIndex - 1,
                yIndex: 0,
                frameIndex: 0
            }
        }
    }
};

export function navigateTo(direction: "NEXT_ITEM" | "PREVIOUS_ITEM" | "NEXT_SECTION" | "PREVIOUS_SECTION" | "NEXT_CHAPTER" | "PREVIOUS_CHAPTER") {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState()?.storyline;
        if (!storyline) return;

        const handler = coordinateCalculators[direction];
        const newCoordinates = handler(storyline);
        if (newCoordinates) {
            goToXYZ(newCoordinates.xIndex, newCoordinates.yIndex, newCoordinates.frameIndex, newCoordinates.previousFrameIndex)(dispatch, getState);
        }
    }
}

export function goToXYZ(xIndex: number, yIndex: number, frameIndex: number, previousFrameIndex?: number) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState()?.storyline;
        if (!storyline) return;

        const animationDirection = xIndex > storyline.xIndex ? "right" :
            xIndex < storyline.xIndex ? "left" :
                yIndex > storyline.yIndex ? "down" :
                    yIndex < storyline.yIndex ? "up" :
                        null;

        animationDirection && animateSlideNavigation(animationDirection);

        dispatch({
            type: GO_TO_X_Y_Z,
            xIndex,
            yIndex,
            frameIndex,
            previousFrameIndex
        });
    }
}

export function goToID(id: string) {
    return {
        type: GO_TO_ID,
        id
    }
}

export function updateCurrentSlideTemplate(newTemplate: string, newCustomCss: string) {
    setTimeout(sendCssClassesToVsCode, 2000);

    return {
        type: UPDATE_CURRENT_SLIDE_TEMPLATE,
        newTemplate,
        newCustomCss
    }
}

export function updateCurrentFrameData(newData: any) {
    return {
        type: UPDATE_CURRENT_FRAME_DATA,
        newData
    }
}

export function updateCurrentCanvasState(stateChunk: Object) {
    return (dispatch, getState: () => RootState) => {
        const currentState = getState()?.storyline.canvasState;
        // Short-circuit if the values are unchanged...
        if (_.isMatch(currentState, stateChunk)) return;

        const newState = { ...currentState, ...stateChunk };

        dispatch({
            type: UPDATE_CANVAS_STATE,
            newState
        });
    };
}

export function saveCurrentCanvasTemplate(contents: string, customCss: string, newTemplateName?: string) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState().storyline;
        const currentSlide = storyline.slides[storyline.xIndex][storyline.yIndex];

        new api.CanvasesClient()
            .updateTemplate(currentSlide.canvasId, {
                newTemplateName: newTemplateName,
                contents: contents,
                customCss: customCss
            })
            .catch((error: api.ApiException) => {
                dispatch(showError(error.response));
            });
    }
}

export function setDatasourceData(datasourceName: string, data: Object[]) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState().storyline;
        const existingDatasource = [...storyline.datasources.values()].find(ds => ds.name === datasourceName);
        const datasourceId = existingDatasource?.id ?? datasourceName;

        if (!existingDatasource) {
            dispatch({
                type: ADD_GLOBAL_DATASOURCE,
                datasource: {
                    id: datasourceName,
                    name: datasourceName,
                    parameters: []
                } as api.DatasourceDisplayModel
            });
        }

        dispatch({
            type: DATASOURCE_UPDATED,
            datasourceId,
            data
        });
    }
}

export function patchDatasourceData(datasourceName: string, diff: Object) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState().storyline;
        const existingDatasource = [...storyline.datasources.values()].find(ds => ds.name === datasourceName);
        const datasourceId = existingDatasource?.id ?? datasourceName;

        if (!existingDatasource) {
            dispatch({
                type: ADD_GLOBAL_DATASOURCE,
                datasource: {
                    id: datasourceName,
                    name: datasourceName,
                    parameters: []
                } as api.DatasourceDisplayModel
            });
        }

        const existingDatasourceData = storyline.datasourceValues.get(datasourceId) ?? [{}];

        dispatch({
            type: DATASOURCE_UPDATED,
            datasourceId,
            data: existingDatasourceData.map(frame => ({ ...frame, ...diff }))
        });
    }
}