import "./Chart.scss";
import React from "react";
import Plot, { PlotParams } from "react-plotly.js";
import { useResizeDetector } from 'react-resize-detector';
import { connect } from "react-redux";
import { updateParameterValue } from "../../../store/storyline/actions";
import { throttle } from "lodash";
import { PlotMouseEvent, PlotRelayoutEvent } from "plotly.js";
import * as _ from "lodash";
import { RootState } from "../../../store";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";
import { Popper } from "../../../shared/components";
import clsx from "clsx";

const style = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

interface Props extends Omit<PlotParams, "data" | "layout" | "onHover"> {
    input: {
        data: Plotly.Data[];
        layout: Partial<Plotly.Layout>;
    };
    xAxisRangeParameters?: string[];
    updateParameterValue?: typeof updateParameterValue;
    parameterValues?: Map<string, any>;
    navigateTo?: (path: string) => void;
    staticPlot?: boolean;
    disableResizeListener?: boolean;
    tooltipTemplate?: string;
    getTooltipContent?: (e: PlotMouseEvent) => JSX.Element;
    tooltipIsInteractive?: boolean;
    hideTooltipArrow?: boolean;
    onHover?: (event: Readonly<Plotly.PlotMouseEvent>) => boolean;
}

function _Chart(props: Props) {
    const { width: _width, height: _height, ref } = useResizeDetector();
    const { input, xAxisRangeParameters, updateParameterValue, parameterValues, staticPlot: propStaticPlot, disableResizeListener, onClick, onHover, onUnhover, navigateTo, tooltipTemplate, getTooltipContent, tooltipIsInteractive, hideTooltipArrow = false, ...rest } = props;
    const customTooltipContent = tooltipTemplate || getTooltipContent;
    const { data: _data, layout: _layout } = input ?? {};
    const [data, setData] = React.useState(_data);
    const [revision, setRevision] = React.useState(0);
    const tooltipTimeoutRef = React.useRef(null);
    React.useEffect(() => {
        const sanitizedData = (_data ?? []).map(d => ({ ...d, hoverinfo: customTooltipContent ? "none" : d.hoverinfo }));
        if (!_.isEqual(sanitizedData, data)) {
            setData(sanitizedData);
        }
    }, [_data]);

    const [layout, setLayout] = React.useState(_layout);
    React.useEffect(() => {
        const sanitizedLayout = {
            ..._layout,
            autosize: true,
            // NB: This cannot be done in CSS, as Plotly uses this for text size calculations...
            font: {
                family: "Helvetica Neue"
            }
        };
        if (!_.isEqual(sanitizedLayout, layout)) {
            setLayout(sanitizedLayout);
        }
    }, [_layout]);

    // If the user is passing in an explicit value for the `staticPlot` prop, use that, otherwise use the canvas-level value...
    const canvasStaticPlot = useStaticPlot();
    const staticPlot = propStaticPlot !== undefined && propStaticPlot !== null ? propStaticPlot : canvasStaticPlot;

    const dataPointClicked = React.useCallback(
        (data) => {
            // Gantt item selected...
            if (xAxisRangeParameters?.length === 2 && data?.points?.[0]?.base && data?.points?.[0]?.value) {
                // Set the xAxisRangeParameter values to the area of the selected item...
                if (parameterValues.get(xAxisRangeParameters[0]) !== data?.points?.[0]?.base || parameterValues.get(xAxisRangeParameters[1]) !== data?.points?.[0]?.value) {
                    updateParameterValue(xAxisRangeParameters[0], data?.points?.[0]?.base);
                    updateParameterValue(xAxisRangeParameters[1], data?.points?.[0]?.value);
                }
            }

            // Generic navigate-on-click handler...
            if (data?.points?.[0]?.pointIndex !== null && data?.points?.[0]?.pointIndex !== undefined) {
                const navigateToValue = data.points[0]?.data?.navigateTo?.[data?.points[0]?.pointIndex];
                if (navigateToValue) {
                    navigateTo?.(navigateToValue);
                }
            }
        },
        [navigateTo, updateParameterValue, xAxisRangeParameters, parameterValues]
    );

    // This memoized, throttled function persists the current axis bounds to parameter values.  This is only really useful 
    // for a date series, where these can be used as datasource parameters in order to highlight the focused data points...
    const persistAxisBoundsToParameters = React.useMemo(
        () =>
            throttle((e: PlotRelayoutEvent) => {
                if (!xAxisRangeParameters || xAxisRangeParameters?.length !== 2) return;

                // Both forms of the autorange value...
                if ((e?.["xaxis.autorange"] || e?.xaxis?.autorange)) {
                    _.forEach(xAxisRangeParameters, p => updateParameterValue(p, undefined));
                }
                // Parameter form 1 for explicit range...
                else if (e?.["xaxis.range[0]"] && e?.["xaxis.range[1]"]) {
                    for (let i = 0; i < xAxisRangeParameters.length; i++) {
                        updateParameterValue(xAxisRangeParameters[i], e[`xaxis.range[${i}]`]);
                    }
                }
                // Parameter form 2 for explicit range...
                else if (e?.["xaxis.range"]?.length === 2) {
                    for (let i = 0; i < xAxisRangeParameters.length; i++) {
                        updateParameterValue(xAxisRangeParameters[i], e["xaxis.range"][i]);
                    }
                }
            }, 1000),
        []);

    if (xAxisRangeParameters && parameterValues.get(xAxisRangeParameters[0]) && parameterValues.get(xAxisRangeParameters[1]) && layout?.xaxis) {
        layout.xaxis.range = [parameterValues.get(xAxisRangeParameters[0]), parameterValues.get(xAxisRangeParameters[1])];
    }

    const [hoverData, setHoverData] = React.useState(null);
    const activeHover = React.useRef(false);

    const handleHover = React.useCallback((e: PlotMouseEvent) => {
        onHover && onHover(e) && setRevision(r => r + 1);

        if (!customTooltipContent) {
            return;
        }

        activeHover.current = true;
        // Cancel the last tooltip show timeout, since we've since moved to a new point...
        tooltipTimeoutRef.current && clearTimeout(tooltipTimeoutRef.current);

        tooltipTimeoutRef.current = setTimeout(() => {
            if (activeHover.current) {
                function getBoundingBox() {
                    if (!e.points) {
                        return {
                            x0: e.event.offsetX,
                            x1: e.event.offsetX,
                            y0: e.event.offsetY,
                            y1: e.event.offsetY,
                        };
                    }

                    if (e.points.length === 1) {
                        return e.points[0]["bbox"];
                    }

                    const midpoints = e.points.map(p => ({
                        x: (p["bbox"].x0 + p["bbox"].x1) / 2,
                        y: (p["bbox"].y0 + p["bbox"].y1) / 2,
                        bbox: p["bbox"]
                    }));

                    // Return bounding box of nearest point...
                    return _.chain(midpoints)
                        .sortBy(p => Math.abs(e.event.offsetX - p.x) + Math.abs(e.event.offsetY - p.y))
                        .map(p => p.bbox)
                        .first()
                        .value();
                };

                const offsets = { x: e.event.x - e.event?.["pointerX"], y: e.event.y - e.event?.["pointerY"] };
                const boundingBox = getBoundingBox();
                const getBoundingClientRect = () => ({
                    width: boundingBox.x1 - boundingBox.x0,
                    height: boundingBox.y1 - boundingBox.y0,
                    left: boundingBox.x0 + offsets.x,
                    right: boundingBox.x1 + offsets.x,
                    top: boundingBox.y0 + offsets.y,
                    bottom: boundingBox.y1 + offsets.y,
                });

                setHoverData({
                    hoverEvent: e,
                    anchorEl: {
                        getBoundingClientRect,
                        contextElement: e.event.target
                    }
                });
            }
        }, tooltipIsInteractive ? 200 : 0);

    }, [customTooltipContent, onHover]);

    const handleUnhover = React.useCallback((e) => {
        onUnhover && onUnhover(e);

        if (!customTooltipContent) {
            return;
        }

        activeHover.current = false;

        setTimeout(() => {
            if (!activeHover.current) {
                setHoverData(null);
            }
        }, tooltipIsInteractive ? 100 : 0);
    }, [customTooltipContent, onUnhover]);

    return (
        <div className="fill" style={{ position: "relative" }} ref={ref}>
            <Plot
                {...rest}
                data={data}
                layout={layout}
                config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: staticPlot, displayModeBar: false }}
                style={style}
                onClick={onClick || dataPointClicked}
                onRelayout={persistAxisBoundsToParameters}
                onHover={handleHover}
                onUnhover={handleUnhover}
                revision={revision}
            />
            {
                customTooltipContent &&
                <ChartTooltip
                    content={customTooltipContent}
                    hoverData={hoverData}
                    onMouseEnter={() => { activeHover.current = true; }}
                    onMouseLeave={handleUnhover}
                    isInteractive={tooltipIsInteractive}
                    arrow={!hideTooltipArrow}
                />
            }
        </div>
    );
}

interface HoverData {
    anchorEl?: any;
    hoverEvent: PlotMouseEvent;
    [key: string]: any;
}

interface ChartTooltipProps {
    content?: string | ((e: PlotMouseEvent) => JSX.Element);
    hoverData?: HoverData;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    isInteractive?: boolean;
    arrow?: boolean;
}


function ChartTooltip(props: ChartTooltipProps) {
    const { content, hoverData, onMouseEnter, onMouseLeave, isInteractive, arrow = true } = props;

    const TooltipContent = () => {
        if (typeof content === "string") {
            const { TemplateRenderer } = require("../../../viewer/components/TemplateRenderer");
            return <TemplateRenderer template={content} data={hoverData} />;
        }

        return content(hoverData.hoverEvent);
    };

    return (
        <Popper
            arrow={arrow}
            disablePortal
            className={clsx({ "non-interactive-element": !isInteractive })}
            open={hoverData != null}
            anchorEl={hoverData?.anchorEl}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <TooltipContent />
        </Popper>
    );
}


const Chart = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(_Chart));

(Chart as DocumentedComponent).metadata = {
    description: "The Chart component is a thin wrapper around the `Plot` component from the [`react-plotly.js`](https://plotly.com/javascript/react/) library.  All additional props are passed directly to the underlying component - please consult the documentation for that component for all the available options.",
    isSelfClosing: true,
    attributes: [
        { name: `input`, type: `object`, description: "The figure to render.  See the [Plotly figure documentation](https://plotly.com/python/figure-structure/) for the structure of this object." },
        { name: `staticPlot`, type: `boolean`, description: "This optional property indicates whether the plot should be interactive or static.  Defaults to `true` when the plot is rendered on the Minimap/Tooltip and to `false` when rendered on the main canvas. Can be overridden to always be `true` if a static plot is desired." },
        { name: `disableResizeListener`, type: `boolean`, description: "If the chart is placed within a non-resizable container, this optional flag can be used to improve performance.  Especially useful for table cell renders, where the virtual scrolling behaviour can trip up the default resize handler." },
        { name: `tooltipTemplate`, type: `string`, description: "The optional custom template to use for chart tooltips.  Can contain any valid JSX.  The underlying `plotly` hover event is exposed as a binding named `hoverEvent`.  `hoverEvent.points[0]` would most likely be used here to access the data for the point." },
        { name: `getTooltipContent`, type: `function`, template: `getTooltipContent={(hoverEvent) => $1}`, description: "The optional callback function to use for generating the tooltip content.  This is the non-serializable counterpart to `tooltipTemplate`, which provides the same functionality.  If both `tooltipTemplate` and `getTooltipContent` is provided, `tooltipTemplate` will take precedence." },
        { name: `tooltipIsInteractive`, type: `boolean`, description: "Optional - defaults to `false`.  If `true`, the custom tooltip will remain open while the user's mouse pointer is within its bounds." },
    ]
};

export default Chart;