import React from "react";
import "./Users.scss";
import { Card, Container, Button, NavBar, ConfirmationDialog, DataGrid, GridActionsCellItem } from "../../shared/components";
import { Edit, Delete } from "@mui/icons-material";
import * as api from "../../shared/api-client";
import * as _ from "lodash";
import EditUserDialog from "./EditUser";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";

function Users(props) {
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [editDialogDisplayTrigger, setEditDialogDisplayTrigger] = React.useState(0);
    const [activeItem, setActiveItem] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        new api.UsersClient().search()
            .then(result => setData(result))
            .finally(() => setIsLoading(false));
    }, [refreshCounter]);

    const onUserCreatedOrUpdated = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const createOrEditUser = (model) => {
        setActiveItem(model);
        setEditDialogDisplayTrigger(editDialogDisplayTrigger + 1);
    }

    const deleteUser = (model) => {
        setActiveItem(model);
        setConfirmOpen(true);
    }

    const onDeleteConfirmed = () => {
        new api.UsersClient().delete(activeItem.id)
            .then(() => {
                props.showSuccess("User deleted successfully.");
                setRefreshCounter(refreshCounter + 1);
            })
            .catch(error => {
                props.showError("Unable to delete user.  Please try again.");
            });
    }

    return (
        <>
            <NavBar
                title="Manage Users">
                <Button size="small" variant="outlined" color="success" onClick={() => createOrEditUser(null)}>
                    Add User
                </Button>
            </NavBar>

            <Container className="plush users-list" maxWidth="lg">
                <Card className="col-fill">
                    <DataGrid
                        disableSelectionOnClick
                        loading={isLoading}
                        columns={[
                            { headerName: 'Name', field: 'name', flex: 1 },
                            { headerName: 'Email', field: 'emailAddress', flex: 1 },
                            { headerName: 'Roles', field: 'roles', flex: 1, renderCell: (e) => _.join(_.map(e.row.roles, r => r.name), ", ") },
                            {
                                field: "actions", type: "actions", getActions: (r) => [
                                    <GridActionsCellItem icon={<Edit className="icon-edit" />} label="Edit User" onClick={(_) => createOrEditUser(r.row)} />,
                                    <GridActionsCellItem icon={<Delete className="icon-delete" />} label="Delete User" onClick={(_) => deleteUser(r.row)} />
                                ]
                            }
                        ]}
                        rows={data}
                    />
                </Card>
            </Container>

            <EditUserDialog key={activeItem?.id} displayTrigger={editDialogDisplayTrigger} user={activeItem} onSuccess={onUserCreatedOrUpdated} />

            <ConfirmationDialog
                title="Delete User?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirmed}
            >
                {`Are you sure you want to delete ${activeItem?.name}?`}
            </ConfirmationDialog>

        </>
    );
}

export default connect(
    null,
    { showSuccess, showError })(Users);