import { Button, PrimaryButton, SecondaryButton, TertiaryButton } from "../../../shared/components";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

(Button as DocumentedComponent).metadata = {
    description: "The Button component allows for triggering an action on click.  The `onClick` event handler would most often be populated via a block-bodied arrow function, in order to specify the custom logic required.",
    attributes: [
        { name: "color", type: "string", options: ["primary", `secondary`, `success`, `error`, `info`, `warning`], description: "The color of the button.  Defaults to`primary`." },
        { name: "size", type: "string", options: ["small", `medium`, `large`], description: "The size of the button.  Defaults to`medium`." },
        { name: "variant", type: "string", options: ["contained", `outlined`, `text`], description: "The style of the button.  Defaults to`contained`." },
        { name: "disabled", type: "boolean", description: "Disables the button if the bound value is `truthy`.  Useful for preventing the user from using the button until some condition is met." },
        { name: "onClick", type: "function", template: "onClick={(e) => {$1}}", description: "The function to invoke when the button is clicked." },
    ]
};

export { Button, PrimaryButton, SecondaryButton, TertiaryButton };