import React from "react";
import { ReactGrid, Column, Row, CellChange, DefaultCellTypes, Highlight, NumberCell, CheckboxCell, DateCell, TextCell, Id } from "@silevis/reactgrid";
import "./DataGrid.scss";
import { RootState, useThunkDispatch } from "../../../store";
import { useSelector } from "react-redux";
import _ from "lodash";
import { updateParameterValue } from "../../../store/storyline/actions";
import moment from "moment";
import { JsxCell, JsxCellTemplate } from "./JsxCellTemplate";
import { ChevronCellTemplate } from "./ChevronCellTemplate";
import { NothingYet } from "../../../shared/components";
import { DropdownCellTemplate, DropdownCell } from "./DropdownCellTemplate";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";

type AllCellTypes = DefaultCellTypes | JsxCell;

type Option = {
    label: string;
    value: any;
}

type DataGridColumn = {
    field: string;
    headerName: string;
    type: "string" | "text" | "number" | "date" | "boolean" | "checkbox" | "singleSelect" | "dropdown" | "chevron" | "jsx";
    width?: number;
    resizable?: boolean;
    availableOptions?: Option[];
    getContent?: (row: any) => JSX.Element;
};

type DataGridRow = {
    id: any;
    [key: string]: any;
};

type DataGridProps = {
    name: string;
    columns: DataGridColumn[];
    getRows?: (parameterValue: any, columns: DataGridColumn[], expandCollapseStatuses: object, getDefaultCellObject: typeof getCellObject) => Row<AllCellTypes>[];
    handleCellChange?: (parameterValue: any, rowId: any, field: any, newValue: any, column: DataGridColumn) => void;
    rowHeight?: number;
    headerRowHeight?: number;
};

function mapColumn(column: DataGridColumn): Column {
    return {
        ...column,
        columnId: column.field
    };
};

function getCellObject(row: DataGridRow, column: DataGridColumn) {
    switch (column.type) {
        case "string":
        case "text":
            return {
                ...column,
                type: "text",
                text: row?.[column.field]
            } as TextCell;
        case "number":
            return {
                ...column,
                type: "number",
                value: row?.[column.field]
            } as NumberCell;
        case "boolean":
        case "checkbox":
            return {
                ...column,
                type: "checkbox",
                checked: row?.[column.field] ?? false
            } as CheckboxCell;
        case "date":
            return {
                ...column,
                type: "date",
                date: moment(row?.[column.field]).toDate()
            } as DateCell;
        case "singleSelect":
        case "dropdown":
            return {
                ...column,
                type: "dropdown",
                selectedValue: row?.[column.field],
                values: column.availableOptions
            } as DropdownCell;
        case "jsx":
            return {
                ...column,
                type: "jsx",
                content: column.getContent ? column.getContent(row) : row?.[column.field]
            } as JsxCell;
        default:
            return {
                ...column,
                type: column.type,
                text: row?.[column.field]
            };
    }
};

function getCellValue(cell: AllCellTypes) {
    switch (cell.type) {
        case "text":
            return cell.text;
        case "number":
            return cell.value;
        case "checkbox":
            return cell.checked;
        case "date":
            return cell.date;
        case "dropdown":
            return cell.selectedValue;
        case "email":
            return cell.text;
        case "time":
            return cell.time;
        case "chevron":
            return cell.text;
        case "jsx":
            return cell.content;
    }
}

function defaultGetRows(rows: DataGridRow[], columns: DataGridColumn[], expandCollapseStatuses: object, getDefaultCellObject: typeof getCellObject, rowHeight: number = 36, headerRowHeight: number = 56): Row<AllCellTypes>[] {
    function mapRow(row: DataGridRow, columns: DataGridColumn[], getDefaultCellObject: typeof getCellObject): Row<AllCellTypes> {
        return {
            rowId: row.id,
            cells: columns.map(c => getDefaultCellObject(row, c)),
            height: rowHeight
        };
    };

    return [
        {
            rowId: "headers",
            cells: columns.map(c => ({ type: "header", text: c.headerName })),
            height: headerRowHeight
        },
        ...(rows ?? []).map(r => mapRow(r, columns, getDefaultCellObject))
    ];
}

/// The default cell change handler requires the input data to be an array, where each item has an "id" field.
function defaultHandleCellChange(inputData: DataGridRow[], rowId: any, field: any, newValue: any, _column: DataGridColumn) {
    // eslint-disable-next-line eqeqeq
    const row = inputData?.find?.(r => r.id == rowId);
    if (!row) {
        return;
    }

    row[field] = newValue;
}

function DATA_GRID(props: DataGridProps) {
    const { name, columns, getRows: customGetRows, handleCellChange: customHandleCellChange, rowHeight, headerRowHeight, ...rest } = props;

    const getRows = (rows, columns, expandCollapseStatuses) => customGetRows ? customGetRows(rows, columns, expandCollapseStatuses, getCellObject) : defaultGetRows(rows, columns, expandCollapseStatuses, getCellObject, rowHeight, headerRowHeight);
    const handleCellChange = customHandleCellChange ? customHandleCellChange : defaultHandleCellChange;

    const inputData = useSelector((store: RootState) => store.storyline.parameterValues.get(name));
    const dispatch = useThunkDispatch();

    const [lastColumns, setLastColumns] = React.useState(columns);
    const [managedColumns, setManagedColumns] = React.useState((columns || []).map(c => mapColumn(c)));

    const [editHighlights, setEditHighlights] = React.useState<Highlight[]>([]);
    const [expandCollapseStatuses, setExpandCollapseStatuses] = React.useState<object>({});
    const [cellMetadata, setCellMetadata] = React.useState<{ rowId: any, colId: any, metadata: object }[]>([]);

    React.useEffect(() => {
        if (!_.isEqual(columns, lastColumns)) {
            setLastColumns(columns);
            setManagedColumns(columns.map(mapColumn));
        }
    }, [columns]);

    const updateCellMetadata = (rowId, colId, changes: object) => {
        const existingEntry = cellMetadata.find(c => c.rowId == rowId && c.colId == colId)?.metadata ?? {};

        setCellMetadata([
            ...cellMetadata.filter(c => c.rowId != rowId && c.colId != colId),
            {
                rowId,
                colId,
                metadata: {
                    ...existingEntry,
                    ...changes
                }
            }
        ])
    };


    const handleEditChanges = (changes: CellChange<DefaultCellTypes>[]) => {
        const modifiedInputData = _.cloneDeep(inputData);
        const modifiedEditHighlights = [...editHighlights];
        const modifiedExpandCollapseStatuses = { ...expandCollapseStatuses };

        changes.forEach(change => {
            // Workaround for checkbox not supporting fill handle...
            if (change.type === "checkbox") {
                change.newCell.checked = !change.previousCell.checked;
            }

            // Workaround for dropdown not supporting fill handle...
            if (change.type === "dropdown" && change.previousCell.selectedValue === change.newCell.selectedValue && change.previousCell["text"] !== change.newCell["text"]) {
                change.newCell.selectedValue = change.newCell["text"];
            }

            // User collapsed/expanded a chevron cell - update the collapse/expanded statuses dictionary accordingly...
            if (change.type === "chevron" && change.previousCell.isExpanded !== change.newCell.isExpanded) {
                modifiedExpandCollapseStatuses[change.rowId] = change.newCell.isExpanded;
                return;
            }

            // Any non-value changes made to cells (isOpen, isExpanded, inputValue, etc.) should be recorded here so that it can be reinstated when the grid is rendered...
            updateCellMetadata(change.rowId, change.columnId,
                {
                    isExpanded: change.newCell["isExpanded"],
                    inputValue: change.newCell["inputValue"],
                    isOpen: change.newCell["isOpen"]
                }
            );

            // Short-circuit if the cell value didn't change...
            if (getCellValue(change.previousCell) === getCellValue(change.newCell)) {
                return;
            }

            // Apply the changes...
            handleCellChange(modifiedInputData, change.rowId, change.columnId, getCellValue(change.newCell), columns.find(c => c.field == change.columnId));

            // eslint-disable-next-line eqeqeq
            if (!editHighlights.find(h => h.rowId == change.rowId && h.columnId == change.columnId)) {
                modifiedEditHighlights.push({
                    rowId: change.rowId,
                    columnId: change.columnId,
                    className: "modified-value"
                });
            }
        });

        !_.isEqual(inputData, modifiedInputData) && dispatch(updateParameterValue(name, modifiedInputData));
        !_.isEqual(modifiedEditHighlights, editHighlights) && setEditHighlights(modifiedEditHighlights);
        !_.isEqual(modifiedExpandCollapseStatuses, expandCollapseStatuses) && setExpandCollapseStatuses(modifiedExpandCollapseStatuses);
    };

    const handleColumnResize = (ci: Id, width: number) => {
        setManagedColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    };

    const rows = getRows(inputData, columns || [], expandCollapseStatuses);

    // Restore the `isOpen` flag for open dropdowns...
    cellMetadata.forEach(({ rowId, colId, metadata }) => {
        const matchingRow = rows.find(r => r.rowId === rowId);
        if (matchingRow) {
            const matchingCell = matchingRow.cells.find(c => c["field"] === colId);
            if (matchingCell) {
                Object.entries(metadata).forEach(([key, value]) => {
                    matchingCell[key] = value;
                });
            }
        }
    });

    return (
        <div className="datagrid-container">
            <ReactGrid
                enableFillHandle
                enableRangeSelection
                stickyTopRows={1}
                horizontalStickyBreakpoint={100} // Disable sticky headers only taking up some portion of the horizontal viewport
                verticalStickyBreakpoint={100} // Disable sticky headers only taking up some portion of the vertical viewport
                {...rest}
                rows={rows}
                columns={managedColumns}
                onCellsChanged={handleEditChanges}
                onColumnResized={handleColumnResize}
                highlights={editHighlights}
                customCellTemplates={{ jsx: new JsxCellTemplate(), chevron: new ChevronCellTemplate(), dropdown: new DropdownCellTemplate() }}
                // Fix for Excel adding a blank cell underneath the pasted data...
                ref={(ref: any) => {
                    if (!ref) return;
                    const oldPasteHandler = ref.eventHandlers.pasteHandler;

                    ref.eventHandlers.pasteHandler = (e) => {
                        try {
                            const dataToPaste = e.clipboardData.getData('text/plain');
                            const htmlDataToPaste = e.clipboardData.getData('text/html');

                            if (htmlDataToPaste.includes('xmlns:v="urn:schemas-microsoft-com:vml"')) {
                                const slicedData = dataToPaste.slice(0, -2)
                                const dataTransfer = new DataTransfer();
                                dataTransfer.setData('text/plain', slicedData);
                                e.clipboardData = dataTransfer;
                            }

                            oldPasteHandler(e);
                        } catch (e) {
                            console.error('There was an error on paste => ', e);
                        }
                    };
                }}
            />
        </div>
    );
}

function DataGrid(props: DataGridProps) {
    const { name, columns } = props;

    if (!name) {
        return <NothingYet title="Missing configuration" description={`The "name" prop is required.`} />;
    }

    if (columns?.length < 1) {
        return <NothingYet title="Missing configuration" description={`"columns" must be a non-empty array of column definitions.`} />;
    }

    return <DATA_GRID {...props} />;
}

(DataGrid as DocumentedComponent).metadata = {
    description: `The DataGrid component is used to display data in a tabular format, with support for editing the values inline.  It supports Excel-style fill operations and copy/paste to and from Excel.

This is a wrapper around the third-party [ReactGrid](https://reactgrid.com/docs/4.0/0-introduction/) component.  Please see that component's documentation for additional information regarding the custom row/cell rendering logic and examples.`,
    isSelfClosing: true,
    attributes: [
        {
            name: "name", type: "string", description: `The name of the variable that the grid data will be read from/written to.  If no custom \`getRows\` handler is provided, the corresponding parameter value is expected to be an array of \`Row\` objects.  See below for the structure of the \`Row\` type.

### \`Row\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`id\` | \`any\` | The unique identifier for this row.  Used to identify the target row for cell updates. |
| \`...fieldNames\` | \`[field: string]: any\` | The rest of the fields in this object would be determined by the columns displayed within the grid.  \`row[column.field]\` will be used to fetch the cell data for each column specified. | ` },
        {
            name: "columns", type: "object", description: `The columns to display in the grid.  See below for the structure of the \`Column\` type.
            
### \`Column\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`field\` | \`string\` | The name of the field to display in this column.  Corresponds to a key in the row object. |
| \`headerName\` | \`string\` | The title of the column rendered in the column header cell. |
| \`type\` | \`"text" \| "number" \| "date" \| "boolean" \| "dropdown" \| "jsx"\` | The type of data that is displayed in the column.  Determines the input component to use, display formatting for the values, etc. |
| \`width\` | \`number\` | The initial width of the column in \`px\`. |
| \`resizable\` | \`boolean ? \` | If \`true\`, the user can resize the column by dragging the resize handler inside the relevant header cell.  Optional - defaults to \`false\`. |
| \`availableOptions\` | \`Option[]\` | If the column type is \`dropdown\`, these options are used for the dropdowns in this column.  The structure of these options match those of the standard \`Autocomplete\` component (\`label\` and \`value\`). |
| \`getContent\` | \`(row: object) => JSX\` | The callback function used to generate the content of the cell.  Only applies to columns of type \`jsx\`.  |`
        },
        {
            name: "getRows", type: "function", template: "getRows={(data, columns, expandCollapseStatuses, defaultGetCell) => {\n\t$1\n}}", description: `The (optional) callback function used to map the raw input data to the grid rows.  Must return an array of \`CustomRow\` objects.  See below for the structure of the \`CustomRow\` type.
            
### \`CustomRow\` Fields:

| Name | Type | Description |
|------|------|-------------|
| \`rowId\` | \`any\` | The unique identifier for this row.  Used to identify the target row for cell updates. |
| \`height\` | \`number\` | The height of this row.  Optional - defaults to \`25\`. |
| \`cells\` | \`Cell[]\` | The cells to render in this row.  The order and length of this should match the \`columns\` for the grid. |`
        },
        {
            name: "handleCellChange", type: "function", template: "handleCellChange={(parameterValue, rowId, field, newValue, column) => {\n\t$1\n}}", description: "The (optional) callback function used to apply cell changes to the raw input data.  The `parameterValue` argument can be mutated directly here in order to apply the desired changes."
        },
        { name: "rowHeight", type: "number", description: "The height (in pixels) to apply to the data rows.  Optional - defaults to `36`." },
        { name: "headerRowHeight", type: "number", description: "The height (in pixels) to apply to the header row.  Optional - defaults to `56`." }
    ]
};

export default DataGrid;