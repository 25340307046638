import React from 'react';
import { useGridSelector, GridFooterContainerProps, gridRowCountSelector, selectedGridRowsCountSelector, gridVisibleRowCountSelector, gridPaginationSelector, useGridApiContext, useGridRootProps, GridFooterContainer, GridSelectedRowCount, GridRowCount } from '@mui/x-data-grid-pro';
import { TertiaryButton } from "../../../shared/components";
import { CloudDownload } from "@mui/icons-material";
import { utils, writeFile } from "xlsx";
import moment from "moment";

interface SCGridFooterContainerProps extends GridFooterContainerProps {
    hideExportToExcel?: boolean;
}

// Use https://github.com/mui-org/material-ui-x/blob/master/packages/grid/_modules_/grid/components/GridFooter.tsx as a base in order to modify the content of the Grid Footer...
export default React.forwardRef<HTMLDivElement, SCGridFooterContainerProps>(
    function GridFooter(props, ref) {
        const { hideExportToExcel, ...rest } = props;
        const apiRef = useGridApiContext();
        const options = useGridRootProps();
        const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
        const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector);
        const pagination = useGridSelector(apiRef, gridPaginationSelector);
        const visibleRowCount = useGridSelector(apiRef, gridVisibleRowCountSelector);

        const SelectedRowCountElement =
            !options.hideFooterSelectedRowCount && selectedRowCount > 0 ? (
                <GridSelectedRowCount selectedRowCount={selectedRowCount} />
            ) : (
                    <div />
                );

        const RowCountElement =
            !options.hideFooterRowCount && !options.pagination ? (
                <GridRowCount rowCount={totalRowCount} visibleRowCount={visibleRowCount} />
            ) : null;

        const PaginationComponent =
            !!options.pagination &&
            pagination.pageSize != null &&
            !options.hideFooterPagination &&
            options.componentsProps?.pagination;

        const PaginationElement = PaginationComponent && (
            <PaginationComponent {...options.componentsProps?.pagination} />
        );

        const onExportToExcelClicked = () => {
            const gridRows = Array.from(apiRef.current.getRowModels().entries()).map(([_, item]) => item);
            const gridColumns = apiRef.current.getAllColumns();

            // Map fields to column headers for each row...
            const exportRows = gridRows.map(row => {
                const result = {};
                gridColumns.forEach(col => {
                    result[col.headerName] = col.valueGetter ? col.valueGetter({ field: col.field, value: row[col.field], api: apiRef.current } as any) : row[col.field];
                });
                return result;
            });

            const wb = utils.book_new();
            const ws = utils.json_to_sheet(exportRows);
            utils.book_append_sheet(wb, ws, "Grid Data");
            const filename = `${document.title} - ${moment().format("YYYY-MM-DD-HHmm")}.xlsx`;
            writeFile(wb, filename);
        }

        const ExportToExcelComponent =
            !hideExportToExcel && (
                <TertiaryButton id="export-to-excel" color="primary" size="small" onClick={onExportToExcelClicked} title="Export Data to Excel">
                    <CloudDownload />&nbsp;&nbsp;XLSX
                </TertiaryButton>
            );

        return (
            <GridFooterContainer ref={ref} {...rest}>
                {ExportToExcelComponent}
                {SelectedRowCountElement}
                {RowCountElement}
                {PaginationElement}
            </GridFooterContainer>
        );
    },
);